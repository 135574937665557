import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createNewTerminal } from "../../../../../actions/admin/AdminActions";
import { validateFields } from "../../../../../util/utility-helper";
import { searchStoreList } from "../../../../../actions/inventory/InventoryActions";

const intialTerminalState = { 'name': '', 'status': 'ACTIVE', 'storeId': '', 'description': '', 'syncStatus': 'N' }
const mandatoryFields = ['name', 'status', 'storeId'];

const AddTerminal = ({ showModal, hideModal, fetchAllTerminalList, handleToastManager }) => {
    // useDispatch hook for dispatching actions
    const dispatch = useDispatch();

    // loader for button
    const [buttonState, setButtonState] = useState({ disable: true, loading: false });
    const [storeList, setStoreList] = useState([]);
    // State hooks for managing form data 
    const [terminalObj, setTerminalObj] = useState({});

    useEffect(() => {
        if (showModal) {
            getStoreByApi()
            setTerminalObj({ ...intialTerminalState });
        }
    }, [showModal])

    const getStoreByApi = async () => {
        await dispatch(searchStoreList({ 'searchFilter': "" }))
            .then((response) => {
                if (response.payload && !response.payload.error) {
                    setStoreList(response.payload)
                }
            })
    };

    // Handle input changes
    const onHandleChange = (event) => {
        const { name, value } = event.target;
        setTerminalObj((prevObj) => ({
            ...prevObj,
            [name]: name === "storeId" ? Number(value) : name === 'linkedAccounts' ? [value] : value
        }));
        setButtonState({ ...buttonState, disable: validateFields(mandatoryFields, { ...terminalObj, [event.target.name]: event.target.value }) });
    };

    // Handle form submission
    const onSubmit = async () => {
        try {
            setButtonState({ ...buttonState, loading: true });
            await dispatch(createNewTerminal(terminalObj))
                .then((response) => {
                    if (response.payload && !response.payload.error) {
                        handleToastManager({ visible: true, toastMessage: 'Terminal created successfully.', variant: "Success" })
                        fetchAllTerminalList();
                        setTimeout(() => {
                            hideModal();
                        }, 1000);
                    } else {
                        handleToastManager({ visible: true, toastMessage: 'Failed to create terminal. Please try again.', variant: "Danger" })
                    }
                    setButtonState({ ...buttonState, save: false });
                })
        } catch (err) { }
    };

    return (
        <Modal size="md" centered backdrop="static" keyboard={false} show={showModal} onHide={hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Add Terminal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3" >
                    <Col md={12}>
                        <Form.Floating>
                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name="name"
                                id="nameInput"
                                placeholder="Name"
                                value={terminalObj.name}
                                onChange={onHandleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Name<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12}>
                        <Form.Floating className="">
                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name="description"
                                id="description"
                                placeholder="Description"
                                value={terminalObj.description}
                                onChange={onHandleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Description</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12}>
                        {/* <Form.Floating>
                            <Form.Select
                                name="linkedAccounts"
                                id="storeIdSelect"
                                placeholder="User"
                                onChange={onHandleChange}
                            >
                                <option value="" disabled={terminalObj.linkedAccounts} >Select User</option>
                                {memberList.map((member, idx) => {
                                    return (
                                        <option key={idx} value={member.id} ><>{member?.username}</></option>
                                    )
                                })}
                            </Form.Select>
                            <Form.Label htmlFor="storeIdSelect">Select User<span className="text-danger">*</span></Form.Label>
                        </Form.Floating> */}
                    </Col>
                    <Col md={12}>
                        <Form.Floating>
                            <Form.Select
                                name="status"
                                id="statusSelect"
                                placeholder="Status"
                                value={terminalObj.status}
                                onChange={onHandleChange}
                            >
                                <option value="" disabled>Select Status</option>
                                <option value="ACTIVE">Active</option>
                                <option value="INACTIVE">Inactive</option>
                            </Form.Select>
                            <Form.Label htmlFor="statusSelect">Status<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12}>
                        <Form.Floating>
                            <Form.Select
                                name="storeId"
                                id="storeIdSelect"
                                placeholder="Store Id"
                                value={terminalObj.storeId}
                                onChange={onHandleChange}
                            >
                                <option value="" disabled={terminalObj.storeId} >Select Store</option>
                                {storeList.map((store, idx) => {
                                    if (store.storeType !== 'ASSEMBLY') {
                                        return (
                                            <option key={idx} value={store.id} selected={store.id === terminalObj.storeId}><>{store?.name} ({store.storeType})</></option>
                                        )
                                    }
                                })}
                            </Form.Select>
                            <Form.Label htmlFor="storeIdSelect">Store<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
                <Row className="g-3 w-100 justify-content-end">
                    <Col md={3} lg={3} className="m-0">
                        {!buttonState.loading ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={onSubmit} disabled={buttonState.disable}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    );
};

export default AddTerminal;
