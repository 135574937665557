import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deleteSubCategory, updateSubCategory } from "../../../../../../actions/billdesk/BillDeskActions";
import NumberField from "../../../../../../components/common/App/NumberField";

const initialState = { catAlias: "", catType: "", icon: "", displayOrder: null, status: "", syncStatus: "", categoryId: "" };
let isDataChangeFlag = false;

const EditSubCategoryModal = ({ ...props }) => {
    const dispatch = useDispatch();

    const [category, setCategory] = useState({ ...initialState });

    const [buttonState, setButtonState] = useState({ updateState: "", deleteState: "" });

    useEffect(() => {
        if (props.showModal) {
            if (props.currentSubCategory) {
                isDataChangeFlag = false;
                setCategory({ ...props.currentSubCategory, products: undefined });
                setButtonState({ updateState: '', deleteState: '' });
            }
        }
    }, [props.showModal, props.currentSubCategory]);

    const handleChange = (event) => {
        setCategory({ ...category, [event.target.name]: event.target.value });
    }

    const updateCategory = async () => {
        if (props.currentMainCategory && props.currentMainCategory.id) {
            setButtonState({ ...buttonState, updateState: "loading" });
            try {
                await dispatch(updateSubCategory({ ...category, parentCategoryId: props.currentMainCategory.id }))
                    .then((response) => {
                        if (response.payload && !response.payload.error) {
                            isDataChangeFlag = true;
                            props.handleToastManager({ visible: true, toastMessage: "Sub-Category Update Successfully", variant: 'Success' });
                        } else {
                            props.handleToastManager({ visible: true, toastMessage: "Error While Updating Sub-Category", variant: 'Danger' });
                        }
                        setButtonState({ ...buttonState, updateState: "" });
                    })
            } catch (err) { }
        }
    };

    const deleteCategory = async () => {
        if (props.currentMainCategory && props.currentMainCategory.id) {
            setButtonState({ ...buttonState, deleteState: "loading" });
            try {
                await dispatch(deleteSubCategory({ ...category, parentCategoryId: props.currentMainCategory.id }))
                    .then((response) => {
                        if (response.payload && !response.payload.error) {
                            props.hideModal(true)
                            props.handleToastManager({ visible: true, toastMessage: "Sub-Category Deleted Successfully", variant: 'Success' });
                        } else {
                            props.handleToastManager({ visible: true, toastMessage: "Error While Deleting Sub-Category", variant: 'Danger' });
                        }
                        setButtonState({ ...buttonState, deleteState: "" });
                    })
            } catch (err) { }
        }
    };

    return (
        <Modal size="md" backdrop="static" keyboard={false} centered show={props.showModal} onHide={() => props.hideModal(isDataChangeFlag)}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Sub Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12}>
                        <Form.Floating className="">
                            <Form.Control type="text" autoComplete='off' name="catAlias" id="floatingInputCustom" placeholder="Product Name" value={category.catAlias} defaultValue={category.catAlias} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">Sub Category Name</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12}>
                        <Form.Floating className="">
                            <NumberField autoComplete='off' name="displayOrder" id="floatingInputCustom" placeholder="Product Name" value={category.displayOrder} defaultValue={category.displayOrder} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">Display Order</Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-end'>
                    <Col md={3} lg={3} >
                        {buttonState.updateState !== 'loading' ?
                            <Button disabled={buttonState.deleteState === 'loading'} className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={updateCategory}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey' variant="primary" disabled>
                                <Spinner as="span" size="sm" animation="border" role="status" aria-hidden="true" />
                            </Button>}
                    </Col>
                    <Col md={3} lg={3} >
                        {buttonState.deleteState !== 'loading' ?
                            <Button disabled={buttonState.updateState === 'loading'} className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={deleteCategory}>Delete</Button>
                            : <Button className='light-btn w-100 bg-light-grey' variant="primary" disabled>
                                <Spinner as="span" size="sm" animation="border" role="status" aria-hidden="true" />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

export default EditSubCategoryModal;