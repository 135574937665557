import React from "react";
import { Badge, Col, Form } from "react-bootstrap";

const SettingEditStorePane = ({ deliveryMethods, paymentMethods, addStoreData, storeList, handleChange, handleMethodChange, userAssignTerminal }) => {
    return (
        <>
            <Col md={12}>
                <div className="d-flex">
                    <Form.Label htmlFor="floatingInputCustom">Delivery Methods<span className="text-danger me-2">*</span></Form.Label>
                    {deliveryMethods && deliveryMethods.map((methods, index) => {
                        return (
                            <Form.Check
                                key={index}
                                className="ms-3"
                                type="checkbox"
                                name="deliveryMethods"
                                id={methods.methodCode}
                                value={methods.methodCode}
                                label={methods.name}
                                checked={String(addStoreData.deliveryMethods).includes(methods.methodCode)}
                                onChange={handleMethodChange}
                            />
                        )
                    })}
                </div>
            </Col>
            <Col md={12}>
                <div className="d-flex">
                    <Form.Label htmlFor="floatingInputCustom">Payment Methods<span className="text-danger">*</span></Form.Label>
                    {paymentMethods && paymentMethods.map((methods, index) => {
                        return (
                            <Form.Check
                                key={index}
                                className="ms-3"
                                type="checkbox"
                                name="paymentMethods"
                                id={methods.methodCode}
                                value={methods.methodCode}
                                label={methods.description}
                                checked={String(addStoreData.paymentMethods).includes(methods.methodCode)}
                                onChange={handleMethodChange}
                            />
                        )
                    })}
                </div>
            </Col>
            <Col md={8}>
                <Form.Floating className="">
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        name="aboutStore"
                        id="aboutStore"
                        placeholder="AboutStore"
                        value={addStoreData.aboutStore}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">About Store</Form.Label>
                </Form.Floating>
            </Col>

            <Col md={4}>
                <Form.Floating className="">
                    <Form.Select
                        name="rootStoreId"
                        id="rootStoreId"
                        placeholder="Parent Store"
                        value={addStoreData.rootStoreId}
                        onChange={handleChange}
                    >
                        <option value="" disabled={addStoreData.rootStoreId} >Parent Store</option>
                        {storeList && storeList.map((store, indx) => {
                            return (
                                <option key={indx} value={store.id} selected={addStoreData.rootStoreId === store.id}>{store.name}</option>
                            )
                        })}

                    </Form.Select>
                    <Form.Label htmlFor="rootStoreId">Parent Store</Form.Label>
                </Form.Floating>
            </Col>
            {addStoreData.storeType !== 'ASSEMBLY' ?
                <Col md={12}>
                    <Form.Label className="mb-2">
                        Store Terminals
                    </Form.Label>
                    <div>
                        {userAssignTerminal && userAssignTerminal.map((terminal, index) => {
                            return (
                                <Badge key={index} bg="success" className="me-2">
                                    {terminal.name}
                                </Badge>
                            );
                        })}
                    </div>
                </Col> : null}
        </>
    );
};

export default SettingEditStorePane;
