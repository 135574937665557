import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, Form, Image, Modal, Row } from 'react-bootstrap';
import mapImg from '../../../../assets/images/map.png';
import { FaIndianRupeeSign } from 'react-icons/fa6';
import VegIcon from '../../../../assets/images/vegetarian-food-symbol.png';
import NonVegIcon from '../../../../assets/images/non-vegetarian-food-symbol.png';
import { BiEdit } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import loadingImg from '../../../../assets/images/loading-img.gif';
import NumberField from '../../../../components/common/App/NumberField';
import { getOrderById, updateOrder } from '../../../../actions/order/OpenOrdersActions';
import { deepCopy } from '../../../../util/utility-helper';
import { getStoreDeliveryMethod } from '../../../../actions/billdesk/BillDeskActions';
import { IoSaveOutline } from 'react-icons/io5';

const intialManageViewState = { viewName: "", visible: false }
const CardDetailModal = ({ showCardDetailModal, onCloseCardDetailModal, currentOrder }) => {
    const dispatch = useDispatch();

    const [manageView, setManageView] = useState({ viewName: "", visible: false });
    const [orderDetails, setOrderDetails] = useState({ ...intialManageViewState })
    const [loader, setLoader] = useState(false);
    const [orderTypeMethods, setOrderTypeMethods] = useState([]);

    useEffect(() => {
        setManageView({ ...intialManageViewState });
    }, []);

    useEffect(() => {
        if (currentOrder && currentOrder.id) {
            getCurrentOrderById(currentOrder);
            getOrderTypeMethodsByApi()
        }
    }, [currentOrder]);

    const getCurrentOrderById = async (order) => {
        setLoader(true);
        await dispatch(getOrderById(order.id)).then(async (response) => {
            if (response.payload) {
                let apiResponse = deepCopy(response.payload);
                await apiResponse.items && apiResponse.items.map(itm => {
                    itm.total = itm?.price * itm.qty;
                    return itm;
                });
                apiResponse = { ...apiResponse, deliveryMethod: order.deliveryMethod, orderStage: order.orderStage, paymentMethod: order.paymentMethod };
                setLoader(false);
                setOrderDetails(apiResponse);
            }
        })
    };

    const getOrderTypeMethodsByApi = async () => {
        await dispatch(getStoreDeliveryMethod())
            .then((response) => {
                if (response.payload && !response.payload.error) {
                    setOrderTypeMethods(response.payload)
                }
            })
    }

    const isMethodValid = (method) => {
        return method === "DINE" || method === "DELIVERY" || method === "DRIVE";
    };

    const handleView = (viewName, isVisible) => {
        setManageView({ ...manageView, viewName: viewName, visible: isVisible });
    };

    const handleChange = (event) => {
        let customerInfo = orderDetails.customer;
        customerInfo = { ...customerInfo, [event.target.name]: event.target.value };
        setOrderDetails({ ...orderDetails, customer: customerInfo });
        // orderUpdate({ customer: customerInfo });
    };

    const handleDropDownChange = (name, value) => {
        setOrderDetails({ ...orderDetails, [name]: value });
        orderUpdate({ [name]: value });
    };

    const orderUpdate = async (data) => {
        await dispatch(updateOrder({ id: currentOrder.id, ...data })).then((response) => {
            if (response.payload && response.payload.message === "SUCCESS") {
                getCurrentOrderById(currentOrder);
            }
        })
    };

    return (
        <>
            <Modal backdrop="static" keyboard={false} show={showCardDetailModal} onHide={onCloseCardDetailModal} centered size='lg' scrollable>
                <Modal.Header className='border-0 pb-0' closeButton>
                    <Modal.Title className='fs-5 fw-bold'>{orderDetails.tempInvoiceNo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!loader ?
                        <Row className='g-3'>
                            <Col md={12} className=''>
                                <Row className="g-3">
                                    <Col md={12} lg={5} className=''>
                                        <Row className="g-0">
                                            <Col xs={6} className=''>
                                                <p className='mb-0 d-flex align-items-center'>Total Items<span className='ms-auto me-2'>-</span></p>
                                                <p className='mb-0 d-flex align-items-center'>Total Amount<span className='ms-auto me-2'>-</span></p>
                                                <p className='mb-0 d-flex align-items-center'>Payment Method<span className='ms-auto me-2'>-</span></p>
                                            </Col>
                                            <Col xs={6} className=''>
                                                <p className='mb-0'>{orderDetails.items ? orderDetails.items.length : 0} </p>
                                                <p className='mb-0 d-flex align-items-center'><FaIndianRupeeSign className='fs-14' />{orderDetails?.orderTotal}</p>
                                                <p className='mb-0'>{orderDetails.paymentMethod}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} lg={7} className='align-self-end'>
                                        <Row className="g-3">
                                            <Col xs={12} sm={12} md={isMethodValid(orderDetails?.deliveryMethod) === true ? 5 : 3} className=''>
                                                <Row className="g-3">
                                                    {isMethodValid(orderDetails?.deliveryMethod) === true ?
                                                        <Col xs={6} sm={6} md={5} className=''>
                                                            <Button variant="primary" className='light-btn h-56 w-100 text-truncate' onClick={() => handleView(manageView.viewName === 'mapView' ? '' : 'mapView', !manageView.visible)}>
                                                                MAP
                                                            </Button>
                                                        </Col>
                                                        :
                                                        null}
                                                    <Col xs={6} sm={6} md={isMethodValid(orderDetails?.deliveryMethod) === true ? 7 : 12} className=''>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className={`light-btn h-56 w-100 text-white text-truncate ${orderDetails?.paymentStatus === "PAID" ? 'bg-light-green' : 'bg-light-red'}`} id="dropdown-status">
                                                                {orderDetails?.paymentStatus}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu align="end" className=''>
                                                                <Dropdown.Item onClick={() => handleDropDownChange('paymentStatus', 'PAID')}>PAID</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleDropDownChange('paymentStatus', 'UNPAID')}>UNPAID</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} sm={12} md={isMethodValid(orderDetails?.deliveryMethod) === true ? 7 : 9} className=''>
                                                <Row className="g-3">
                                                    <Col xs={6} sm={6} md={6} lg={6} className=''>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className='light-btn h-56 w-100 text-truncate' id="dropdown-method">
                                                                {orderDetails.deliveryMethod}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu align="end" className=''>
                                                                {orderTypeMethods.map(orderType => {
                                                                    return (
                                                                        <Dropdown.Item onClick={() => handleDropDownChange('deliveryMethod', orderType.methodCode)}>{orderType.methodCode}</Dropdown.Item>
                                                                    )
                                                                })}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Col>
                                                    <Col xs={6} sm={6} md={6} lg={6} className=''>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className='light-btn h-56 w-100 text-truncate' id="dropdown-stage">
                                                                {orderDetails.orderStage}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu align="end" className=''>
                                                                <Dropdown.Item onClick={() => handleDropDownChange('orderStage', 'REJECTED')}>REJECTED</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleDropDownChange('orderStage', 'NEW PLACED')}>NEW PLACED</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleDropDownChange('orderStage', 'ACCEPTED')}>ACCEPTED</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleDropDownChange('orderStage', 'WIP')}>WIP</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleDropDownChange('orderStage', 'COMPLETED')}>COMPLETED</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleDropDownChange('orderStage', 'DELIVERED')}>DELIVERED</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleDropDownChange('orderStage', 'OUT-OF-DELIVERY')}>OUT-OF-DELIVERY</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            {manageView.viewName === "mapView" && manageView.visible === true ?
                                <Col xs={12}>
                                    <Card className='inso-card'>
                                        <Card.Body>
                                            <Image className='mx-auto d-block' src={mapImg} />
                                        </Card.Body>
                                    </Card>
                                </Col> :
                                <>
                                    {orderDetails?.items && orderDetails?.items?.length !== 0 ?
                                        <Col xs={12} className=''>
                                            <Card className='inso-card'>
                                                <Card.Body>
                                                    <Row className='g-0'>
                                                        <Col xs={12} className=''>
                                                            <ul className='list-group list-group-flush mb-0'>
                                                                {orderDetails.items && orderDetails.items.map((items, index) => (
                                                                    <li key={index} className="list-group-item px-0">
                                                                        <Row className='g-0'>
                                                                            <Col xs={12} className='d-flex align-items-start'>
                                                                                <Image width='14px' className='me-2' src={items.tag === "VEG" ? VegIcon : items.tag === "NONVEG" ? NonVegIcon : null} />
                                                                                <div className='w-100 align-self-center'>
                                                                                    <h4 className="fs-12">{items.prodAlias}</h4>
                                                                                    <div className='fs-12 d-flex flex-wrap'>
                                                                                        <span className="me-auto pe-3 d-flex align-items-center">
                                                                                            <span className=''>
                                                                                                {items.qty}
                                                                                            </span>
                                                                                            <div className='divider'></div>
                                                                                            <span className='d-flex align-items-center'>
                                                                                                <FaIndianRupeeSign className='fs-12' />{items.price}
                                                                                            </span>
                                                                                        </span>
                                                                                        <span className='ms-auto d-flex align-items-center'><FaIndianRupeeSign className='fs-12' />{items.total}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </li>
                                                                ))}

                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col> : null}
                                    <Col md={12}>
                                        <Card className='inso-card'>
                                            <Card.Body className=''>
                                                <Row className="g-0">
                                                    <Col xs={12} sm={12} md={12} lg={9} className=''>
                                                        <Row className="g-0">
                                                            <Col xs={6} md={6} lg={4} className=''>
                                                                <p className='mb-0 d-flex align-items-center'>Cutomer Name<span className='ms-auto me-2'>-</span></p>
                                                                <p className='mb-0 d-flex align-items-center'>Mobile<span className='ms-auto me-2'>-</span></p>
                                                                <p className='mb-0 d-flex align-items-center'>Address<span className='ms-auto me-2'>-</span></p>
                                                            </Col>
                                                            <Col xs={6} md={6} lg={8} className=''>
                                                                <p className='mb-0'>{orderDetails?.customer?.name || ""}</p>
                                                                <p className='mb-0'>{orderDetails?.customer?.mobileNumber || ""}
                                                                </p>
                                                                <p className='mb-0'>{orderDetails?.customer?.address || ""}
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={3} className='d-flex align-items-center justify-content-end'>
                                                        <Button variant="primary" className='light-btn px-3' onClick={() => handleView(manageView.viewName === 'editView' ? '' : 'editView', !manageView.visible)}>
                                                            {manageView.viewName === 'editView' ? <IoSaveOutline className='fs-16' /> : <BiEdit className='fs-16' />}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {manageView.viewName === "editView" && manageView.visible === true ?
                                        <Col xs={12}>
                                            <Row className="g-3">
                                                <Col md={6}>
                                                    <Form.Floating >
                                                        <NumberField maxLength={10} name="mobileNumber" value={orderDetails?.customer?.mobileNumber || ""} defaultValue={orderDetails?.customer?.mobileNumber} onChange={handleChange} />
                                                        <Form.Label htmlFor="floatingInputCustom">Mobile</Form.Label>
                                                    </Form.Floating>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Floating >
                                                        <Form.Control type="text" autoComplete='off' name="address" id="floatingInputCustom" placeholder="Address" value={orderDetails?.customer?.address || ""} onChange={handleChange} />
                                                        <Form.Label htmlFor="floatingInputCustom">Address</Form.Label>
                                                    </Form.Floating>
                                                </Col>
                                            </Row>
                                        </Col>
                                        : null}
                                    <Col md={12}>
                                        <Form.Floating >
                                            <Form.Control type="text" autoComplete='off' name="comment" id="floatingInputCustom" placeholder="Note" value={orderDetails?.customer?.comment || ""} onChange={handleChange} />
                                            <Form.Label htmlFor="floatingInputCustom">Note</Form.Label>
                                        </Form.Floating>
                                    </Col>
                                </>}
                        </Row>
                        : <div className="d-flex align-items-center justify-content-center" style={{ height: '357px' }} >
                            <img width='25%' src={loadingImg} />
                        </div>}
                </Modal.Body >
            </Modal >
        </>
    )
};

export default CardDetailModal;