import { createSlice } from '@reduxjs/toolkit';
import { getCompleteOrders } from '../../actions/order/CompleteOrdersAction';

const initialState = {
    completeOrderList: [],
    orderFilter: ''
}

export const completeOrderSlice = createSlice({
    name: 'completeOrder',
    initialState,
    reducers: {
        changeOrderFilter: (state, action) => {
            state.orderFilter = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompleteOrders.pending, (state) => {
                state.completeOrderList = []
            })
            .addCase(getCompleteOrders.fulfilled, (state, action) => {
                state.completeOrderList = action.payload;
            }).addCase(getCompleteOrders.rejected, (state) => {
                state.completeOrderList = []
            })
    }
});

export const { changeOrderFilter } = completeOrderSlice.actions;

export default completeOrderSlice.reducer;