import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { TbEdit } from "react-icons/tb";
import { AiOutlineDelete } from "react-icons/ai";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { MdLockReset } from "react-icons/md";
import { LuSearch } from "react-icons/lu";
import { FaPlus } from "react-icons/fa6";
import { standardDateFormat } from "../../../../util/utility-helper";

const initialPageDetails = { currentPage: 1, pageSize: 10 };
const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const MemberPane = ({ ...props }) => {
    const [membersToDisplay, setMembersToDisplay] = useState([]);
    const [pageDetails, setPageDetails] = useState({ ...initialPageDetails });
    const [memberSearch, setMemberSearch] = useState("");
    const [memberList, setMemberList] = useState([]);

    useEffect(() => {
        setMemberList(props.memberList)
        handlePaginator(props.memberList, pageDetails.pageSize, pageDetails.currentPage);
    }, [props.memberList]);

    const handlePaginator = (memberList, pageSize, currentPage) => {
        const chunksRecord = [];
        memberList && memberList.forEach((item) => {
            if (!chunksRecord.length || chunksRecord[chunksRecord.length - 1].length === pageSize)
                chunksRecord.push([]);

            chunksRecord[chunksRecord.length - 1].push(item);
        });
        setMembersToDisplay(chunksRecord[currentPage - 1] === undefined ? [] : chunksRecord[currentPage - 1]);
    }

    const capitalizeFirstLetter = (text) => {
        if (!text) return '';
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    const handlePagination = (pageType) => {
        if (pageType === "NEXT") {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage + 1 });
            handlePaginator(props.memberList, pageDetails.pageSize, pageDetails.currentPage + 1);
        } else {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage - 1 });
            handlePaginator(props.memberList, pageDetails.pageSize, pageDetails.currentPage - 1);
        }
    };

    const handlePageSize = (event) => {
        setPageDetails({ ...pageDetails, pageSize: parseInt(event.target.value) });
        handlePaginator(props.memberList, parseInt(event.target.value), pageDetails.currentPage);
    };

    const handleSearch = (event) => {
        setMemberSearch(event.target.value);
        const memberListClone = [];
        props.memberList && props.memberList.forEach(member => {
            if (String(member.username).toLocaleLowerCase().includes(String(event.target.value).toLocaleLowerCase())) {
                memberListClone.push(member);
            }
        })
        setMemberList(memberListClone)
        handlePaginator(memberListClone, pageDetails.pageSize, pageDetails.currentPage);
    };

    return (
        <Row className="g-3">
            <Col md={12}>
                <Row className="g-3">
                    <Col md={6}>
                        <Form.Floating className="search_bar">
                            <span class="input-group-text search_icon" ><LuSearch /></span>
                            <Form.Control className="search_input" id="productSearch" type="search" autoComplete='off' name="filterText" placeholder="Search By User Name Or Status" value={memberSearch} onChange={handleSearch} />
                            <Form.Label htmlFor="floatingInputCustom">Search By User Name</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={3} lg={3} xl={2} className="">
                        <Button className='light-btn h-56 w-100' variant="primary" onClick={() => props.handleModalManager('addMemberModal', true)}>
                            <FaPlus className="me-1 fs-16" />
                            Member
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col sm={12} md={12} lg={12} xxl={12}>
                <Table responsive className="inso-table">
                    <thead>
                        <tr>
                            <th className="text-start" width="10%">  ID </th>
                            <th className="text-start" width="20%">  Username</th>
                            <th className="text-start" width="10%">  Role </th>
                            {/* <th className="text-center" width="10%"> Create Date </th>
                            <th className="text-center" width="10%"> Update Date </th> */}
                            <th className="text-center" width="15%">  Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {membersToDisplay && membersToDisplay.map((item) => {
                            return (
                                <>
                                    <tr key={item.id}>
                                        <td className="text-start" width="10%">{item.id}</td>
                                        <td className="text-start" width="20%">{item.username}</td>
                                        <td className="text-start" width="10%">{item.roleType}</td>
                                        {/* <td className="text-center" width="10%">
                                            {item.createdAt ? standardDateFormat(item.createdAt, dateFormat + 'HH:mm:ss') : '-'}
                                        </td>
                                        <td className="text-center" width="10%">
                                            {item.updatedAt ? standardDateFormat(item.updatedAt, dateFormat + 'HH:mm:ss') : '-'}
                                        </td> */}
                                        <td className="text-center" width="10%">
                                            <OverlayTrigger placement='top' overlay={<Tooltip>Reset Password</Tooltip>}>
                                                <Button
                                                    className="border-0 bg-transparent p-0"
                                                    onClick={() => { props.handleModalManager('resetPasswordModal', true); props.setUserDetails(item) }}
                                                >
                                                    <MdLockReset className="fs-16 text-dark " />
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement='top' overlay={<Tooltip>Edit Member</Tooltip>}>
                                                <Button
                                                    className="border-0 bg-transparent p-0 ms-3"
                                                    onClick={() => { props.handleModalManager('editMemberModal', true); props.setUserDetails(item) }}
                                                >
                                                    <TbEdit className="fs-16 text-dark " />
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement='top' overlay={<Tooltip>Delete Member</Tooltip>}>
                                                <Button className="border-0 bg-transparent p-0 ms-3"
                                                    onClick={() => { props.handleModalManager('deleteMemberConfirmModal', true); props.setUserDetails(item) }}
                                                >
                                                    <AiOutlineDelete className="fs-16 text-dark " />
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr >
                                </>
                            );
                        })}
                    </tbody>
                </Table>
                <Row className='g-0'>
                    <Col md={12} className='table-pagination flex-wrap d-flex align-items-center justify-content-end'>
                        <div className='d-flex align-items-center mb-3'>
                            <span className='mx-3'> Items per page:</span>
                            <select className='form-select' aria-label="Floating label select example" onChange={handlePageSize}>
                                {props.pageSizeOptions.map((page, index) => (
                                    <option key={index} value={page.value}>{page.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className='d-flex align-items-center mb-3'>
                            <span className='mx-3'> {pageDetails.currentPage + " - " + Math.ceil(memberList.length / pageDetails.pageSize) + " of " + memberList.length}</span>
                            <Button className='light-btn px-3' variant="primary" disabled={pageDetails.currentPage === 1} onClick={() => handlePagination('PREV')}><IoChevronBackOutline className='fs-16' /></Button>
                            <Button className='light-btn px-3 ms-3' variant="primary" disabled={memberList.length < pageDetails.pageSize || pageDetails.currentPage === Math.ceil(memberList.length / pageDetails.pageSize)} onClick={() => handlePagination('NEXT')}><IoChevronForwardOutline className='fs-16' /></Button>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default MemberPane