import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HEADERS } from "../../config/appHeaders";

const apiEndPoint = process.env.REACT_APP_API_BASE_ENDPOINT;

export const getGroupStoreData = createAsyncThunk(
    'storeData',
    async (payload) => {
        const { storeId, filterType } = payload
        const response = await axios.get(`${apiEndPoint}/business/product/group/store/products/${storeId}/${filterType}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)


export const getAllProducts = createAsyncThunk(
    'products',
    async () => {
        const response = await axios.get(`${apiEndPoint}/business/products`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const createOrder = createAsyncThunk(
    'createOrder',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/operation/order`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const createCategory = createAsyncThunk(
    'category',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/business/category`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const createSubCategory = createAsyncThunk(
    'subCategory',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/business/category`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const createProduct = createAsyncThunk(
    'newProduct',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/business/product`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const addExistingProduct = createAsyncThunk(
    'addExistingProduct',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/business/product/${payload.categoryId}/${payload.productId}`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const updateCategory = createAsyncThunk(
    'updateCategory',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/business/category/${payload.id}`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const updateSubCategory = createAsyncThunk(
    'updateSubCategory',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/business/category/${payload.id}`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const deleteSubCategory = createAsyncThunk(
    'deleteSubCategory',
    async (payload) => {
        const response = await axios.delete(`${apiEndPoint}/business/category/${payload.id}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getUserTokens = createAsyncThunk(
    'tokens',
    async () => {
        const response = await axios.get(`${apiEndPoint}/operation/tokens`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getStoreDeliveryMethod = createAsyncThunk(
    'methods',
    async () => {
        const response = await axios.get(`${apiEndPoint}/business/store/delivery-methods`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getAllDeliveryMethods = createAsyncThunk(
    'methods',
    async () => {
        const response = await axios.get(`${apiEndPoint}/business/all-deliveryMethods`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getPaymentMethods = createAsyncThunk(
    'payment-methods',
    async () => {
        const response = await axios.get(`${apiEndPoint}/business/all-paymentMethods`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const changeProductImage = createAsyncThunk(
    'image',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/operation/resource/upload/products`, payload, {
            headers: HEADERS.FILE(),
        })
        return response.data;
    }
)

export const updateProduct = createAsyncThunk(
    'updateProduct',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/business/product/${payload.id}`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const deleteCategory = createAsyncThunk(
    'deleteCategory',
    async (payload) => {
        const response = await axios.delete(`${apiEndPoint}/business/category/${payload.id}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const deleteProduct = createAsyncThunk(
    'updateProduct',
    async (payload) => {
        const response = await axios.delete(`${apiEndPoint}/business/product/${payload.categoryId}/${payload.productId}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const checkEcomConnection = createAsyncThunk(
    'onlineStatus',
    async () => {
        const response = await axios.get(`${apiEndPoint}/operation/e-store/status`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const connectToEcomOnline = createAsyncThunk(
    'connect',
    async (payload) => {
        const besicData = btoa(payload.username + ':' + payload.password);
        const response = await axios.post(`${apiEndPoint}/operation/e-store/connect`, payload, {
            headers: HEADERS.LOGIN(besicData),
        })
        return response.data;
    }
)

export const disconnectEComConnection = createAsyncThunk(
    'onlineStatus',
    async () => {
        const response = await axios.get(`${apiEndPoint}/operation/e-store/disconnect`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getProductById = createAsyncThunk(
    'getProduct',
    async (payload) => {
        const response = await axios.get(`${apiEndPoint}/business/product/${payload}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const searchCustomer = createAsyncThunk(
    'searchCustomer',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/operation/search-customer`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
);

export const getCustomerAddress = createAsyncThunk(
    'searchCustomer',
    async (payload) => {
        const response = await axios.get(`${apiEndPoint}/operation/customer/${payload}/address`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
);
