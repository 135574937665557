import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateSubCategory } from "../../../../../actions/billdesk/BillDeskActions";
import NumberField from "../../../../../components/common/App/NumberField";

let isDataChangeFlag = false;
const EditSubCategory = ({ showModal, hideModal, handleToastManager, productList, selectedCategory, selectedSubCategory }) => {
    // useDispatch hook for dispatching actions
    const dispatch = useDispatch();

    // current added product.
    const [currentSubCategory, setCurrentSubCategory] = useState({});
    // loader for button
    const [loaderState, setLoaderState] = useState({ save: false, delete: false });

    useEffect(() => {
        if (showModal) {
            setCurrentSubCategory({
                ...selectedSubCategory,
                parentCategoryId: selectedCategory?.id || ""
            });
        }
    }, [selectedCategory, selectedSubCategory, showModal]);

    const onHandleChange = (event) => {
        const { name, value } = event.target;
        setCurrentSubCategory((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Handle form submission
    const onSubmit = async () => {
        try {
            setLoaderState({ ...loaderState, save: true });
            await dispatch(updateSubCategory(currentSubCategory))
                .then((response) => {
                    if (response.payload && !response.payload.error) {
                        isDataChangeFlag = true
                        handleToastManager({ visible: true, toastMessage: 'Sub Category updated successfully', variant: "Success" });
                    } else {
                        handleToastManager({ visible: true, toastMessage: 'Failed to update sub category. Please try again.', variant: "Danger" });
                    }
                    setLoaderState({ ...loaderState, save: false });
                })
        } catch (err) { }
    };

    return (
        <Modal size="md" centered backdrop="static" keyboard={false} show={showModal} onHide={() => hideModal(isDataChangeFlag)}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Sub Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12}>
                        <Form.Floating>
                            <Form.Select
                                aria-label="Floating label select example"
                                name="parentCategoryId"
                                value={currentSubCategory.parentCategoryId || ""}
                                onChange={onHandleChange}
                            >
                                <option value="">Select Category</option>
                                {productList.map(st => (
                                    <option key={st.id} value={st.id}>
                                        {st.catAlias}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Label htmlFor="floatingSelect">Category Name</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12}>
                        <Form.Floating className="">
                            <Form.Control
                                type="text"
                                autoComplete='off'
                                name="catAlias"
                                id="floatingInputCustom"
                                placeholder="Category Name"
                                value={currentSubCategory.catAlias || ""}
                                onChange={onHandleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Sub Category Name</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12} className="">
                        <Form.Floating className="">
                            <NumberField
                                autoComplete='off'
                                name="displayOrder"
                                id="floatingInputCustom"
                                placeholder="Display Order"
                                value={currentSubCategory.displayOrder || ""}
                                onChange={onHandleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Display Order</Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-end'>
                    <Col md={3} lg={3}>
                        {loaderState.save === false ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={(e) => onSubmit(e)}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default EditSubCategory;
