import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createCategory } from '../../../../../actions/billdesk/BillDeskActions';
import { validateFields } from "../../../../../util/utility-helper";
import NumberField from "../../../../../components/common/App/NumberField";

const initialState = { catAlias: '', icon: '', catType: '', displayOrder: '', status: 'ACTIVE', syncStatus: 'N' };
const intialButtonState = { disable: true, loading: false };
const AddCategory = ({ showModal, hideModal, handleToastManager, selCatType }) => {
    // useDispatch hook for dispatching actions
    const dispatch = useDispatch();

    // current added product.
    const [categoryData, setCategoryData] = useState({});
    const [buttonState, setButtonState] = useState({ ...intialButtonState })

    useEffect(() => {
        if (showModal) {
            setButtonState({ ...intialButtonState });
            setCategoryData({ ...initialState, catType: selCatType });
        }
    }, [showModal, selCatType]);

    const onHandleChange = (event) => {
        const { name, value } = event.target;
        setCategoryData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setButtonState({ ...buttonState, disable: validateFields(['catAlias'], { ...categoryData, [name]: value }) });
    };

    // Handle form submission
    const onSubmit = async () => {
        try {
            setButtonState({ ...buttonState, loading: true });
            await dispatch(createCategory(categoryData)).then((response) => {
                if (response.payload && !response.payload.error) {
                    handleToastManager({ visible: true, toastMessage: 'Category created successfully', variant: "Success" });
                    setTimeout(() => {
                        hideModal(true); // Close the modal
                    }, 1000);
                } else {
                    handleToastManager({ visible: true, toastMessage: 'Failed to create category. Please try again.', variant: "Danger" });
                }
                setButtonState({ ...buttonState, loading: false });
            })
        } catch (err) { }
    };



    return (
        <Modal size="md" centered backdrop="static" keyboard={false} show={showModal} onHide={hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Add Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12}>
                        <Form.Floating className="">
                            <Form.Control
                                type="text"
                                autoComplete='off'
                                name="catAlias"
                                id="floatingInputCustom"
                                placeholder="Category Name"
                                value={categoryData.catAlias}
                                onChange={onHandleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Category Name<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12} className="">
                        <Form.Floating className="">
                            <NumberField
                                autoComplete='off'
                                name="displayOrder"
                                id="floatingInputCustom"
                                placeholder="Display Order"
                                value={categoryData.displayOrder}
                                onChange={onHandleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Display Order</Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-end'>
                    <Col md={3} lg={3}>
                        {buttonState.loading === false ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" onClick={onSubmit} disabled={buttonState.disable}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" >
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default AddCategory;