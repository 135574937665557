import React from "react";
import { Col, Form } from "react-bootstrap";

const SettingAddStorePane = ({ deliveryMethods, paymentMethods, addStoreData, storeList, handleChange, handleMethodChange }) => {

    return (
        <>
            <Col md={12}>
                <div className="d-flex">
                    <Form.Label htmlFor="floatingInputCustom">Delivery Methods<span className="text-danger me-2">*</span></Form.Label>
                    {deliveryMethods && deliveryMethods.map((methods, index) => {
                        return (

                            <Form.Check
                                key={index}
                                className="ms-3"
                                type="checkbox"
                                name="storeDeliveryMethods"
                                id="storeDeliveryMethods"
                                value={methods.methodCode}
                                label={methods.name}
                                onChange={handleMethodChange}
                            />

                        )
                    })}
                </div>
            </Col>
            <Col md={12}>
            <div className="d-flex">
                <Form.Label htmlFor="floatingInputCustom">Payment Methods<span className="text-danger">*</span></Form.Label>
                {paymentMethods && paymentMethods.map((methods, index) => {
                    return (
                        <Form.Check
                            key={index}
                            className="ms-3"
                            type="checkbox"
                            name="storePaymentMethods"
                            id={methods.methodCode}
                            value={methods.methodCode}
                            label={methods.description}
                            onChange={handleMethodChange}
                        />
                    )
                })}
                </div>
            </Col>
            <Col md={8}>
                <Form.Floating className="">
                    <Form.Control
                        type="textarea"
                        autoComplete="off"
                  z      name="aboutStore"
                        id="aboutStore"
                        placeholder="AboutStore"
                        value={addStoreData.aboutStore}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">About Store</Form.Label>
                </Form.Floating>
            </Col>
 

            <Col md={4}>
                <Form.Floating className="">
                    <Form.Select
                        name="rootStoreId"
                        id="rootStoreId"
                        placeholder="Parent Store"
                        value={addStoreData.rootStoreId}
                        onChange={handleChange}
                    >
                        <option value="" disabled={addStoreData.rootStoreId} >Parent Store</option>
                        {storeList && storeList.map((store, indx) => {
                            return (
                                <option key={indx} value={store.id} selected={addStoreData.rootStoreId === store.id}>{store.name}</option>
                            )
                        })}
                    </Form.Select>
                    <Form.Label htmlFor="rootStoreId">Parent Store</Form.Label>
                </Form.Floating>
            </Col>
        </>
    );
};

export default SettingAddStorePane;
