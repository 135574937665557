import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { getUserById, updateUserPassword } from "../../../../../actions/admin/AdminActions";
import { LuEye, LuEyeOff } from "react-icons/lu";


const ResetPassword = ({ showModal, hideModal, selectedUser, handleToastManager, fetchAllUserList }) => {
    // useDispatch hook for dispatching actions
    const dispatch = useDispatch();
    const [updatedPassword, setUpdatedPassword] = useState({ password: '', confirmPassword: '' });

    const [currentSelUser, setCurrentSelUser] = useState({})


    // password hide show
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    // loader for button
    const [loaderState, setLoaderState] = useState({ save: false, });

    // Update state when selectedUser changes
    useEffect(() => {
        if (showModal) {
            setUpdatedPassword({password:"",confirmPassword:""});
            getUserDetailsById()
        }
    }, [selectedUser,showModal]);

    const getUserDetailsById = async () => {
        await dispatch(getUserById(selectedUser.id)).then((response) => {
            if (response.payload && !response.payload.error) {
                setCurrentSelUser(response.payload)
            }
        })
    }

    // Handle input changes and update the state
    const onHandleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedPassword((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/;
        return regex.test(password);
    };

    const handlePasswordReset = async () => {
        if (updatedPassword.password !== updatedPassword.confirmPassword) {
            handleToastManager({ visible: true, toastMessage: 'Passwords do not match. Please try again.', variant: "Danger" });
            return;
        }

        if (!validatePassword(updatedPassword.password)) {
            handleToastManager({ visible: true, toastMessage: 'Password must be at least 8 characters long and contain letters, numbers, and special characters.', variant: "Danger" });
            return;
        }

        try {
            setLoaderState({ ...loaderState, save: true });
            const payload = {
                ...currentSelUser,
                password: updatedPassword.password
            };
            await dispatch(updateUserPassword(payload))
                .then((response) => {
                    if ((response.payload && !response.payload.error)) {
                        handleToastManager({ visible: true, toastMessage: 'Password reset successfully.', variant: "Success" })
                        fetchAllUserList();
                        setUpdatedPassword([]);
                        setLoaderState({ ...loaderState, save: false });
                        setTimeout(() => {
                            hideModal(); // Close the modal
                        }, 500);
                    } else {
                        handleToastManager({ visible: true, toastMessage: response.payload.error, variant: "Danger" })
                    }
                })
        } catch (error) { }
    };


    return (
        <Modal size="md" centered backdrop="static" keyboard={false} show={showModal} onHide={hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Password Reset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12}>
                        <Form.Floating className="position-relative">
                            <Form.Control
                                type={passwordVisible ? "text" : "password"}
                                autoComplete='off'
                                name="password"
                                placeholder="Password"
                                value={updatedPassword.password || ''}
                                onChange={onHandleChange}
                            />
                            <span className="input-group-text border-0 bg-transparent view_password" onClick={() => setPasswordVisible(!passwordVisible)}>
                                {passwordVisible ?
                                    <LuEye className='fs-14' /> :
                                    <LuEyeOff className='fs-14' />
                                }
                            </span>
                            <Form.Label>Password</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12}>
                        <Form.Floating className="position-relative">
                            <Form.Control
                                type={confirmPasswordVisible ? "text" : "password"}
                                autoComplete='off'
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                value={updatedPassword.confirmPassword || ''}
                                onChange={onHandleChange}
                            />
                            <span className="input-group-text border-0 bg-transparent view_password" onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}>
                                {confirmPasswordVisible ?
                                    <LuEye className='fs-14' /> :
                                    <LuEyeOff className='fs-14' />
                                }
                            </span>
                            <Form.Label>Confirm Password</Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-end'>
                    <Col md={3} lg={3} >
                        {loaderState.save === false ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={(e) => handlePasswordReset(e)}>Reset</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default ResetPassword;
