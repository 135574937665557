import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { updateTerminalData } from "../../../../../actions/admin/AdminActions";
import { searchStoreList } from "../../../../../actions/inventory/InventoryActions";

const initalButtonState = { loading: false, disable: true }
const EditTerminal = ({ showModal, hideModal, selectedTerminal, fetchAllTerminalList, handleToastManager, memberList }) => {
    // useDispatch hook for dispatching actions
    const dispatch = useDispatch();

    // State to manage the updated terminal details
    const [updatedTerminal, setUpdatedTerminal] = useState(selectedTerminal || {});

    const [buttonState, setButtonState] = useState({ ...initalButtonState })

    const [storeList, setStoreList] = useState([]);

    useEffect(() => {
        if (showModal) {
            const options = [];
            
            setButtonState({ ...initalButtonState });
        }
    }, [showModal, memberList]);

    // Update state when selectedTerminal changes
    useEffect(() => {
        if (selectedTerminal) {
            getStoreByApi();
            setUpdatedTerminal(selectedTerminal);
        }
    }, [selectedTerminal]);

    const getStoreByApi = async () => {
        await dispatch(searchStoreList({ 'searchFilter': "" }))
            .then((response) => {
                if (response.payload && !response.payload.error) {
                    setStoreList(response.payload)
                }
            })
    };

    // Handle input changes and update the state
    const onHandleChange = (event) => {
        const { name, value } = event.target;
        setUpdatedTerminal(prevState => ({
            ...prevState,
            [name]: name === "storeId" ? Number(value) : value
        }));
        setButtonState({ ...buttonState, disable: false });
    };

    // const onUserChange = (userList = []) => {
    //     const accountIds = [];
    //     userList.forEach(user => {
    //         accountIds.push(user.value);
    //     })
    //     setUpdatedTerminal({ ...updatedTerminal, linkedAccounts: accountIds });
    //     setButtonState({ ...buttonState, disable: false });
    // };

    // Handle the update button click
    const handleTerminalUpdate = async () => {
        setButtonState({ ...buttonState, disable: false });
        await dispatch(updateTerminalData({ ...updatedTerminal, "syncStatus": "N", }))
            .then((response) => {
                if ((response.payload && !response.payload.error)) {
                    handleToastManager({ visible: true, toastMessage: 'Terminal updated successfully.', variant: "Success" })
                    fetchAllTerminalList(); // Fetch updated terminal list
                } else {
                    handleToastManager({ visible: true, toastMessage: 'Failed to updated terminal. Please try again.', variant: "Danger" })
                }
                setButtonState({ ...initalButtonState });
            })
    };

    return (
        <Modal size="md" centered backdrop="static" keyboard={false} show={showModal} onHide={hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Terminal</Modal.Title>
            </Modal.Header>
            <>
                <Modal.Body>
                    <Row className="g-3" >
                        <Col md={12}>
                            <Form.Floating>
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    name="name"
                                    id="nameInput"
                                    placeholder="Name"
                                    value={updatedTerminal.name || ''}
                                    onChange={onHandleChange}
                                />
                                <Form.Label htmlFor="floatingInputCustom">Name</Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={12}>
                            <Form.Floating className="">
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    name="description"
                                    id="description"
                                    placeholder="Description"
                                    value={updatedTerminal.description || ''}
                                    onChange={onHandleChange}
                                />
                                <Form.Label htmlFor="floatingInputCustom">Description</Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={12}>
                            <Form.Floating>
                                {/* <Form.Select
                                    name="accountId"
                                    id="userSelect"
                                    placeholder="Status"
                                    onChange={onUserChange}
                                    multiple
                                    aria-label="multiple select example"
                                    className="form-select"
                                >
                                    <option value="" disabled={updatedTerminal.accountId}>Select User</option>
                                    {memberList && memberList.map((member, idx) => {
                                        return (
                                            <option value={member.id} key={idx} selected={member.id === updatedTerminal.accountId}>{member.username}</option>
                                        )
                                    })}
                                </Form.Select> */}
                                {/* <Select
                                    onChange={onUserChange}
                                    isMulti
                                    name="username"
                                    options={userListOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                /> */}
                                {/* <Form.Label htmlFor="userSelect">Select User</Form.Label> */}
                            </Form.Floating>
                        </Col>
                        <Col md={12}>
                            <Form.Floating>
                                <Form.Select
                                    name="status"
                                    id="statusSelect"
                                    placeholder="Status"
                                    value={updatedTerminal.status}
                                    onChange={onHandleChange}
                                >
                                    <option value="" disabled>Select Status</option>
                                    <option value="ACTIVE">Active</option>
                                    <option value="INACTIVE">Inactive</option>
                                </Form.Select>
                                <Form.Label htmlFor="statusSelect">Status</Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={12}>
                            <Form.Floating>
                                <Form.Select
                                    name="storeId"
                                    id="storeIdSelect"
                                    placeholder="Store Id"
                                    value={updatedTerminal.storeId || ''}
                                    onChange={onHandleChange}
                                >
                                    <option value="" disabled>Select Store</option>
                                    {storeList.map((store, idx) => {
                                        if (store.storeType !== 'ASSEMBLY') {
                                            return (
                                                <option key={idx} value={store.id} selected={store.id === updatedTerminal.storeId}><>{store?.name} ({store.storeType})</></option>

                                            )
                                        }
                                    })}
                                </Form.Select>
                                <Form.Label htmlFor="storeIdSelect">Store</Form.Label>
                            </Form.Floating>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0'>
                    <Row className='g-3 w-100 justify-content-end mx-0'>
                        <Col md={3} lg={3} >
                            {!buttonState.loading ?
                                <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={handleTerminalUpdate} disabled={buttonState.disable}>Save</Button>
                                : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        size="sm"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>}
                        </Col>

                    </Row>
                </Modal.Footer>
            </>
        </Modal >
    )
}

export default EditTerminal;
