import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { updateCategory } from "../../../../../actions/billdesk/BillDeskActions";
import NumberField from "../../../../../components/common/App/NumberField";

let isDataChangeFlag = false;
const initalButtonState = { loading: false, disable: true }
const EditCategory = ({ showModal, hideModal, handleToastManager, selectedCategory }) => {
    // useDispatch hook for dispatching actions
    const dispatch = useDispatch();

    // current added product.
    const [currentCategory, setCurrentCategory] = useState(selectedCategory || { catAlias: '', displayOrder: '' });

    const [buttonState, setButtonState] = useState({ ...initalButtonState })

    useEffect(() => {
        if (selectedCategory) {
            setCurrentCategory(selectedCategory);
        }
    }, [selectedCategory]);

    const onHandleChange = (event) => {
        const { name, value } = event.target;
        setCurrentCategory(prevState => ({
            ...prevState,
            [name]: value
        }));
        setButtonState({ ...buttonState, disable: false })
    };

    // Handle form submission
    const onSubmit = async () => {
        try {
            setButtonState({ ...buttonState, loading: true })
            await dispatch(updateCategory({ ...currentCategory }))
                .then((response) => {
                    if ((response.payload && !response.payload.error)) {
                        isDataChangeFlag = true;
                        handleToastManager({ visible: true, toastMessage: 'Category update successfully', variant: "Success" })
                        hideModal(isDataChangeFlag);
                    } else {
                        handleToastManager({ visible: true, toastMessage: 'Failed to update category. Please try again.', variant: "Danger" })
                    }
                    setTimeout(() => {
                        setButtonState({ ...initalButtonState })
                    }, 500)
                })
        } catch (err) { }
    }

    return (
        <Modal size="md" centered backdrop="static" keyboard={false} show={showModal} onHide={() => hideModal(isDataChangeFlag)}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12}>
                        <Form.Floating className="">
                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name="catAlias"
                                placeholder="Category Name"
                                value={currentCategory.catAlias}
                                onChange={onHandleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Category Name</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12} className="">
                        <Form.Floating className="">
                            <NumberField
                                autoComplete="off"
                                name="displayOrder"
                                placeholder="Display Order"
                                value={currentCategory.displayOrder}
                                onChange={onHandleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Display Order</Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-end'>
                    <Col md={3} lg={3}>
                        {buttonState.loading === false ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={onSubmit} disabled={buttonState.disable}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

export default EditCategory;