import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HEADERS } from "../../config/appHeaders";

const apiEndPoint = process.env.REACT_APP_API_BASE_ENDPOINT;

export const userLogin = createAsyncThunk(
    'login',
    async (payload) => {
        const besicData = btoa(payload.username + ':' + payload.password);
        const response = await axios.post(`${apiEndPoint}/auth`, { storeId: payload.storeId, terminalId: payload.terminalId }, {
            headers: HEADERS.LOGIN(besicData),
        })
        return response.data;
    }
)
