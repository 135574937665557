export const SESSION_KEYS = {
    USER: 'USER',
    EXPIRY: 'EXPIRY',
    ROLE: 'USER_ROLE'
};

export const toastVariants = [
    { variant: 'Primary', name: 'Primary' },
    { variant: 'Secondary', name: 'Secondary' },
    { variant: 'Success', name: 'Success' },
    { variant: 'Danger', name: 'Error' },
    { variant: 'Warning', name: 'Warning' },
    { variant: 'Info', name: 'Info' },
    { variant: 'Light', name: 'Light' },
    { variant: 'Dark', name: 'Dark' },
]

export const states = [
    { name: "Andhra Pradesh", code: "37" }, { name: "Arunachal Pradesh", code: "12" }, { name: "Assam", code: "18" }, { name: "Bihar", code: "10" },
    { name: "Chattisgarh", code: "22" }, { name: "Goa", code: "30" }, { name: "Gujarat", code: "24" }, { name: "Haryana", code: "06" },
    { name: "Himachal Pradesh", code: "02" }, { name: "Jharkhand", code: "20" }, { name: "Karnataka", code: "29" }, { name: "Kerala", code: "32" },
    { name: "Madhya Pradesh", code: "23" }, { name: "Maharashtra", code: "27" }, { name: "Manipur", code: "14" }, { name: "Meghalaya", code: "17" },
    { name: "Mizoram", code: "15" }, { name: "Nagaland", code: "13" }, { name: "Odisha", code: "21" }, { name: "Punjab", code: "03" },
    { name: "Rajasthan", code: "08" }, { name: "Sikkim", code: "11" }, { name: "Tamil Nadu", code: "33" }, { name: "Telangana", code: "36" },
    { name: "Tripura", code: "16" }, { name: "Uttarakhand", code: "05" }, { name: "Uttar Pradesh", code: "09" }, { name: "West Bengal", code: "19" },
    // Union Territories
    { name: "Andaman and Nicobar Islands", code: "35" }, { name: "Chandigarh", code: "04" },
    { name: "Dadra and Nagra Haveli and Daman and DIU (NEWLY MERGED UT)", code: "26" }, { name: "Jammu & Kashmir", code: "01" },
    { name: "Ladakh", code: "38" }, { name: "Lakshadweep", code: "31" },
    { name: "Puducherry", code: "34" },
    // *****Additional****
    { name: "Delhi", code: "07" }
];

export const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const uomData = [
    { name: 'Pieces', value: 'pc' },
    { name: 'Grams', value: 'gram' },
    { name: 'Kilograms', value: 'Kg' },
    { name: 'Litres', value: 'litre' },
]