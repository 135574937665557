import { createSlice } from '@reduxjs/toolkit';
import { userLogin } from '../actions/signIn/SignInActions';

const initialState = {
    error: {},
    authenticated: false,
    loginDetails: {},
    storeId: null
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.authenticated = false;
            })
            .addCase(userLogin.fulfilled, (state, action) => {
                state.loginDetails = action.payload;
                state.authenticated = action.payload.statusCode === undefined || action.payload.statusCode === 401 ? false : true;
            })
            .addCase(userLogin.rejected, (state, action) => {
                state.loginDetails = {};
                state.error = action.payload;
                state.authenticated = false;
            })
    }
});


export default loginSlice.reducer;