import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import ResetPassword from "./modals/userModal/ResetPassword";
import AddMember from "./modals/userModal/AddMember";
import EditMember from "./modals/userModal/EditMember";
import AddTerminal from "./modals/terminalModal/AddTerminal";
import EditTerminal from "./modals/terminalModal/EditTerminal";
import { findStoreId, isAuthenticated } from "../../../config/appSession";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchUserData, updateUserData, deleteTerminal, deleteMember, getCustomerVerificatonList, deleteStore, activateStore, fetchAllTerminals } from "../../../actions/admin/AdminActions";
import ToastManager from "../../../components/common/App/NotificationManager";
import ConfirmDialog from "../../../components/common/App/ConfirmDialog";
import MemberPane from "./widgets/MemberPane";
import TerminalPane from "./widgets/TerminalsPane";
import CustomerVerificatonsPane from "./widgets/CustomerVerificationPane";
import StorePane from "./widgets/StorePane";
import { searchStoreList } from "../../../actions/inventory/InventoryActions";
import AddStoreModal from "./modals/storeModal/AddStoreModal";
import EditStoreModal from "./modals/storeModal/EditStoreModal";

let selectedUserDetails = null;
let selectedTerminalDetails = null;
let selectedStoreDetails = null;
const pageSizeSelectOptions = [{ name: "10", value: 10 }, { name: "20", value: 20 }, { name: "50", value: 50 }];
const initialToastState = { visible: false, toastMessage: '', variant: "" };

const Admin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [storeList, setStoreList] = useState([]);
  const [terminalList, setTerminalList] = useState([]);
  const [customerVerificatons, setCustomerVerificatons] = useState([]);
  const [memberList, setMemberList] = useState([]);

  const [modalManager, setModalManager] = useState({ modalName: '', visible: false });

  // notification message
  const [toastMessage, setToastMessage] = useState({ ...initialToastState });


  useEffect(() => {
    checkUserAuthentication();
  }, []);

  // check user is autenticated. if yes store products will get.
  const checkUserAuthentication = () => {
    const isUserLogin = isAuthenticated();
    if (!isUserLogin) {
      navigate("/user/login");
    } else {
      fetchStoreList();
      fetchAllUserList();
      handleUpdateUser();
      fetchAllTerminalList();
      fetchCustVerificationList();
    }
  };

  const fetchStoreList = async () => {
    try {
      await dispatch(searchStoreList({ 'searchFilter': "", 'includeDeleted': "Y" })).then((response) => {
        if (response && response.payload) {
          setStoreList(response.payload ? response.payload : [])
        }
      })

    } catch (err) { }
  };
  // Function to fetch all terminal list
  const fetchAllTerminalList = async () => {
    try {
      await dispatch(fetchAllTerminals()).then((response) => {
        if (response && response.payload) {
          setTerminalList(response.payload ? response.payload : [])
        }
      })
    } catch (err) { }
  };

  // Function to fetch all user list
  const fetchAllUserList = async () => {
    try {
      await dispatch(fetchUserData()).then((response) => {
        if (response && response.payload) {
          setMemberList(response.payload ? response.payload : [])
        }
      })
    } catch (err) { }

  };

  const fetchCustVerificationList = async () => {
    try {
      await dispatch(getCustomerVerificatonList(findStoreId())).then((response) => {
        if (response && response.payload) {
          setCustomerVerificatons(response.payload ? response.payload : [])
        }
      })
    } catch (err) { }
  };

  // Function to handle update user
  const handleUpdateUser = async () => {
    try {
      dispatch(updateUserData())
    } catch (err) { }
  };

  const handleModalManager = (modalName, visible) => {
    setModalManager({ ...modalManager, modalName, visible });
  };

  // Function to handle delete a member
  const deleteMemberAction = async (item) => {
    await dispatch(deleteMember(item)).then((response) => {
      if ((response.payload && !response.payload.error)) {
        handleModalManager('', false)
        fetchAllUserList();
        handleToastManager({ visible: true, toastMessage: 'Member deleted successfully', variant: "Success" })
      } else {
        handleToastManager({ visible: true, toastMessage: 'Failed to delete terminal.', variant: "Danger" })
      }
    })
  };

  // Function to handle delete a terminal
  const deleteTerminalAction = async (item) => {
    await dispatch(deleteTerminal(item)).then((response) => {
      if ((response.payload && !response.payload.error)) {
        handleModalManager('', false)
        fetchAllTerminalList();
        handleToastManager({ visible: true, toastMessage: 'Terminal deleted successfully', variant: "Success" })
      } else {
        handleToastManager({ visible: true, toastMessage: 'Failed to delete terminal.', variant: "Danger" })
      }
    })
  };

  const deleteStoreAction = async (item) => {
    await dispatch(deleteStore(item)).then((response) => {
      if ((response.payload && !response.payload.error)) {
        handleModalManager('', false)
        fetchStoreList();
        handleToastManager({ visible: true, toastMessage: 'Store deleted successfully', variant: "Success" })
      } else {
        handleToastManager({ visible: true, toastMessage: 'Failed to delete terminal.', variant: "Danger" })
      }
    })
  };

  const undoStore = async (item) => {
    await dispatch(activateStore(item)).then((response) => {
      if ((response.payload && !response.payload.error)) {
        handleModalManager('', false)
        fetchStoreList();
        handleToastManager({ visible: true, toastMessage: 'Store recover successfully', variant: "Success" })
      } else {
        handleToastManager({ visible: true, toastMessage: response.payload.error, variant: "Danger" })
      }
    })
  }

  const handleConfirm = (flag) => {
    if (flag === 'YES') {
      switch (modalManager.modalName) {
        case "deleteTerminalConfirmModal": {
          deleteTerminalAction(selectedTerminalDetails);
          break;
        } case "deleteMemberConfirmModal": {
          deleteMemberAction(selectedUserDetails);
          break;
        }
        case "deleteStoreConfirmModal": {
          deleteStoreAction(selectedStoreDetails);
          break
        }
        case "activeStoreConfirmModal": {
          undoStore(selectedStoreDetails);
          break
        }
        default: break
      }
    } else {
      handleModalManager('', false);
    }
  };

  const handleToastManager = (toastStatus) => {
    setToastMessage({ ...toastMessage, ...toastStatus });
  };

  const handleConfirmModalMessage = (modalName) => {
    switch (modalName) {
      case 'deleteTerminalConfirmModal': return "Are you sure you want to delete the terminal?"
      case 'deleteStoreConfirmModal': return "Are you sure you want to delete the store?"
      case 'deleteMemberConfirmModal': return "Are you sure you want to delete the member?"
      case 'activeStoreConfirmModal': return "Are you sure you want to active the store?"
      default: return ""
    }
  };

  const storePaneProps = { storeList: storeList, pageSizeOptions: pageSizeSelectOptions, setStoreDetails: (storeDetails) => selectedStoreDetails = storeDetails, handleModalManager: handleModalManager, }
  const memberPaneProps = { memberList: memberList, pageSizeOptions: pageSizeSelectOptions, setUserDetails: (userDetails) => selectedUserDetails = userDetails, handleModalManager: handleModalManager, }
  const terminalPaneProps = { terminalList: terminalList, pageSizeOptions: pageSizeSelectOptions, setTerminalDetails: (terminalDetails) => selectedTerminalDetails = terminalDetails, handleModalManager: handleModalManager, storeList: storeList }
  const custVerificationPaneProps = { verificationList: customerVerificatons, pageSizeOptions: pageSizeSelectOptions }

  /** define modal props. */
  const confirmModalProps = { showModal: ((modalManager.modalName === 'deleteTerminalConfirmModal' || modalManager.modalName === 'deleteMemberConfirmModal' || modalManager.modalName === 'deleteStoreConfirmModal' || modalManager.modalName === 'activeStoreConfirmModal') && modalManager.visible === true), hideModal: () => handleModalManager('', false), handleClick: handleConfirm, message: handleConfirmModalMessage(modalManager.modalName) }
  const resetPasswordModalProps = { showModal: (modalManager.modalName === 'resetPasswordModal' && modalManager.visible === true), hideModal: () => handleModalManager('', false), selectedUser: selectedUserDetails, fetchAllUserList, fetchAllUserList, handleToastManager: handleToastManager }
  const addMemberModalProps = { showModal: (modalManager.modalName === 'addMemberModal' && modalManager.visible === true), hideModal: () => handleModalManager('', false), fetchAllUserList: fetchAllUserList, handleToastManager: handleToastManager }
  const editMemberModalProps = { showModal: (modalManager.modalName === 'editMemberModal' && modalManager.visible === true), hideModal: () => handleModalManager('', false), selectedUser: selectedUserDetails, fetchAllUserList: fetchAllUserList, handleToastManager: handleToastManager, terminalList: terminalList }
  const addTerminalModalProps = { memberList: memberList, showModal: (modalManager.modalName === 'addTerminalModal' && modalManager.visible === true), hideModal: () => handleModalManager('', false), fetchAllTerminalList: fetchAllTerminalList, handleToastManager: handleToastManager }
  const editTerminalModalProps = { memberList: memberList, showModal: (modalManager.modalName === 'editTerminalModal' && modalManager.visible === true), hideModal: () => handleModalManager('', false), selectedTerminal: selectedTerminalDetails, fetchAllTerminalList: fetchAllTerminalList, handleToastManager: handleToastManager };
  const addStoreModalProps = { showModal: (modalManager.modalName === 'addStoreModal' && modalManager.visible === true), hideModal: () => handleModalManager('', false), handleToastManager: handleToastManager, fetchStoreList: fetchStoreList, storeList: storeList };
  const editStoreModalProps = { showModal: (modalManager.modalName === 'editStoreModal' && modalManager.visible === true), hideModal: () => handleModalManager('', false), fetchStoreList: fetchStoreList, handleToastManager: handleToastManager, selectedStore: selectedStoreDetails, storeList: storeList }

  return (
    <>
      <ToastManager toastManager={toastMessage} setToastManager={setToastMessage} />
      <Row className="g-0">
        <Col sm={12} md={12} lg={12}>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="Stores"
          >
            <Row className="g-3">
              <Col md={12}>
                <Nav className="card-tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="Stores">Stores</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Terminals">Terminals</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Users">Users</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="custVerification">Customer Verification</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col md={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="Stores">
                    <StorePane {...storePaneProps} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Users">
                    <MemberPane {...memberPaneProps} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Terminals">
                    <TerminalPane {...terminalPaneProps} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="custVerification">
                    <CustomerVerificatonsPane {...custVerificationPaneProps} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row >
      <ConfirmDialog {...confirmModalProps} />
      <ResetPassword {...resetPasswordModalProps} />
      <AddMember {...addMemberModalProps} />
      <EditMember {...editMemberModalProps} />
      <AddTerminal {...addTerminalModalProps} />
      <EditTerminal {...editTerminalModalProps} />
      <AddStoreModal {...addStoreModalProps} />
      <EditStoreModal {...editStoreModalProps} />
    </>
  );
};

export default Admin;
