import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Signin from './components/user/Signin';
import BillDesk from "./pages/bill-desk/billing/standard/BillDesk";
import OpenOrders from "./pages/bill-desk/open-orders/OpenOrders";
import CompleteOrder from "./pages/bill-desk/complete-orders/CompleteOrders";
import Admin from "./pages/administration/admin/Adminstration";
import Products from "./pages/administration/products/Products";
import Category from "./pages/administration/category/Category";
import Inventory from "./pages/administration/inventory/Inventory";
import PurchaseOrder from "./pages/administration/purchaseOrder/PurchaseOrder";
import InventoryHistory from "./pages/administration/inventoryHistory/InventoryHistory";
import OrderManage from "./pages/bill-desk/order-manage/OrderManage";
import Resourceploader from "./pages/administration/resource/ResourceUploader";
import UserAccess from "./components/user/UserAccess";

const AppRoutes = () => {
    const userRole = localStorage.getItem('USER_ROLE');
    return (
        <Suspense>
            <Routes>
                <Route path="/" element={<Signin />} />
                <Route path="/userAccess" element={<UserAccess />} />
                <Route path="/user/login" element={<Signin />} />
                {userRole !== 'BUSINESS_ADMIN' ?
                    <>
                        <Route path="/home" element={<BillDesk />} />
                        <Route path="/completeOrders" element={<CompleteOrder />} />
                        <Route path="/orderManage" element={<OrderManage />} />
                        <Route path="/openOrders" element={<OpenOrders />} />
                    </> : <>
                        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                        <Route path="/admin" element={<Admin />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/category" element={<Category />} />
                        <Route path="/inventory" element={<Inventory />} />
                        <Route path="/purchaseOrder" element={<PurchaseOrder />} />
                        <Route path="/inventoryHistory" element={<InventoryHistory />} />
                        <Route path="/completeOrders" element={<CompleteOrder />} />
                        <Route path="/resource" element={<Resourceploader />} />
                    </>}
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
