import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createNewMember } from "../../../../../actions/admin/AdminActions"; // Import createNewMember action from HomeActions
import { searchStoreList } from "../../../../../actions/inventory/InventoryActions";
import { findStoreId } from "../../../../../config/appSession";

const initialMemberState = { roleType: "", username: "", status: "", storeId: "", businessId: "", password: "" };
const mandatoryFields = ['roleType', 'username', 'status', 'storeId', 'businessId', 'password'];
const AddMember = ({ showModal, hideModal, fetchAllUserList, handleToastManager }) => {
  // useDispatch hook for dispatching actions
  const dispatch = useDispatch();

  // State hooks for managing form data and messages
  const [newMember, setNewMember] = useState({ ...initialMemberState });
  const [storeList, setStoreList] = useState([]);
  // loader for button
  const [buttonState, setButtonState] = useState({ loader: false, disable: true })

  useEffect(() => {
    if (showModal) {
      setNewMember({ ...initialMemberState, businessId: findStoreId() })
      getUserStores()
    }
  }, [showModal])

  const getUserStores = async () => {
    dispatch(searchStoreList({ 'searchFilter': "" }))
      .then((resp) => {
        if (resp.payload) {
          setStoreList(resp.payload)
        }
      })
  }

  // Handle change function for input fields
  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setNewMember((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
    validateFields({ ...newMember, [name]: value })
  };

  const validateFields = (newMemberDetails) => {
    let disableBtn = false
    mandatoryFields.forEach(fieds => {
      if (newMemberDetails[fieds] === undefined || newMemberDetails[fieds] === null || newMemberDetails[fieds] === "") {
        disableBtn = true;
      }
    })
    setButtonState({ ...buttonState, disable: disableBtn })
  };

  // Form submission function
  const handleNewMemberCreate = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      setButtonState({ ...buttonState, save: true });
      await dispatch(createNewMember({ ...newMember })).then((response) => {
        if (response.payload && !response.payload.error) {
          fetchAllUserList();
          handleToastManager({ visible: true, toastMessage: 'Member created successfully.', variant: "Success" })
          setTimeout(() => {
            hideModal();
          }, 1000);
        } else {
          handleToastManager({ visible: true, toastMessage: response.payload.error, variant: "Danger" })
        }
        setButtonState({ ...buttonState, save: false });
      })
    } catch (error) { } finally {
      setButtonState({ ...buttonState, save: false });
    }
  };

  // Render modal with form
  return (
    <Modal size="lg" centered backdrop="static" keyboard={false} show={showModal} onHide={hideModal}>
      <Modal.Header className="border-0 pb-0" closeButton>
        <Modal.Title className="fs-5 fw-bold">Add Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-3">
          <Col md={6}>
            <Form.Floating>
              <Form.Control
                type="text"
                autoComplete="off"
                name="username"
                id="usernameInput"
                placeholder="Username"
                value={newMember.username}
                onChange={onHandleChange}
              />
              <Form.Label htmlFor="usernameInput">Username<span className="text-danger">*</span></Form.Label>
            </Form.Floating>
          </Col>
          <Col md={6}>
            <Form.Floating>
              <Form.Control
                type="password"
                autoComplete="off"
                name="password"
                id="password"
                placeholder="Password"
                value={newMember.password}
                onChange={onHandleChange}
              />
              <Form.Label htmlFor="usernameInput">Password<span className="text-danger">*</span></Form.Label>
            </Form.Floating>
          </Col>
          {/* <Col md={6}>
            <Form.Floating>
              <Form.Control
                type="email"
                autoComplete="off"
                name="email"
                id="emailInput"
                placeholder="Email"
                value={newMember.email}
                onChange={onHandleChange}
              />
              <Form.Label htmlFor="emailInput">Email<span className="text-danger">*</span></Form.Label>
            </Form.Floating>
          </Col> */}
          <Col md={6}>
            <Form.Floating>
              <Form.Select
                name="roleType"
                id="roleTypeSelect"
                placeholder="Role Type"
                value={newMember.roleType}
                onChange={onHandleChange}
              >
                <option value="" disabled>Select Role Type</option>
                {/* <option value="Admin">Admin</option> */}
                <option value="BUSINESS_ADMIN">Business Admin</option>
                <option value="BUSINESS_MANAGER">Business Manager</option>
              </Form.Select>
              <Form.Label htmlFor="roleTypeSelect">Role Type<span className="text-danger">*</span></Form.Label>
            </Form.Floating>
          </Col>
          <Col md={6}>
            <Form.Floating>
              <Form.Select
                name="status"
                id="statusSelect"
                placeholder="Status"
                value={newMember.status}
                onChange={onHandleChange}
              >
                <option value="" disabled>Select Status</option>
                <option value="Active">Active</option>
                <option value="Inactive">In-Active</option>
              </Form.Select>
              <Form.Label htmlFor="statusSelect">Status<span className="text-danger">*</span></Form.Label>
            </Form.Floating>
          </Col>
          <Col md={6}>
            <Form.Floating>
              <Form.Select
                name="storeId"
                id="storeIdSelect"
                placeholder="Store Id"
                value={newMember.storeId}
                onChange={onHandleChange}
              >
                <option value="" disabled>Select Store</option>
                {storeList.map(store => {
                  return (
                    <option value={store.id} selected={store.id === newMember.storeId}><>{store?.name} ({store.storeType})</></option>
                  )
                })}
              </Form.Select>
              <Form.Label htmlFor="storeIdSelect">Select Store<span className="text-danger">*</span></Form.Label>
            </Form.Floating>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="border-0 pt-0">
        <Row className="g-3 w-100 justify-content-end">
          <Col md={3} lg={2}>
            {buttonState.loader === false ?
              <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={(e) => handleNewMemberCreate(e)} disabled={buttonState.disable}>Save</Button>
              : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                <Spinner
                  as="span"
                  size="sm"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              </Button>}
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMember;
