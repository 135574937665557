import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Nav, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchTerminalData, updateStore } from "../../../../../actions/admin/AdminActions";
import { getAllDeliveryMethods, getPaymentMethods } from "../../../../../actions/billdesk/BillDeskActions";
import BasicEditStorePane from "./widgets/BasicEditStorePane";
import SettingEditStorePane from "./widgets/SettingEditStorePane";

const initalButtonState = { loading: false, disable: true };
const EditStoreModal = ({ showModal, hideModal, handleToastManager, selectedStore, fetchStoreList, storeList }) => {
    const dispatch = useDispatch();
    const [addStoreData, setAddStoreData] = useState({});
    const [buttonState, setButtonState] = useState({ ...initalButtonState });
    const [deliveryMethods, setDeliveryMethods] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [userAssignTerminal, setUserAssignTerminal] = useState([]);
    const [activeTab, setActiveTab] = useState('Basic');

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    useEffect(() => {
        if (showModal) {
            setAddStoreData({ ...selectedStore })
            setButtonState({ ...initalButtonState });
            setActiveTab('Basic');
            fetchTerminalDetails(selectedStore);
            getDeliveryMethods();
            fetchPaymentMethods();
        }
    }, [showModal, selectedStore]);

    const fetchTerminalDetails = async (selectedStore) => {
        if (selectedStore) {
            await dispatch(fetchTerminalData(selectedStore.id)).then((response) => {
                if (response.payload && !response.payload.error) {
                    setUserAssignTerminal(response.payload);
                }
            })
        }
    };

    const getDeliveryMethods = async () => {
        await dispatch(getAllDeliveryMethods())
            .then((response) => {
                if (response.payload) {
                    setDeliveryMethods(response.payload);
                }
            });
    };

    const fetchPaymentMethods = async () => {
        await dispatch(getPaymentMethods()).then((response) => {
            if (response.payload && !response.payload.error) {
                setPaymentMethods(response.payload);
            }
        })
    }

    const onSubmit = async () => {
        setButtonState({ ...buttonState, loading: true });
        await dispatch(updateStore({ ...addStoreData }))
            .then((response) => {
                if (response.payload && !response.payload.error) {
                    fetchStoreList();
                    hideModal(true);
                    handleToastManager({ visible: true, toastMessage: 'Store Updated.', variant: "Success" });
                } else {
                    handleToastManager({ visible: true, toastMessage: response.payload.error, variant: "Danger" });
                }
                setButtonState({ ...buttonState, loading: false });
            })
    };

    const handleChange = (event) => {
        setAddStoreData({ ...addStoreData, [event.target.name]: event.target.value });
        setButtonState({ ...buttonState, disable: false });
    };

    const handleMethodChange = (event) => {
        const { name, value } = event.target;
        let newStoreData = { ...addStoreData };
        if (String(newStoreData[name]).includes(value)) {
            newStoreData[name] = String(newStoreData[name]).replace(String(newStoreData[name]).includes('-' + value) ? '-' + value : value, '')
        } else {
            if (newStoreData[name] !== '') {
                newStoreData[name] = String(newStoreData[name]).concat('-' + value);
            } else {
                newStoreData[name] = value;
            }
        }
        setAddStoreData({ ...newStoreData })
        setButtonState({ ...buttonState, disable: false });
    };

    const generateStoreKey = () => {
        setButtonState({...buttonState,disable:false})
        setAddStoreData({ ...addStoreData, storeKey: addStoreData.storeType !== 'ASSEMBLY' ? String(addStoreData.name).substring(0, 3).toUpperCase() : null })
    };

    return (
        <Modal size="lg" centered backdrop="static" keyboard={false} show={showModal} onHide={hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Store</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3" >
                    <Col md={12}>
                        <Nav className="card-tabs" activeKey={activeTab} onSelect={handleTabSelect}>
                            <Nav.Item>
                                <Nav.Link eventKey="Basic">Basic</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Setting">Setting</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    {activeTab === 'Basic' && (
                        <BasicEditStorePane addStoreData={addStoreData} handleChange={handleChange} onStoreNameChange={generateStoreKey} />
                    )}
                    {activeTab === 'Setting' && (
                        <SettingEditStorePane deliveryMethods={deliveryMethods} paymentMethods={paymentMethods} addStoreData={addStoreData} storeList={storeList} handleChange={handleChange} handleMethodChange={handleMethodChange} userAssignTerminal={userAssignTerminal} />
                    )}
                </Row>
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
                <Row className="g-3 w-100 justify-content-end">
                    <Col md={3} lg={3} className="m-0">
                        {!buttonState.loading ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={onSubmit} disabled={buttonState.disable}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

export default EditStoreModal;