import React, { useState, useEffect } from 'react';
import { Button, Col, Dropdown, Form, Nav, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { TbEdit } from 'react-icons/tb';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import AddCategory from './modals/categoryModal/AddCategory';
import EditCategory from './modals/categoryModal/EditCategory';
import AddSubCategory from './modals/subcategoryModal/AddSubCategory';
import EditSubCategory from './modals/subcategoryModal/EditSubCategory';
import { LuSearch } from 'react-icons/lu';
import { FaPlus, FaSort } from 'react-icons/fa6';
import ToastManager from '../../../components/common/App/NotificationManager';
import { useDispatch } from 'react-redux';
import { deleteCategory, deleteSubCategory, getGroupStoreData } from '../../../actions/billdesk/BillDeskActions';
import { useSelector } from "react-redux";
import { findStoreId, isAuthenticated } from '../../../config/appSession';
import ConfirmDialog from '../../../components/common/App/ConfirmDialog';
import loader from '../../../assets/images/loading-img.gif';
import { useNavigate } from 'react-router-dom';
import { deepCopy } from '../../../util/utility-helper';

let selectedMainCat = null;
let selectedSubCat = null;
const pageSizeSelectOptions = [{ name: "10", value: 10 }, { name: "20", value: 20 }, { name: "50", value: 50 }];
const initialPageDetails = { currentPage: 1, pageSize: 10 };
const initialToastState = { visible: false, toastMessage: '', variant: "" };
const intialModalManagerState = { modalName: '', visible: false };

const Category = () => {
    // useDispatch hook for dispatching actions
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Selector to get productList from the Redux store
    const productList = useSelector((state) => state.billDesk.businessStoreData);

    const [expandedRow, setExpandedRow] = useState(null);
    const [modalManager, setModalManager] = useState({ ...intialModalManagerState });

    // page handler store page size and page number
    const [pageDetails, setPageDetails] = useState({ ...initialPageDetails });

    // notification message
    const [toastMessage, setToastMessage] = useState({ ...initialToastState });
    const [categorySearchQuery, setCategorySearchQuery] = useState("");
    const [categoryTypeFilter, setCategoryTypeFilter] = useState('OFFLINE');
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState('latest');


    // Fetch all product data on component mount
    useEffect(() => {
        if (isAuthenticated()) {
            fetchAllProductList(categoryTypeFilter);
        } else {
            navigate('/user/login')
        }
    }, []);


    // Function to fetch all product  list
    const fetchAllProductList = async (categoryTypeFilterProps) => {
        setLoading(true);
        await dispatch(getGroupStoreData({ storeId: findStoreId(), filterType: categoryTypeFilterProps }))
            .then((response) => {
                if (categoryTypeFilterProps !== categoryTypeFilter) {
                    setPageDetails({ ...pageDetails, currentPage: 1 });
                }
                setExpandedRow(null);
                if (response.payload && !response.payload.error) {
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                }
            });
    };

    const deleteMainCategoryAction = async (item) => {
        await dispatch(deleteCategory({ ...item })).then((response) => {
            if (response.payload && !response.payload.error) {
                handleToastManager({ visible: true, toastMessage: 'Category deleted successfully', variant: "Success" });
                fetchAllProductList(categoryTypeFilter);
                handleModalManager('', false);
            }
            else {
                handleToastManager({ visible: true, toastMessage: 'Failed to delete category. Please try again.', variant: "Danger" });
            }
        })
    };

    const deleteSubCategoryAction = async (item) => {
        await dispatch(deleteSubCategory({ ...item })).then((response) => {
            if (response.payload && !response.payload.error) {
                handleToastManager({ visible: true, toastMessage: 'Sub Category deleted successfully', variant: "Success" });
                fetchAllProductList(categoryTypeFilter);
                handleModalManager('', false);
            } else {
                handleToastManager({ visible: true, toastMessage: 'Failed to delete sub category. Please try again.', variant: "Danger" });
            }
        })
    };

    const handleRowClick = (idx) => {
        if (productList && productList[idx]) {
            selectedMainCat = productList[idx]
        }
        setExpandedRow(expandedRow === idx ? null : idx);
    };

    // handle user search change.
    const handleCategorySearchChange = (e) => {
        setCategorySearchQuery(e.target.value);
        setPageDetails(prevState => ({ ...prevState, currentPage: 1 })); // Reset page to 1
    };

    const sortCategoryList = (productList) => {
        if (sortBy === 'latest') {
            return productList.sort((a, b) => { return parseInt(b.id) - parseInt(a.id) });
        } else if (sortBy === 'name') {
            return productList.sort((a, b) => { return b.catAlias.localeCompare(a.catAlias) }).reverse();
        } else {
            return productList.sort((a, b) => { return parseInt(b.displayOrder) - parseInt(a.displayOrder) });
        }
    };
    // Filter users list by username or status
    const filteredCategory = sortCategoryList(deepCopy(productList)).filter(cat =>
        (cat.catAlias && cat.catAlias.toLowerCase().includes(categorySearchQuery.toLowerCase()))
    );


    const handlePagination = (direction) => {
        if (direction === 'PREV' && pageDetails.currentPage > 1) {
            setPageDetails(prevState => ({ ...prevState, currentPage: prevState.currentPage - 1 }));
        } else if (direction === 'NEXT' && pageDetails.currentPage < totalPages) {
            setPageDetails(prevState => ({ ...prevState, currentPage: prevState.currentPage + 1 }));
        }
    };
    const handlePageSize = (e) => {
        const newSize = parseInt(e.target.value, 10);
        setPageDetails(prevState => ({ ...prevState, pageSize: newSize, currentPage: 1 }));
    };

    const handleToastManager = (toastStatus) => {
        setToastMessage({ ...toastMessage, ...toastStatus });
    };

    const onChangeFilterType = async (newProductTypeFilter) => {
        fetchAllProductList(newProductTypeFilter);
        setCategoryTypeFilter(newProductTypeFilter);
    };

    const handleModalManager = (modalName, visible) => {
        setModalManager({ ...modalManager, modalName, visible });
    };

    const onHideModal = (isDataChangeFlag) => {
        if (isDataChangeFlag) {
            fetchAllProductList(categoryTypeFilter);
        }
        handleModalManager('', false);
    };

    const onDeleteConfirm = (flag) => {
        if (flag === 'YES') {
            switch (modalManager.modalName) {
                case "deleteCatConfirmModal": {
                    deleteMainCategoryAction(selectedMainCat);
                    break
                }
                case "deleteSubCatConfirmModal": {
                    deleteSubCategoryAction(selectedSubCat);
                    break
                }
                default: break;
            }
        } else {
            handleModalManager('', false)
        }
    };


    /**
     * code for pagination flow
     */
    const totalPages = Math.ceil(filteredCategory.length / pageDetails.pageSize);
    const indexOfLastItem = pageDetails.currentPage * pageDetails.pageSize;
    const indexOfFirstItem = indexOfLastItem - pageDetails.pageSize;
    const currentItems = filteredCategory.slice(indexOfFirstItem, indexOfLastItem);

    const addCategoryModalProps = { showModal: (modalManager.modalName === 'addCategoryModal' && modalManager.visible === true), hideModal: onHideModal, handleToastManager: handleToastManager, selCatType: categoryTypeFilter, fetchAllProductList: () => fetchAllProductList(categoryTypeFilter) };
    const editCategoryModalProps = { showModal: (modalManager.modalName === 'editCategoryModal' && modalManager.visible === true), hideModal: onHideModal, handleToastManager: handleToastManager, fetchAllProductList: () => fetchAllProductList(categoryTypeFilter), selectedCategory: selectedMainCat };
    const addSubCategoryModalProps = { showModal: (modalManager.modalName === 'addSubCategoryModal' && modalManager.visible === true), hideModal: onHideModal, handleToastManager: handleToastManager, selectedCategory: selectedMainCat, fetchAllProductList: () => fetchAllProductList(categoryTypeFilter), productList: productList, selCatType: categoryTypeFilter }
    const editSubCategoryModalProps = { showModal: (modalManager.modalName === 'editSubCategoryModal' && modalManager.visible === true), hideModal: onHideModal, handleToastManager: handleToastManager, fetchAllProductList: () => fetchAllProductList(categoryTypeFilter), selectedCategory: selectedMainCat, selectedSubCategory: selectedSubCat, productList: productList }
    const confirmDialogModalProps = { showModal: ((modalManager.modalName === 'deleteSubCatConfirmModal' || modalManager.modalName === 'deleteCatConfirmModal') && modalManager.visible), hideModal: onHideModal, handleClick: onDeleteConfirm, message: "Are you sure you want to delete the category?" }

    return (
        <>

            <ToastManager toastManager={toastMessage} setToastManager={setToastMessage} />

            <Row className='g-0'>
                <Col sm={12} md={12} lg={12}>
                    <Row className='g-3'>
                        <Col md={12}>
                            <Nav className="card-tabs">
                                <Nav.Item>
                                    <Nav.Link active={categoryTypeFilter === 'OFFLINE'} onClick={() => onChangeFilterType('OFFLINE')}>Finished</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link active={categoryTypeFilter === 'ASSEMBLY'} onClick={() => onChangeFilterType('ASSEMBLY')}>Assembly</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col md={12}>
                            <Row className='g-3'>
                                <Col md={6}>
                                    <Form.Floating className="search_bar">
                                        <span class="input-group-text search_icon" ><LuSearch /></span>
                                        <Form.Control className="search_input" id="productSearch" type="search" autoComplete='off' name="filterText" placeholder="Search By Category Name" value={categorySearchQuery} onChange={handleCategorySearchChange} />
                                        <Form.Label htmlFor="floatingInputCustom">Search By Category Name</Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={3} lg={3} xl={2} className=''>
                                    <Dropdown className=''>
                                        <Dropdown.Toggle className='light-btn h-56 w-100 justify-content-between px-3' id="dropdown-basic">
                                            <span>
                                                <FaSort className="me-1 fs-16" />
                                                Sort by {sortBy === 'latest' ? 'Latest' : sortBy === 'name' ? 'Name' : sortBy === 'displayOrder' ? 'DisplayOrder' : ''}
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item active={sortBy === "latest"} onClick={() => setSortBy('latest')} >Latest</Dropdown.Item>
                                            <Dropdown.Item active={sortBy === "name"} onClick={() => setSortBy('name')}>By Name</Dropdown.Item>
                                            <Dropdown.Item active={sortBy === "displayOrder"} onClick={() => setSortBy('displayOrder')}>DisplayOrder</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col md={3} lg={3} xl={2} className=''>
                                    <Button className='light-btn h-56 w-100' variant="primary" onClick={() => handleModalManager('addCategoryModal', true)}>
                                        <FaPlus className="me-1 fs-16" />
                                        Category
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Table responsive className='inso-table'>
                                <thead>
                                    <tr>
                                        <th className='text-center table_btn' width="5%">
                                            {/* <Button className='border-0 bg-transparent p-0'>
                                                <span className={`arrow_btn `}></span>
                                            </Button> */}
                                        </th>
                                        <th className='text-start' width="30%">Category Name</th>
                                        <th className='text-start' width="20%"></th>
                                        <th className='text-center' width="20%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading ?
                                        currentItems.length > 0 ? currentItems.map((category, index) => {
                                            const absoluteIndex = indexOfFirstItem + index;
                                            const isExpanded = expandedRow === absoluteIndex;
                                            return (
                                                <React.Fragment key={absoluteIndex}>
                                                    <tr>
                                                        <td className='text-center table_btn'>
                                                            <Button className='border-0 bg-transparent p-0' onClick={() => handleRowClick(absoluteIndex)}>
                                                                <span className={`arrow_btn${isExpanded ? ' expanded' : ''}`}></span>
                                                            </Button>
                                                        </td>
                                                        <td className='text-start'>{category.catAlias}</td>
                                                        <td className='text-end'>
                                                            <Button className="border-0  px-3  font-weight-bold rounded-pill" variant="outline-warning" onClick={() => { handleModalManager('addSubCategoryModal', true); selectedMainCat = category }}>
                                                                <FaPlus className='me-1 fs-16' />Sub Category
                                                            </Button>
                                                        </td>
                                                        <td className='text-center'>
                                                            <OverlayTrigger placement='top' overlay={<Tooltip>Edit Category</Tooltip>}>
                                                                <Button
                                                                    className="border-0 bg-transparent p-0"
                                                                    onClick={() => { handleModalManager('editCategoryModal', true); selectedMainCat = category }}
                                                                >
                                                                    <TbEdit className="fs-16 text-dark " />
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger placement='top' overlay={<Tooltip>Delete Category</Tooltip>}>
                                                                <Button className="border-0 bg-transparent p-0 ms-3"
                                                                    onClick={() => { handleModalManager('deleteCatConfirmModal', true); selectedMainCat = category }}
                                                                >
                                                                    <AiOutlineDelete className="fs-16 text-dark " />
                                                                </Button>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                    {isExpanded && category.categories && category.categories.map((subCategory, subIdx) => (
                                                        <tr key={`${absoluteIndex}-${subIdx}`}>
                                                            <td className='text-center'></td>
                                                            <td className='text-start ps-5'>{subCategory.catAlias}</td>
                                                            <td className='text-center'></td>
                                                            <td className='text-center'>
                                                                <OverlayTrigger placement='top' overlay={<Tooltip>Edit SubCategory</Tooltip>}>
                                                                    <Button
                                                                        className="border-0 bg-transparent p-0"
                                                                        onClick={() => { handleModalManager('editSubCategoryModal', true); selectedSubCat = subCategory }}
                                                                    >
                                                                        <TbEdit className="fs-16 text-dark " />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement='top' overlay={<Tooltip>Delete SubCategory</Tooltip>}>
                                                                    <Button className="border-0 bg-transparent p-0 ms-3"
                                                                        onClick={() => { handleModalManager('deleteSubCatConfirmModal', true); selectedSubCat = subCategory }}
                                                                    >
                                                                        <AiOutlineDelete className="fs-16 text-dark " />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            );
                                        }) :
                                            <tr>
                                                <td colSpan="12" className="text-center">No categories available</td>
                                            </tr>
                                        : <tr  >
                                            <td colSpan='12'>
                                                <div className="d-flex align-items-center justify-content-center" style={{ height: '508px' }} >
                                                    <img width='20%' src={loader} />
                                                </div>
                                            </td>
                                        </tr>}
                                </tbody>

                            </Table>
                            <Row className='g-0'>
                                <Col md={12} className='table-pagination flex-wrap d-flex align-items-center justify-content-end'>
                                    <div className='d-flex align-items-center mb-3'>
                                        <span className='mx-3'> Items per page:</span>
                                        <select className='form-select' aria-label="Floating label select example" name="productType" onChange={(e) => { handlePageSize(e) }}>
                                            {pageSizeSelectOptions.map((page, index) => (
                                                <option key={index} value={page.value}>{page.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='d-flex align-items-center mb-3'>
                                        <span className='mx-3'> {pageDetails.currentPage + " - " + Math.ceil(filteredCategory.length / pageDetails.pageSize) + " of " + filteredCategory.length}</span>
                                        <Button className='light-btn px-3' variant="primary" disabled={pageDetails.currentPage === 1} onClick={() => handlePagination('PREV')}><IoChevronBackOutline className='fs-16' /></Button>
                                        <Button className='light-btn px-3 ms-3' variant="primary" disabled={indexOfLastItem >= filteredCategory.length} onClick={() => handlePagination('NEXT')}><IoChevronForwardOutline className='fs-16' /></Button>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Col>
            </Row>
            <ConfirmDialog {...confirmDialogModalProps} />
            <AddCategory {...addCategoryModalProps} />
            <EditCategory {...editCategoryModalProps} />
            <AddSubCategory {...addSubCategoryModalProps} />
            <EditSubCategory {...editSubCategoryModalProps} />
        </>
    );
};

export default Category;
