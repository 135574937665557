import React, { useEffect, useState } from "react";
import moment from "moment";

const billDateFormat = process.env.REACT_APP_BILL_FORMAT;
const gstNumber = process.env.REACT_APP_GST_NUMBER;

const CustomerReport = ({ orderDetails }) => {

    const [orderData, setOrderData] = useState({});

    useEffect(() => {
        if (orderDetails) {
            calculateOrderTax();
        }
    }, [orderDetails]);

    const calculateOrderTax = () => {
        let newData = orderDetails;
        let cgst = 0;
        let sgst = 0;
        let subTotal = 0;
        let totalGst = 0;
        if (newData.items) {
            newData.items.forEach(itm => {
                if (itm.isPriceGstInc === "Y") {
                    let itemGst = itm.perGst ? (itm.price * itm.perGst / (100 + itm.perGst)) : 0;
                    itemGst = itemGst.toFixed(2);
                    subTotal = subTotal + (parseFloat(itm.price - itemGst) * itm.qty);
                    totalGst = totalGst + (itemGst * itm.qty);
                } else {
                    let itemGst = itm.perGst ? itm.price * itm.perGst / 100 : 0;
                    itemGst = itemGst.toFixed(2);
                    subTotal = subTotal + (parseFloat(itm.price) * itm.qty);
                    totalGst = totalGst + (itemGst * itm.qty);
                }
            })
        }
        cgst = parseFloat(totalGst.toFixed(2)) / 2;
        sgst = parseFloat(totalGst.toFixed(2)) / 2;
        const orderTotal = parseFloat((subTotal + totalGst).toFixed(2));
        setOrderData({ ...newData, sgst, cgst, subTotal, totalGst, orderTotal });
    };

    return (
        Object.keys(orderDetails).length !== 0 ?
            <div id="customerReport" style={{ display: 'inline' }} hidden={true} >
                <div class="invoice-box">
                    <table class="table-head" cellpadding="0" cellspacing="0">
                        <tr>
                            <td>
                                <strong style={{ fontSize: 28 }}>Master Food</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Main Road,<br />
                                Opp. Nidhan Wala Mod. V.P.O. Dagru<br />
                                Mobile No. - 9877196771 <br />
                                <strong>Order Type : {orderData.deliveryMethod}</strong><br />
                                <strong>GST : 129382819</strong>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-border">
                                <strong>Invoice No : {orderData?.invoiceNo}</strong><br />
                                Date : {moment(new Date(orderData.orderDate)).format(billDateFormat === 'DT' ? 'DD-MM-YYYY hh:mm:ss' : 'DD-MM-YYYY')} <br />
                                {orderData.customer !== undefined ?
                                    <>
                                        <label>
                                            Name : {orderData.customer?.name}
                                        </label>
                                        <br />
                                        <label>
                                            Mobile Number: {orderData.customer?.mobileNumber ? orderData.customer?.mobileNumber : "N/A"}
                                        </label>
                                    </> :
                                    null
                                }
                            </td>
                        </tr>
                    </table>
                    <table class="table-body" cellpadding="0" cellspacing="0">

                        <tr class="heading">
                            <td>Item</td>
                            <td>Qty</td>
                            <td>Rate</td>
                            <td>Amount</td>
                        </tr>

                        {orderData !== null && orderData.id !== undefined && orderData.items ?
                            orderData.items.map(item => {
                                return (
                                    < tr class="details">
                                        <td>{item.prodAlias || item.name}</td>
                                        <td>{item.qty}</td>
                                        <td>{item.isPriceGstInc === 'Y' ? parseFloat((parseFloat(item.price) - ((item.perGst ? (item.price * item.perGst / (100 + item.perGst)) : 0) / parseInt(item.qty)))).toFixed(2) : parseFloat(item.price).toFixed(2)}</td>
                                        <td>{item.isPriceGstInc === 'Y' ? parseFloat(((parseFloat(item.price) * parseInt(item.qty)) - (item.perGst ? (item.price * item.perGst / (100 + item.perGst)) : 0))).toFixed(2) : (parseFloat(item.price) * parseFloat(item.qty)).toFixed(2)}</td>
                                    </tr>
                                )
                            })
                            : null}
                    </table>
                    <table class="table-footer" cellpadding="0" cellspacing="0">
                        <tr class="total-item tr-p">
                            <td>  </td>
                            <td></td>
                            <td>
                                <strong>Sub Total:</strong>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <strong>{parseFloat(orderData?.subTotal).toFixed(2)}</strong>
                            </td>
                        </tr>
                        {orderData?.customer && orderData?.customer?.stateCode === "03" ?
                            <>
                                <tr class="total-item tr-p">
                                    <td>  </td>
                                    <td></td>
                                    <td>
                                        CGST:
                                    </td>
                                    <td></td>
                                    <td>2.5%</td>
                                    <td>
                                        {parseFloat(orderData?.cgst).toFixed(2)}
                                    </td>
                                </tr>
                                <tr class="total-item tr-p">
                                    <td> </td>
                                    <td></td>
                                    <td>
                                        SGST:
                                    </td>
                                    <td></td>
                                    <td>2.5%</td>
                                    <td>
                                        {parseFloat(orderData?.sgst).toFixed(2)}
                                    </td>
                                </tr>
                            </>
                            : <tr class="total-item tr-p">
                                <td> </td>
                                <td></td>
                                <td>
                                    IGST:
                                </td>
                                <td></td>
                                <td>5%</td>
                                <td>
                                    {parseFloat(orderData?.sgst).toFixed(2)}
                                </td>
                            </tr>}
                        <tr>
                            <td colSpan='6' style={{ borderBottom: '1px solid #000', padding: '0px' }}></td>
                        </tr>
                        <tr class="total-item">
                            <td></td>
                            <td> </td>
                            <td>
                                <strong>Grand Total:</strong>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <strong>{parseFloat(orderData?.orderTotal).toFixed(2)}</strong>
                            </td>
                        </tr>
                    </table>
                    <table class="last-t" cellpadding="0" cellspacing="0">
                        <tr>
                            <td>
                                <strong>THANK YOU VISIT AGAIN HAVE A NICE DAY.</strong>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontStyle: 'normal' }}>
                                GST:{gstNumber}
                            </td>
                        </tr>
                    </table>
                </div>
            </div >
            : null
    )
}

export default CustomerReport