import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import TextField from "../common/App/TextField";
import loadingIcon from "../../assets/images/loading.gif";
import masterlogo from "../../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { userLogin } from "../../actions/signIn/SignInActions";
import BackFlash from "../common/App/BackFlash";
import { createSession, isAuthenticated } from "../../config/appSession";
import { useNavigate } from "react-router-dom";

const initalCredentialState = { username: "", password: "" };

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRole = localStorage.getItem("USER_ROLE");
  const [buttonState, setButtonState] = useState("");
  const [userCredentials, setUserCredentials] = useState({
    ...initalCredentialState,
  });
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(userRole === "BUSINESS_ADMIN" ? "/admin" : "/home");
    }
  }, [userRole]);

  const onSubmit = (event) => {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setErrorMsg("");
    setButtonState("loading");
    await dispatch(userLogin({ ...userCredentials })).then((resp) => {
      if (resp.payload && resp.payload.statusCode === 200) {
        createSession(resp.payload);
        if (resp.payload.defaultRole !== "BUSINESS_ADMIN") {
          localStorage.setItem("userCredentials", JSON.stringify({ ...userCredentials }));
        }
        navigate(
          resp.payload.defaultRole === "BUSINESS_ADMIN"
            ? "/admin"
            : "/userAccess"
        );
      } else {
        if (resp.payload && resp.payload.statusCode === 401) {
          setErrorMsg("Invalid username or password.");
        } else {
          setErrorMsg("Failed to Connect");
        }
      }
      setButtonState("");
    });
  };

  const handleChange = (name, value) => {
    setUserCredentials({ ...userCredentials, [name]: value });
  };

  const username = { name: "username", placeholder: "Username", type: "text", value: userCredentials.username, id: "usernamefield", class: "form-control form-control-solid placeholder-no-fix", handler: handleChange, defaultValue: userCredentials.username, };
  const password = { name: "password", placeholder: "Password", type: "password", value: userCredentials.password, id: "passwordfield", class: "form-control form-control-solid placeholder-no-fix", handler: handleChange, defaultValue: userCredentials.password, };

  return (
    <>
      <Container fluid className="master-login">
        <Row className="g-0">
          <Col sm={6} lg={4} className="master-content position-relative">
            {errorMsg !== "" ? (
              <>
                <div className="error-box position-abosolute top-0 p-2 bg-danger">
                  {" "}
                  <p className="text-center error-message text-white  mb-0">
                    {errorMsg}
                  </p>
                </div>
              </>
            ) : (
              <div className="error-box bg-white position-abosolute top-0 p-2 ">
                {" "}
              </div>
            )}

            <div className="px-5">
              <Row className="g-0 py-3 text-center">
                <Col sm={12} lg={12}>
                  <h1 style={{ cursor: "pointer" }} onClick={() => window.open('https://easyopz.com/', "_blank")}>
                    <span className="text-warning">
                      <strong>Easy</strong>
                    </span>
                    opz
                  </h1>
                </Col>
              </Row>
              <Form onSubmit={handleSubmit} method="post" onKeyUp={onSubmit}>
                {/* {/ Sign In /} */}

                <Row className="g-3">
                  <Col md={12}>
                    <h5 className="text-center  fs-4 fw-bold">Sign In</h5>
                  </Col>
                  <Col md={12}>
                    <Form.Floating className=" d-flex">
                      <TextField {...username} />
                      <Form.Label
                        htmlFor="floatingInputCustom"
                        className="login-label"
                      >
                        Username
                      </Form.Label>
                    </Form.Floating>
                  </Col>
                  <Col md={12}>
                    <Form.Floating className=" d-flex">
                      <TextField {...password} />
                      <Form.Label
                        htmlFor="floatingInputCustom"
                        className="login-label"
                      >
                        Password
                      </Form.Label>
                    </Form.Floating>
                  </Col>
                  <Col md={12} className="mt-2">
                    <Row className="g-0">
                      <Col lg={6} sm={6} xs={6} className="text-start fs-12">
                        <Form.Check type="checkbox" label="Remember me" />
                      </Col>
                      <Col lg={6} sm={6} xs={6} className="text-end fs-12">
                        <span className="text-secondary" role="button" onClick={() => setErrorMsg('Please contact administrator.')}>
                          Forgot password?
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="form-actions mb-3">
                    {buttonState === "loading" ? (
                      <Button
                        className="light-btn w-100 h-56"
                        variant="primary"
                        name="save"
                      >
                        <Image
                          width="100px"
                          className="mx-5"
                          src={loadingIcon}
                        />
                      </Button>
                    ) : (
                      <Button
                        className="light-btn w-100 bg-light-yellow h-56"
                        variant="primary"
                        name="save"
                        onClick={handleSubmit}
                      >
                        Login
                      </Button>
                    )}
                  </Col>
                </Row>

                {/* {/ Forgot Password /} */}

                {/* <Row className='g-3'>
                <Col md={12}>
                  <h5 className='text-center  fs-4 fw-bold mb-0'>Forgot Password</h5>
                </Col>
                <Col md={12}>
                  <p className='text-center text-secondary mb-0 fs-12'>Enter  your email and we’ll send you a link to reset your password.</p>
                </Col>
                <Col md={12}>
                  <Form.Floating className=" d-flex">
                    <span class="input-group-text span-user" ><LuMail /></span>
                    <FormControl className="login-input" id="floatingInputCustom" placeholder='Email' type='text' autoComplete='off' name="location" value='' />
                    <Form.Label htmlFor="floatingInputCustom" className="login-label">Email</Form.Label>
                  </Form.Floating>
                </Col>
                <Col md={12} className='mt-2 text-start fs-12' >
                  <span className='text-danger' role="button">We cannot find your email.</span>
                </Col>
                <Col md={12} className="form-actions">
                  {buttonState === 'loading' ?
                    <Button className='light-btn w-100 h-56' variant="primary" name="save">
                      <Image width="100px" className='mx-5' src={loadingIcon} />
                    </Button> :
                    <Button className='light-btn w-100 bg-light-yellow h-56' variant="primary" name="save" onClick={handleSubmit}>
                      Reset Password
                    </Button>}
                </Col>
                <Col md={12} className="text-center fs-12">
                  <span className='text-secondary' role="button"><IoChevronBack className='fs-14' />Back to Login</span>
                </Col>
              </Row> */}

                {/* {/ Verification Code /} */}

                {/* <Row className='g-3'>
                <Col md={12}>
                  <h5 className='text-center  fs-4 fw-bold mb-0'>Verification Code</h5>
                </Col>
                <Col md={12}>
                  <p className='text-center text-secondary mb-0 fs-12'>A Verification code has been sent email.</p>
                </Col>
                <Col md={12}>
                  <Form.Floating className=" d-flex">
                    <span class="input-group-text span-user" ><LuKeyRound /></span>
                    <FormControl className="login-input" id="floatingInputCustom" placeholder='Email' type='text' autoComplete='off' name="location" value='' />
                    <Form.Label htmlFor="floatingInputCustom" className="login-label">Verification Code</Form.Label>
                  </Form.Floating>
                </Col>
                <Col md={12} className='mt-2 text-start fs-12' >
                  <span className='text-danger' role="button">Change Email</span>
                </Col>
                <Col md={12} className="form-actions mb-3">
                  {buttonState === 'loading' ?
                    <Button className='light-btn w-100 h-56' variant="primary" name="save">
                      <Image width="100px" className='mx-5' src={loadingIcon} />
                    </Button> :
                    <Button className='light-btn w-100 bg-light-yellow h-56' variant="primary" name="save" onClick={handleSubmit}>
                      Reset Password
                    </Button>}
                </Col>
              </Row> */}

                {/* {/ New Password /} */}

                {/* <Row className='g-3'>
                <Col md={12}>
                  <p className='text-center text-secondary mb-0 fs-12'>Your password must be at-least 8 characters long.</p>
                </Col>
                <Col md={12}>
                  <Form.Floating className=" d-flex">
                    <TextField  {...password} />
                    <Form.Label htmlFor="floatingInputCustom" className="login-label">Enter New Password</Form.Label>
                  </Form.Floating>
                </Col>
                <Col md={12}>
                  <Form.Floating className=" d-flex">
                    <TextField  {...password} />
                    <Form.Label htmlFor="floatingInputCustom" className="login-label">Confirm New Password</Form.Label>
                  </Form.Floating>
                </Col>
                <Col md={12} className="form-actions mb-3 mt-5">
                  {buttonState === 'loading' ?
                    <Button className='light-btn w-100 h-56' variant="primary" name="save">
                      <Image width="100px" className='mx-5' src={loadingIcon} />
                    </Button> :
                    <Button className='light-btn w-100 bg-light-yellow h-56' variant="primary" name="save" onClick={handleSubmit}>
                      Reset Password
                    </Button>}
                </Col>
              </Row> */}

                {/* {/ Done Password /} */}

                {/* <Row className='g-3'>
                <Col md={12} className='text-center'>
                  <GoCheckCircle className='text-success' style={{ fontSize: '100px' }} />
                </Col>
                <Col md={12}>
                  <h5 className='text-center  fs-4 fw-bold mb-0'>Password Changed!</h5>
                </Col>
                <Col md={12}>
                  <p className='text-center text-secondary mb-0 fs-12'>Your password has been changed successfully.</p>
                </Col>
              </Row> */}
              </Form>
              <Row>
                <Col md={12} className="text-center powered-text  mb-3 fs-12">
                  Powered by{" "}
                  <a className="text-reset" href="https://www.insonix.com/">
                    <strong>INSONIX</strong>
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <BackFlash />
    </>
  );
};

export default Login;

