import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import loader from '../../../../assets/images/loading-img.gif';
import { useNavigate } from 'react-router-dom';
import { LuUser2 } from 'react-icons/lu';
import { FaIndianRupeeSign, FaPlus } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';
import { PiArrowCounterClockwiseBold } from 'react-icons/pi';
import { useDispatch } from 'react-redux';
import { getOrderByToken, updateOrder } from '../../../../actions/order/OpenOrdersActions';
import { deepCopy, standardDateFormat } from '../../../../util/utility-helper';
import CustomerDetailModal from '../../billing/standard/modals/other/CustomerDetailModal';
import OrderTypeModal from '../../billing/standard/modals/other/OrderTypeModal';
import ConfirmDialog from '../../../../components/common/App/ConfirmDialog';
import PaymentType from '../../billing/standard/modals/other/PaymentTypeModal';
import { addNewOrderWithToken } from '../../../../slice/BillDeskSlice';
import KitchenReport from '../../../../components/reports/KitchenReports';
import { MINI_BILL_CSS_FORMAT } from '../../../../components/reports/constants';

let selectedOrder = null;
let isDataChangeFlag = false;

const initialPaymentButtonState = { payButton: { value: 0.00, disable: true }, unPayButton: { value: 0.00, disable: true } };
const initalExpandedRowState = { main: true, child: '' };
const initalModalManagerState = { modalName: '', visible: false };

const dateFormat = process.env.REACT_APP_DATE_FORMAT;

const TokenOrderDetailModal = ({ ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [tokenOrderDetail, setTokenOrderDetail] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paymentButtonState, setPaymentButtonState] = useState({ ...initialPaymentButtonState });
    const [expandedRow, setExpandedRow] = useState({ ...initalExpandedRowState });
    const [modalManager, setModalManager] = useState({ ...initalModalManagerState });

    useEffect(() => {
        if (props.showModal) {
            isDataChangeFlag = false;
            setExpandedRow({ ...initalExpandedRowState });
            getOrderDetailsByTag(props.selectedOrder);
        }
    }, [props.showModal, props.selectedOrder]);

    useEffect(() => {
        selectedOrder = null;
        if (tokenOrderDetail.filter(e => e.selectedOrder).length > 0) {
            selectedOrder = tokenOrderDetail.filter(e => e.selectedOrder)[0];
        }
        calculatePaymentStatus(tokenOrderDetail);
    }, [tokenOrderDetail]);

    const getOrderDetailsByTag = async (orderDetail) => {
        if (orderDetail) {
            setLoading(true);
            await dispatch(getOrderByToken(orderDetail.tag))
                .then(async (response) => {
                    if (response.payload) {
                        const apiResponse = await changeTokenData(response.payload || []);
                        console.log(apiResponse)
                        setTokenOrderDetail(apiResponse);
                        setLoading(false);
                    }
                })
        }
    };

    const calculatePaymentStatus = (orderDetail) => {
        let buttonState = { ...initialPaymentButtonState };
        orderDetail = orderDetail.sort(function (a, b) { return b.paymentStatus.length - a.paymentStatus.length });
        orderDetail && orderDetail.forEach(order => {
            if (order.selectedOrder && order.status !== 'CANCELLED') {
                if (order.paymentStatus === 'PARTIAL') {
                    if (parseFloat(order.orderTotal) > parseFloat(order.amountPaid)) {
                        buttonState = {
                            ...buttonState, payButton: { ...buttonState.payButton, disable: false, value: (parseFloat(order.orderTotal) - parseFloat(order.amountPaid) + parseFloat(buttonState.payButton.value)).toFixed(2) },
                            unPayButton: { ...buttonState.unPayButton, disable: false, value: (parseFloat(order.amountPaid) + parseFloat(buttonState.unPayButton.value)).toFixed(2) }
                        }
                    }
                } else {
                    if (order.paymentStatus === 'UNPAID') {
                        buttonState = { ...buttonState, payButton: { ...buttonState.payButton, disable: false, value: (parseFloat(order.orderTotal) + parseFloat(buttonState.payButton.value)).toFixed(2) } };
                    } else {
                        buttonState = { ...buttonState, unPayButton: { ...buttonState.unPayButton, disable: false, value: (parseFloat(order.orderTotal) + parseFloat(buttonState.unPayButton.value)).toFixed(2) } };
                    }
                }
            }
        })
        setPaymentButtonState({ ...buttonState });
    };

    const changeTokenData = async (orderDetail) => {
        if (orderDetail) {
            let newArr = orderDetail;
            await newArr.map((order, index) => {
                if (newArr[index]) {
                    newArr[index] = { ...newArr[index], deliveryMethod: "DINE", orderDate: newArr[index].orderDate ? standardDateFormat(newArr[index].orderDate, dateFormat) : "", selectedOrder: true };
                }
                return order;
            });
            return newArr
        }
    };

    const handleRowClick = (name, value) => {
        setExpandedRow({ ...expandedRow, [name]: value });
    };

    const handleModalManager = (modalName, visible) => {
        setModalManager({ ...modalManager, modalName, visible });
    };

    const handleCheckBox = (orderIndex, checked) => {
        let orderDetail = deepCopy(tokenOrderDetail);
        orderDetail[orderIndex].selectedOrder = checked;
        setTokenOrderDetail(orderDetail);
    };

    const addTokenOrder = () => {
        dispatch(addNewOrderWithToken({ tags: props.selectedOrder.tag, deliveryMethod: "DINE", customer: tokenOrderDetail.filter(e => e.selectedOrder).length > 0 ? tokenOrderDetail.filter(e => e.selectedOrder)[0].customer : {} }));
        navigate('/home');
    };

    const onPayOrder = async () => {
        const selectedOrderList = tokenOrderDetail.filter(e => e.selectedOrder);
        if (selectedOrderList && selectedOrderList.length > 0) {
            const partialOrders = selectedOrderList.filter(e => e.paymentStatus === 'PARTIAL');
            if (partialOrders.length > 0) {
                await changePartialToPayOrders(partialOrders, 0)
            }
            const unPaidOrders = selectedOrderList.filter(e => e.paymentStatus === 'UNPAID');
            if (unPaidOrders.length > 0) {
                selectedOrder = { orderTotal: paymentButtonState.payButton.value, paymentMethod: unPaidOrders[0].paymentMethod || "CASH" };
                handleModalManager('paymentTypeModal', true);
            }
        }
    };

    const changePartialToPayOrders = async (partialOrders, index = 0) => {
        setLoading(true);
        if (partialOrders[index]) {
            await dispatch(updateOrder({ id: partialOrders[index].id, paymentStatus: 'PAID', amountPaid: partialOrders[index].orderTotal }))
                .then((resp) => {
                    if ((resp.payload && !resp.payload.error)) {
                        if (partialOrders.length - 1 > index) {
                            changePartialToPayOrders(partialOrders, index + 1);
                        } else {
                            isDataChangeFlag = true;
                            getOrderDetailsByTag(props.selectedOrder);
                            props.handleToastManager({ visible: true, toastMessage: "Payment Status Updated.", variant: "Success" });
                        }
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Payment Status.", variant: "Danger" });
                    }
                })
        }
    };

    const changeOrderStausToComplete = async () => {
        if (tokenOrderDetail.filter(e => e.paymentStatus === 'PARTIAL').length > 0) {
            handleModalManager("partialConfirmComplete", true);
        } else {
            handleModalManager("confirmCompleteModal", true)
        }
    };

    const handleSelectAll = async (event) => {
        const flag = event.target.checked;
        let orderDetails = deepCopy(tokenOrderDetail);
        await orderDetails.map(order => {
            order.selectedOrder = flag;
            return order
        })
        setTokenOrderDetail(orderDetails);
    };

    const onChangeCustomerDetails = async (newCustomer) => {
        if (selectedOrder && (JSON.stringify(newCustomer) !== JSON.stringify(selectedOrder.customer))) {
            setLoading(true);
            await dispatch(updateOrder({ id: selectedOrder.id, customer: newCustomer }))
                .then(async (response) => {
                    if ((response.payload && !response.payload.error)) {
                        isDataChangeFlag = true;
                        getOrderDetailsByTag(props.selectedOrder)
                        props.handleToastManager({ visible: true, toastMessage: "Customer Updated.", variant: "Success" });
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Customer.", variant: "Danger" });
                    }
                })
        } else {
            props.handleToastManager({ visible: true, toastMessage: "No Order Select.", variant: "Info" });
        }
    };

    const onChangeOrderType = async (orderDetails) => {
        if (orderDetails) {
            setLoading(true);
            await dispatch(updateOrder({ id: orderDetails.id, deliveryMethod: orderDetails.deliveryMethod, deliveryAddress: orderDetails.deliveryAddress || null }))
                .then((response) => {
                    if ((response.payload && !response.payload.error)) {
                        isDataChangeFlag = true;
                        getOrderDetailsByTag(props.selectedOrder)
                        handleModalManager('', false);
                        props.handleToastManager({ visible: true, toastMessage: "Order Updated.", variant: "Success" });
                        props.onOrderTypeChange(orderDetails.deliveryMethod);
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Order.", variant: "Danger" });
                    }
                });
        }
    };

    const confirmStatus = async (flag) => {
        handleModalManager('', false)
        if (flag === 'YES') {
            switch (modalManager.modalName) {
                case "unpaidModal": {
                    setLoading(true);
                    onUnPayOrder(0);
                    break;
                } case "confirmCompleteModal": {
                    completeOrder();
                    break;
                } case "partialPaymentConfirmModal": {
                    completeWithPartialOrder(flag)
                    break;
                }
                case "confirmCancel": {
                    onCancelOrderStatus();
                    break;
                }
                case "confirmUndo": {
                    onUndoOrderStatus();
                    break;
                }
                default: return;
            }
        }
    };

    const onCancelOrderStatus = async () => {
        handleModalManager('', false);
        try {
            setLoading(true);
            await dispatch(updateOrder({ id: selectedOrder.id, status: "CANCEL" })).then((response) => {
                if ((response.payload && !response.payload.error)) {
                    isDataChangeFlag = true;
                    props.handleToastManager({ visible: true, toastMessage: "Order Status Updated.", variant: "Success" });
                    getOrderDetailsByTag(props.selectedOrder);
                } else {
                    props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Order Status.", variant: "Danger" });
                }
            });
            return;
        } catch (err) { }
    };

    const onUndoOrderStatus = async () => {
        handleModalManager('', false);
        try {
            setLoading(true);
            await dispatch(updateOrder({ id: selectedOrder.id, status: "OPEN" })).then((response) => {
                if ((response.payload && !response.payload.error)) {
                    isDataChangeFlag = true;
                    props.handleToastManager({ visible: true, toastMessage: "Order Status Updated.", variant: "Success" });
                    getOrderDetailsByTag(props.selectedOrder);
                } else {
                    props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Order Status.", variant: "Danger" });
                }
            });
            return
        } catch (err) { }
    }

    const completeWithPartialOrder = async (flag) => {
        handleModalManager("", false)
        setLoading(true);
        if (flag === "YES") {
            await dispatch(updateOrder({ id: tokenOrderDetail[0].id, status: "COMPLETE", paymentStatus: "PAID", adjustmentType: "DISCOUNT" })).then((response) => {
                if ((response.payload && !response.payload.error)) {
                    setLoading(false);
                    setTimeout(() => {
                        props.hideModal();
                    }, 500);
                    props.handleToastManager({ visible: true, toastMessage: "Order Status Updated.", variant: "Success" });
                } else {
                    props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Status.", variant: "Danger" });
                }
            });
        } else {
            await dispatch(updateOrder({ id: tokenOrderDetail[0].id, status: "COMPLETE", adjustmentType: "CREDIT" })).then((response) => {
                if ((response.payload && !response.payload.error)) {
                    setLoading(false);
                    setTimeout(() => {
                        props.hideModal();
                    }, 500);
                    props.handleToastManager({ visible: true, toastMessage: "Order Status Updated.", variant: "Success" });
                } else {
                    props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Status.", variant: "Danger" });
                }
            });
        }
    }

    const completeOrder = async () => {
        handleModalManager("confirmComplete", false);
        setLoading(true);
        await dispatch(updateOrder({ id: tokenOrderDetail[0].id, status: "COMPLETE" })).then((response) => {
            if ((response.payload && !response.payload.error)) {
                setLoading(false);
                props.handleToastManager({ visible: true, toastMessage: "Order Status Updated.", variant: "Success" });
                setTimeout(() => {
                    props.hideModal(true);
                }, 500);
            } else {
                props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Status.", variant: "Danger" });
            }
        });
    };

    const onUnPayOrder = async (index) => {
        const newArr = [];
        tokenOrderDetail.forEach(data => {
            if (data.selectedOrder && (data.paymentStatus === "PAID" || data.paymentStatus === 'PARTIAL')) {
                newArr.push(data);
            }
        })
        if (newArr.length !== 0) {
            try {
                await dispatch(updateOrder({ id: newArr[index].id, paymentStatus: 'UNPAID' })).then((response) => {
                    if (response.payload && response.payload.message === "SUCCESS") {
                        if (newArr.length - 1 > index) {
                            onUnPayOrder(index + 1);
                        } else {
                            isDataChangeFlag = true;
                            getOrderDetailsByTag(props.selectedOrder);
                            props.handleToastManager({ visible: true, toastMessage: "Payment Status Updated.", variant: "Success" });
                        }
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Payment Status.", variant: "Danger" });
                    }
                })
            } catch (err) { }
        }
    };

    const onPaymentConfirm = (orderDetail) => {
        const selectedOrderList = tokenOrderDetail.filter(e => e.selectedOrder);
        if (selectedOrderList.length > 0) {
            const unPaidOrders = selectedOrderList.filter(e => e.paymentStatus === 'UNPAID');
            setLoading(true);
            changePaymentStatusToPaid(unPaidOrders, 0, orderDetail);
        }
    };

    const changePaymentStatusToPaid = async (unPaidOrders, index, newPaymentDetails) => {
        const cancelOrderIndx = unPaidOrders.findIndex(e => e.status === 'CANCELLED');
        if (cancelOrderIndx > -1) {
            unPaidOrders.splice(cancelOrderIndx, 1);
        }
        await dispatch(updateOrder({ id: unPaidOrders[index].id, paymentStatus: newPaymentDetails.paymentStatus, paymentMethod: newPaymentDetails?.paymentMethod || "CASH", amountPaid: undefined }))
            .then((resp) => {
                if ((resp.payload && !resp.payload.error)) {
                    if (unPaidOrders.length - 1 > index) {
                        changePaymentStatusToPaid(unPaidOrders, index + 1, newPaymentDetails);
                    } else {
                        isDataChangeFlag = true;
                        getOrderDetailsByTag(props.selectedOrder);
                        props.handleToastManager({ visible: true, toastMessage: "Payment Status Updated.", variant: "Success" });
                    }
                } else {
                    props.handleToastManager({ visible: true, toastMessage: "Error While Updating Payment Status.", variant: "Danger" });
                }
            })
    };

    const changeOrderStatusOnPartial = async (paymentType) => {
        if (tokenOrderDetail && tokenOrderDetail.length > 0) {
            await dispatch(updateOrder({ id: tokenOrderDetail[0].id, status: "COMPLETE", adjustmentType: paymentType, paymentStatus: paymentType === "DISCOUNT" ? "PAID" : undefined, }))
                .then((response) => {
                    if ((response.payload && !response.payload.error)) {
                        isDataChangeFlag = true;
                        setTimeout(() => {
                            props.hideModal(true);
                        }, 1000);
                        props.handleToastManager({ visible: true, toastMessage: "Status Updated.", variant: "Success" })
                        handleModalManager('', false);
                    } else {
                        props.handleModalManager({ visible: true, toastMessage: "Error While Updating Status.", variant: "Danger" })
                    }
                });
        }
    };

    const confirmModalMessage = () => {
        switch (modalManager.modalName) {
            case "unpaidModal": return "Are you sure you want to Un-Pay the Order?";
            case "confirmCancel": return "Are you sure you want to cancel the order?";
            case "confirmUndo": return "Are you sure you want to undo the order?";
            case "confirmCompleteModal": return "Are you sure you want to complete the order?";
            default: return "";
        }
    };

    const printReport = () => {
        const miniBillCss = MINI_BILL_CSS_FORMAT;
        var divContents = document.getElementById("kitchenReport");
        let data = miniBillCss;
        var a = window.open('', '', 'height=500, width=500');
        if (a) {
            if (divContents) {
                a.document.write('<html>');
                a.document.write('<head>');
                a.document.write('<head>');
                a.document.write(data);
                a.document.write('</head>');
                a.document.write(divContents.innerHTML);
                a.document.write('</body></html>');
                a.document.close();
                a.print();
            }
        }
    };

    const customerDetailModalProps = { showModal: (modalManager.modalName === 'customerDetailModal' && modalManager.visible === true), hideModal: () => handleModalManager('', false), customerDetails: selectedOrder?.customer ? { ...selectedOrder.customer, orderDate: selectedOrder.orderDate } : {}, changeCustomerDetails: onChangeCustomerDetails };
    const orderTypeModalProps = { showModal: (modalManager.modalName === 'orderTypeModal' && modalManager.visible === true), hideModal: () => handleModalManager('', false), orderDetails: selectedOrder, changeOrderDetails: onChangeOrderType };
    const confirmModalProps = { showModal: ((modalManager.modalName === 'unpaidModal' || modalManager.modalName === 'confirmUndo' || modalManager.modalName === 'confirmCompleteModal' || modalManager.modalName === 'confirmCancel') && modalManager.visible === true), hideModal: () => handleModalManager('', false), handleClick: confirmStatus, message: confirmModalMessage() };
    const paymentTypeModalProps = { showModal: (modalManager.modalName === 'paymentTypeModal' && modalManager.visible === true), hideModal: () => handleModalManager('', false), orderDetails: selectedOrder, onPaymentDone: onPaymentConfirm, allowPartialPayment: false };
    const kitchenReportsProps = { orderType: 'DINE', orderDetails: tokenOrderDetail, tokenNo: props?.selectedOrder?.tag };

    return (
        <>
            <KitchenReport {...kitchenReportsProps} />
            {customerDetailModalProps.showModal ?
                <CustomerDetailModal {...customerDetailModalProps} /> :
                orderTypeModalProps.showModal ?
                    <OrderTypeModal {...orderTypeModalProps} /> :
                    confirmModalProps.showModal ?
                        <ConfirmDialog {...confirmModalProps} /> :
                        paymentTypeModalProps.showModal ?
                            <PaymentType paymentTypeModalProps {...paymentTypeModalProps} />
                            : modalManager.modalName === 'partialConfirmComplete' && modalManager.visible === true ?
                                <Modal size="md" backdrop="static" keyboard={false} centered show={(modalManager.modalName === 'partialConfirmComplete' && modalManager.visible === true)} onHide={() => handleModalManager('', false)}>
                                    <Modal.Header className='border-0 pb-0' closeButton />
                                    <Modal.Body>
                                        <Row className="g-3">
                                            <Col md={12} className="text-center">
                                                Do you want to consider balance as?
                                            </Col>
                                        </Row>
                                    </Modal.Body >
                                    <Modal.Footer className='border-0 pt-0'>
                                        <Row className='g-3 w-100 justify-content-center'>
                                            <Col md={3} lg={3} >
                                                <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={() => changeOrderStatusOnPartial("DISCOUNT")}>Discount</Button>
                                            </Col>
                                            <Col md={3} lg={3} >
                                                <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={() => changeOrderStatusOnPartial("CREDIT")}>Credit</Button>
                                            </Col>
                                        </Row>
                                    </Modal.Footer>
                                </Modal> :
                                <Modal backdrop="static" keyboard={false} className='modal-height' show={props.showModal} onHide={() => props.hideModal(isDataChangeFlag)} centered size='lg' scrollable>
                                    <Modal.Header className='border-0 pb-0' closeButton>
                                        <Row className='mx-0 g-3 w-100 align-items-center me-3'>
                                            <Col md={12} lg={7} className='d-sm-flex d-md-flex flex-wrap d-block align-items-center pe-4'>
                                                <Modal.Title className='fs-5 fw-bold me-auto'>Token Orders <span className='text-primary text-warning'>({props?.selectedOrder?.tag})</span></Modal.Title>
                                                <Modal.Title className='fs-5 fw-bold ms-auto'>Total: <span className='text-primary text-warning'>{(parseFloat(paymentButtonState.payButton.value) + parseFloat(paymentButtonState.unPayButton.value)).toFixed(2)}</span></Modal.Title>
                                            </Col>
                                            <Col xs={6} md={6} lg={3}>
                                                <Button className='light-btn w-100 text-truncate h-56 text-uppercase  d-flex align-items-center justify-content-center' variant="primary" onClick={() => handleModalManager('customerDetailModal', true)} disabled={tokenOrderDetail.filter(order => order.selectedOrder === true).length === 0}>
                                                    <LuUser2 className='fs-16 me-1' />Customer
                                                </Button>
                                            </Col>
                                            <Col xs={6} md={6} lg={2}>
                                                <Button className='light-btn w-100 text-truncate h-56 text-uppercase  d-flex align-items-center justify-content-center' variant="primary" onClick={addTokenOrder} disabled={tokenOrderDetail.filter(order => order.selectedOrder === true).length === 0}>
                                                    <FaPlus className='fs-16 me-1' />Order
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Modal.Header>
                                    {!loading ?
                                        <Modal.Body>
                                            <Row className='g-0'>
                                                <Col xs={12}>
                                                    <Table responsive className='inso-table'>
                                                        <thead>
                                                            <tr>
                                                                <th className='text-center table_btn' width="5%">
                                                                    <Button className='border-0 bg-transparent p-0' onClick={() => handleRowClick('main', !expandedRow.main)}>
                                                                        <span className={`arrow_btn${expandedRow.main ? ' expanded' : ''}`}></span>
                                                                    </Button>
                                                                </th>
                                                                <th className='text-center' width="10%">
                                                                    <Form.Check className='' checked={tokenOrderDetail.length === tokenOrderDetail.filter(e => e.selectedOrder).length} type='checkbox' onClick={handleSelectAll} />
                                                                </th>
                                                                <th className='text-center' width="20%">Order Date</th>
                                                                <th className='text-end' width="15%"><FaIndianRupeeSign className='' /> Amount</th>
                                                                <th className='text-center' width="15%">Order Type</th>
                                                                <th className='text-center' width="15%">Status</th>
                                                                <th className='text-center' width="10%">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {expandedRow.main ?
                                                            <tbody>
                                                                {tokenOrderDetail.map((order, index) => {
                                                                    const isExpanded = expandedRow.child === index;
                                                                    return (
                                                                        <>
                                                                            <tr key={index}>
                                                                                <td className='text-center table_btn'>
                                                                                    <Button className='border-0 bg-transparent p-0' onClick={() => handleRowClick('child', expandedRow.child === index ? null : index)}>
                                                                                        <span className={`arrow_btn${isExpanded ? ' expanded' : ''}`}></span>
                                                                                    </Button>
                                                                                </td>
                                                                                <td className='text-center'>
                                                                                    <Form.Check className={order.status === "CANCELLED" ? 'td-disabled' : ''} type='checkbox' checked={order.selectedOrder} onChange={(event) => handleCheckBox(index, event.target.checked)} />
                                                                                </td>
                                                                                <td className='text-center'>{standardDateFormat(order.orderDate, dateFormat + 'HH:mm:ss')}</td>
                                                                                <td className='text-end'>{order.orderTotal}</td>
                                                                                <td className='text-center'>
                                                                                    <Badge bg="success" role='button' className={order.status === "CANCELLED" ? 'td-disabled' : ''} onClick={() => { handleModalManager(order.selectedOrder ? 'orderTypeModal' : '', true); selectedOrder = { ...order, tags: props?.selectedOrder?.tag } }}>{order.deliveryMethod}</Badge>
                                                                                </td>
                                                                                <td className='text-center'>
                                                                                    {order.paymentStatus === "UNPAID" ?
                                                                                        <Badge bg="danger">Un-Paid</Badge> :
                                                                                        order.paymentStatus === "PAY" || order.paymentStatus === "PAID" ?
                                                                                            <Badge bg="success" >Paid</Badge> :
                                                                                            order.paymentStatus === "PARTIAL" ?
                                                                                                <Badge bg="warning">Partial</Badge> : null}
                                                                                    <Badge bg="danger" className='ms-2'>Wip</Badge>
                                                                                </td>
                                                                                <td className='text-center'>
                                                                                    {order.status !== "CANCELLED" ?
                                                                                        order.paymentStatus === 'UNPAID' ?
                                                                                            <span className='text-center' role="button" onClick={() => { handleModalManager('confirmCancel', true); selectedOrder = order }}>
                                                                                                <IoClose className='fs-16' />
                                                                                            </span> : null
                                                                                        :
                                                                                        <span className='text-center' role="button" onClick={() => { handleModalManager('confirmUndo', true); selectedOrder = order }}>
                                                                                            <PiArrowCounterClockwiseBold className='fs-16' />
                                                                                        </span>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            {isExpanded && (
                                                                                <tr>
                                                                                    <td colSpan={12} className='p-2'>
                                                                                        <Table responsive className='inso-table mb-0'>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th className='text-start' width="60%">Item Name</th>
                                                                                                    <th className='text-center' width="20%">Qty</th>
                                                                                                    <th className='text-end' width="20%"><FaIndianRupeeSign className='' /> Price</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {order.items.map((item, subIdx) => (
                                                                                                    <tr key={`${index}-${subIdx}`}>
                                                                                                        <td className='text-start'>{item.prodAlias}</td>
                                                                                                        <td className='text-center'>{item.qty}</td>
                                                                                                        <td className='text-end'>{item.price}</td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </>
                                                                    );
                                                                })}
                                                            </tbody> : null}
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </Modal.Body >
                                        : <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }} >
                                            <img width='20%' src={loader} />
                                        </div>}
                                    <Modal.Footer className='border-0 pt-0'>
                                        <Row className='g-3 w-100 justify-content-end'>
                                            <Col xs={6} sm={6} md={6} lg={2}>
                                                <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={changeOrderStausToComplete} disabled={tokenOrderDetail.filter(e => e.paymentStatus === 'UNPAID' && e.status !== "CANCELLED").length > 0}>
                                                    Complete
                                                </Button>
                                            </Col>

                                            <Col xs={6} sm={6} md={6} lg={3}>
                                                <Button
                                                    disabled={paymentButtonState.unPayButton.disable}
                                                    className='light-btn w-100 bg-light-red text-white' variant="primary" onClick={() => handleModalManager('unpaidModal', true)}>
                                                    Un-Pay <FaIndianRupeeSign className='' />
                                                    {parseFloat(paymentButtonState.unPayButton.value).toFixed(2)}
                                                </Button>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={3}>
                                                <Button
                                                    disabled={paymentButtonState.payButton.disable}
                                                    className='light-btn w-100 bg-light-green text-white' variant="primary" onClick={onPayOrder}>
                                                    Pay <FaIndianRupeeSign className='' />
                                                    {parseFloat(paymentButtonState.payButton.value).toFixed(2)}
                                                </Button>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={2}>
                                                <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={printReport} disabled={!tokenOrderDetail.filter(e => e.selectedOrder).length > 0}>
                                                    Print
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Modal.Footer>
                                </Modal >}
        </>
    )
}

export default TokenOrderDetailModal;