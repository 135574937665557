import React from "react";
import { Row, Col, Card, } from 'react-bootstrap';
import { FaIndianRupeeSign } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import noImgIcon from '../../../../../assets/images/no-image-found.png';

const ProductCard = ({ handleItemClick, product = {}, perDisc }) => {
    const showTags = process.env.REACT_APP_TAGS_REQUIRED;
    const endPoint = process.env.REACT_APP_API_BASE_ENDPOINT;
    return (
        <Card
            className="p-main-box" onClick={() => handleItemClick(product)}>
            <Card.Header>
                <Row className="g-0">
                    <Col xs={12} className="position-relative">
                        {product.icon === '' || product.icon === null || product.icon === undefined ?
                            <Card.Img className="p-img" variant="top" src={noImgIcon} /> :
                            <Card.Img className="p-img" variant="top" src={endPoint + product.icon} />}
                        {perDisc !== 0 ?
                            <div class="offer-tag">{Math.round(perDisc)}% off</div> : null}
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="position-relative">
                <Row className="g-0">
                    <Col xs={12}>
                        <Card.Subtitle className="p-title mb-0">{product.prodAlias}</Card.Subtitle>
                    </Col>
                    <Col xs={12} className="d-flex align-items-center justify-content-between">
                        <Card.Title className="p-price mb-0"> <FaIndianRupeeSign className='fs-14' /> {product.discPrice !== undefined ? product.discPrice : product.price}</Card.Title>
                        {showTags === 'Y' ?
                            product.tags === 'VEG' ?
                                <span className="p-icon veg-icon">
                                    <GoDotFill />
                                </span> :
                                product.tags === "NONVEG" ?
                                    <span className="p-icon nonveg-icon">
                                        <GoDotFill />
                                    </span>
                                    : null : null}
                    </Col>

                </Row>

            </Card.Body>
        </Card >
    )
}

export default ProductCard