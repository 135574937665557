import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import "./Card.css";
import { FaIndianRupeeSign, FaRegCircleCheck } from "react-icons/fa6";
import { BsCart3, BsCash } from "react-icons/bs";
import { FaRegTimesCircle } from "react-icons/fa"
import dineImg from '../../../../../assets/images/dineblack.png';
import pickImg from '../../../../../assets/images/packingblack.png';
import deliveryImg from '../../../../../assets/images/deliveryblack.png';
import driveImg from '../../../../../assets/images/drive.png';
import { Image } from "react-bootstrap";
import CardDetailModal from "../../modals/CardDetailModal";
import { LuClock } from "react-icons/lu";

const Card = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [currentOrder, setCurrentOrder] = useState({});

    const handleModal = () => {
        setShowModal(!showModal);
    };

    const getCurrentOrder = async (order) => {
        handleModal();
        setCurrentOrder(order);
    };
    
    return (
        <>
            <Draggable
                key={props?.id?.toString()}
                draggableId={props?.id?.toString()}
                index={props.index}
            >
                {(provided) => (
                    <>

                        <div
                            onClick={() => { getCurrentOrder(props?.card) }}
                            className={`custom__card bg-${props.deliveryMethod ? props.deliveryMethod.toLowerCase() : ""}`}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                        >
                            <div className="card__text">
                                <p className="mb-0 d-flex justify-content-between align-items-start">
                                    <span className="mb-2 fw-bold">{props.tempInvoiceNo}</span>
                                    <span className="fs-12 d-flex align-items-center"><LuClock className='fs-12 me-1' />30 mins </span>
                                </p>
                                <p className="mb-1 fs-12 d-flex align-items-center">
                                    <BsCart3 className='fs-14 me-1' />
                                    {/* <Image width='14px' className='' src={props.tag === "VEG" ? VegIcon : props.tag === "NONVEG" ? NonVegIcon : null} /> */}
                                    <span className=''>{props.itemCount} * items</span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between align-items-center">
                                    <span className="d-flex align-items-center">
                                        <Image width='16px' className='' src={props.deliveryMethod === "DINE" ? dineImg : props.deliveryMethod === "PICK" ? pickImg : props.deliveryMethod === "DELIVERY" ? deliveryImg : props.deliveryMethod === "DRIVE" ? driveImg : null} />
                                        <span className='ms-1'>{props.deliveryMethod}</span>
                                    </span>
                                    <span className="d-flex align-items-center"><BsCash className='fs-14 me-1' />{props.paymentMethod}</span>
                                </p>
                                <p className="mb-0 d-flex justify-content-between align-items-center">
                                    <span className=""><FaIndianRupeeSign className='fs-14' />{props.card ? props.card.orderTotal : "0.00"}</span>
                                    <span className={`d-flex align-items-center ${props.paymentStatus === "PAID" ? "text-success" : "text-danger"}`}>
                                        {props.paymentStatus === "PAID" ? <FaRegCircleCheck className='fs-14 me-1' /> : <FaRegTimesCircle className='fs-14 me-1' />}
                                        {props.paymentStatus}
                                    </span>
                                </p>
                            </div>
                            <div className="card__footer">
                            </div>
                            {provided.placeholder}
                        </div>
                    </>
                )
                }
            </Draggable >

            <CardDetailModal showCardDetailModal={showModal} onCloseCardDetailModal={handleModal} currentOrder={currentOrder} />
        </>
    );
};

export default Card;