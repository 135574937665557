import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Form, ListGroup, Modal, Row, Tab, } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import loader from '../../../../../../assets/images/loading-img.gif';
import { getCustomerAddress, getStoreDeliveryMethod, getUserTokens } from '../../../../../../actions/billdesk/BillDeskActions';
import { states } from '../../../../../../util/constants';
import { validateFields } from '../../../../../../util/utility-helper';
import AutoCompleter from '../../../../../../components/common/App/AutoCompleter';
import NumberField from '../../../../../../components/common/App/NumberField';

const mandatoryDeliveryFields = ['personName', 'mobileNumber', 'stateCode', 'zipCode', 'city', 'line1'];
const mandatoryPickUpFields = ['personName', 'mobileNumber',];
const deliveryAddressFields = { 'personName': '', 'line1': "", "line1": "", "stateCode": "", "defaultAddress": "", "status": "ACTIVE", "syncStatus": "N", 'mobileNumber': "" }; const OrderTypeModal = ({ ...props }) => {
    const dispatch = useDispatch();

    const [selectedOrderType, setSelectedOrderType] = useState("");
    const [tokenList, setTokenList] = useState([]);
    const [orderDetails, setOrderDetails] = useState({});
    const [orderTypeMethods, setOrderTypeMethods] = useState([]);
    const [currentFloor, setCurrentFloor] = useState(1);
    const [disableSave, setDisableSave] = useState(false);
    const [custDeliveryAddr, setCustDeliveryAddr] = useState([]);
    const [tokenAvailability, setTokenAvailability] = useState({ reserverd: 0, selected: 0, available: 0 });

    useEffect(() => {
        if (props.showModal) {
            const setProperties = async () => {
                const selOrderType = await fetchOrderTypeMethods();
                if (selOrderType === 'DELIVERY' || selOrderType === 'PICK') {
                    getDeliveryAddress(props?.orderDetails?.customer ? props?.orderDetails?.customer.id : "");
                    const isSaveDisabled = validateFields(selOrderType === 'DELIVERY' ? mandatoryDeliveryFields : mandatoryPickUpFields, (props?.orderDetails?.deliveryAddress || {}));
                    setDisableSave(isSaveDisabled);
                } else {
                    setDisableSave(false);
                }
                fetchTokens(props?.orderDetails);
                setOrderDetails({ ...props.orderDetails, deliveryMethod: selOrderType });
                setSelectedOrderType(selOrderType);
            }
            setProperties();
        }
    }, [props.showModal, props.orderDetails]);

    const fetchOrderTypeMethods = async () => {
        let selectedOrderType = null;
        await dispatch(getStoreDeliveryMethod())
            .then((response) => {
                if (response.payload) {
                    if (!props.orderDetails?.deliveryMethod) {
                        selectedOrderType = Array.isArray(response.payload) && response.payload.length > 0 ? response.payload[0].methodCode : ""
                    } else {
                        selectedOrderType = props.orderDetails?.deliveryMethod;
                    }
                    setOrderTypeMethods(response.payload);
                }
            });
        return selectedOrderType;
    };

    const fetchTokens = async (orderDetails) => {
        await dispatch(getUserTokens())
            .then((response) => {
                if (response.payload && Array.isArray(response.payload)) {
                    setTokenAvailability({ ...tokenAvailability, available: response.payload.filter(e => e.isReserved === 'N' && orderDetails.tags !== e.tokenNumber).length, reserverd: response.payload.filter(e => e.isReserved === 'Y').length, selected: orderDetails.tags ? 1 : 0 })
                    setTokenList(response.payload);
                }
            })
    };

    const handleOrderType = (orderType) => {
        if (orderType === "DINE") {
            fetchTokens(orderDetails);
        }
        else if (orderType === 'DELIVERY' || orderType === 'PICK') {
            const isSaveDisabled = validateFields(orderType === 'DELIVERY' ? mandatoryDeliveryFields : mandatoryPickUpFields, (orderDetails.deliveryAddress || {}));
            setDisableSave(isSaveDisabled)
        } else {
            setDisableSave(false);
        }
        setOrderDetails({ ...orderDetails, deliveryMethod: orderType, tags: "", deliveryAddress: orderDetails.deliveryAddress ? orderDetails.deliveryAddress : deliveryAddressFields })
        setSelectedOrderType(orderType)
    };

    const handleTokenChange = (token) => {
        setTokenAvailability({ ...tokenAvailability, available: token.tokenNumber !== orderDetails.tags ? tokenList.filter(e => e.isReserved === 'N').length - 1 : tokenAvailability.available + 1, selected: token.tokenNumber !== orderDetails.tags ? 1 : 0 });
        setOrderDetails({ ...orderDetails, tags: token.tokenNumber !== orderDetails.tags ? token.tokenNumber : "", deliveryMethod: token.tokenNumber !== orderDetails.tags ? "DINE" : "" });
    };

    const submitOrderType = () => {
        if (orderDetails.deliveryMethod !== "") {
            props.changeOrderDetails(orderDetails);
        } else {
            props.hideModal(orderDetails);
        }
    };

    const handleDeliverAddrChange = (event) => {
        if (event.target.name === "mobileNumber") {
            const numericValue = event.target.value.replace(/\D/g, ""); // Removes all non-numeric characters
            setOrderDetails({ ...orderDetails, deliveryAddress: { ...orderDetails.deliveryAddress, [event.target.name]: numericValue } })
        } else {
            setOrderDetails({ ...orderDetails, deliveryAddress: { ...orderDetails.deliveryAddress, [event.target.name]: event.target.value } })
        }
        setDisableSave(validateFields(selectedOrderType === 'DELIVERY' ? mandatoryDeliveryFields : mandatoryPickUpFields, ({ ...orderDetails?.deliveryAddress || {}, [event.target.name]: event.target.value })))
    };

    const onDeliveryAddressChange = (details) => {
        setOrderDetails({ ...orderDetails, deliveryAddress: details.selItm });
        setDisableSave(validateFields(mandatoryDeliveryFields, details.selItm));
    };

    const getDeliveryAddress = async (customerId) => {
        await dispatch(getCustomerAddress(customerId)).then((response) => {
            if ((response.payload && !response.payload.error)) {
                setCustDeliveryAddr(response.payload);
            }
        })
    };

    return (
        <>
            <Modal backdrop="static" keyboard={false} size='xl' scrollable centered show={props.showModal} onHide={props.hideModal}  >
                <Modal.Header className='border-0 pb-0' closeButton>
                    <Modal.Title className='fs-5 fw-bold'>Select Order Type</Modal.Title>
                </Modal.Header>
                {orderTypeMethods.length !== 0 ?
                    <Modal.Body>
                        <Row className='g-0'>
                            <Col xs={12} className='order_type_container'>
                                <Tab.Container id="list-group-tabs-example" activeKey={selectedOrderType}>
                                    <Row className='g-3 '>
                                        <Col xs={4} sm={4} md={4} lg={3} xl={2}>
                                            <ListGroup className='order_type'>
                                                {orderTypeMethods.map((methods, index) => {
                                                    return (
                                                        <ListGroup.Item key={index} action eventKey={index} className='py-3' onClick={() => handleOrderType(methods.methodCode)} active={selectedOrderType === methods.methodCode} >
                                                            <span className='d-md-flex flex-wrap flex-md-nowrap d-block align-items-center' >
                                                                <div className=' pe-0 pe-md-2 mb-1 mb-md-0 text-center'>
                                                                    <span className={methods.methodCode === "DINE" ? 'list_img_one' : methods.methodCode === "PICK" ? 'list_img_three' : methods.methodCode === "DELIVERY" ? 'list_img_two' : methods.methodCode === "DRIVE" ? 'list_img_four' : ''} />
                                                                </div>
                                                                <span className='fs-14 text-truncate d-block text-center me-md-auto me-0 lh-base'>
                                                                    {methods.methodCode}
                                                                </span>
                                                            </span>
                                                        </ListGroup.Item>
                                                    )
                                                })}
                                            </ListGroup>
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={9} xl={10} className=''>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="DINE">
                                                    <Row className='g-0'>
                                                        <Col xs={12}>
                                                            <Card>
                                                                <Card.Body className='p-3'>
                                                                    <Row className='g-3 grid_seat'>
                                                                        <Col xs={12} className='mt-4'>
                                                                            <div className='d-sm-flex d-md-flex flex-wrap d-block pb-0 border-0 align-items-start'>
                                                                                <div className='me-auto pe-3'>
                                                                                    <Card.Title>Seat Reservation System</Card.Title>
                                                                                </div>
                                                                                <div className='d-sm-flex d-md-flex flex-wrap d-block align-items-center justify-content-end'>
                                                                                    <div className='d-flex align-items-center justify-content-end ms-5 mb-2 mb-lg-0'>
                                                                                        <Badge bg='secondary' className='px-2 me-2 fs-12'>{tokenAvailability.available}</Badge>
                                                                                        Available
                                                                                    </div>
                                                                                    <div className='d-flex align-items-center justify-content-end ms-5 mb-2 mb-lg-0'>
                                                                                        <Badge bg='warning' className='px-2 me-2 fs-12 text-dark'>{tokenAvailability.reserverd}</Badge>
                                                                                        Reserved
                                                                                    </div>
                                                                                    <div className='d-flex align-items-center justify-content-end ms-5'>
                                                                                        <Badge bg='success' className='px-2 me-2 fs-12'>{tokenAvailability.selected}</Badge>
                                                                                        Selected
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={12}>
                                                                            <Row className="g-3">
                                                                                {/* <Col md={12} className=''>
                                                                                    <Nav className="card-tabs">
                                                                                        <Nav.Item >
                                                                                            <Nav.Link active={currentFloor === 1} onClick={() => handleFloorOptions(1)}>1st Floor</Nav.Link>
                                                                                        </Nav.Item>
                                                                                        <Nav.Item >
                                                                                            <Nav.Link active={currentFloor === 2} onClick={() => handleFloorOptions(2)}>2st Floor</Nav.Link>
                                                                                        </Nav.Item>
                                                                                        <Nav.Item >
                                                                                            <Nav.Link active={currentFloor === 3} onClick={() => handleFloorOptions(3)}>Bar</Nav.Link>
                                                                                        </Nav.Item>
                                                                                    </Nav>
                                                                                </Col> */}
                                                                                {currentFloor === 1 ?
                                                                                    <Col md={12} className=''>
                                                                                        <table className="">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    {tokenList.map(token => {
                                                                                                        if (token.isReserved === 'Y') {
                                                                                                            return (
                                                                                                                <td className='reserved'>{token.tokenNumber}</td>
                                                                                                            )
                                                                                                        } else if (orderDetails.tags === token.tokenNumber) {
                                                                                                            return (
                                                                                                                <td className='selected' onClick={() => handleTokenChange(token)}>{token.tokenNumber}</td>
                                                                                                            )
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <td className='available' onClick={() => handleTokenChange(token)}>{token.tokenNumber}</td>
                                                                                                            )
                                                                                                        }

                                                                                                    })}
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>

                                                                                    </Col> :
                                                                                    currentFloor === 2 ?
                                                                                        <Col ms={12}>
                                                                                            2st Floor
                                                                                        </Col> :
                                                                                        currentFloor === 3 ?
                                                                                            <Col ms={12}>
                                                                                                Bar
                                                                                            </Col> :
                                                                                            null
                                                                                }
                                                                                <Col xs={12}>
                                                                                    <Form.Floating className="">
                                                                                        <Form.Control type="text" as="textarea" rows={10} autoComplete='off' name="comments" id="floatingInputCustom" placeholder="Note" value={orderDetails.comments} onChange={(event) => setOrderDetails({ ...orderDetails, [event.target.name]: event.target.value })} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Note</Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>

                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="PICK" className='h-100'>
                                                    <Row className='g-0'>
                                                        <Col xs={12}>
                                                            <Card>
                                                                <Card.Body className='p-3'>
                                                                    <Row className='g-3'>
                                                                        <Col xs={12} className='mt-4'>
                                                                            <Card.Title>Pick</Card.Title>
                                                                        </Col>
                                                                        <Col xs={12}>
                                                                            <Row className="g-3">
                                                                                <Col xs={12} sm={12} md={12} lg={4}>
                                                                                    <Form.Floating className="">
                                                                                        <Form.Control type="text" autoComplete='off' value={orderDetails.deliveryAddress ? orderDetails.deliveryAddress.personName : ""} name="personName" id="floatingInputCustom" placeholder="Customer Name" onChange={handleDeliverAddrChange} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Customer Name <span className=" text-danger">*</span></Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                                <Col xs={12} sm={12} md={12} lg={4}>
                                                                                    <Form.Floating className="">
                                                                                        <NumberField maxLength={10} autoComplete='off' name="mobileNumber" id="floatingInputCustom" placeholder="Phone Number" value={orderDetails.deliveryAddress ? orderDetails.deliveryAddress.mobileNumber : ""} onChange={handleDeliverAddrChange} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Phone Number <span className=" text-danger">*</span></Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                                {/* <Col xs={12} sm={12} md={12} lg={4}>
                                                                                    <Form.Floating className="">
                                                                                        <Form.Control type="text" autoComplete='off' name="line1" id="floatingInputCustom" placeholder="Address" value={orderDetails.deliveryAddress ? orderDetails.deliveryAddress.line1 : ""} onChange={handleDeliverAddrChange} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Address <span className=" text-danger">*</span></Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col> */}
                                                                            </Row>
                                                                        </Col>
                                                                        <Col xs={12}>
                                                                            <Form.Floating className="">
                                                                                <Form.Control type="text" as="textarea" rows={10} autoComplete='off' name="comments" id="floatingInputCustom" placeholder="Note" value={orderDetails.comments} onChange={(event) => setOrderDetails({ ...orderDetails, [event.target.name]: event.target.value })} />
                                                                                <Form.Label htmlFor="floatingInputCustom">Note</Form.Label>
                                                                            </Form.Floating>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="DELIVERY">
                                                    <Row className='g-0'>
                                                        <Col xs={12}>
                                                            <Card>
                                                                <Card.Body className='p-3'>
                                                                    <Row className='g-3'>
                                                                        <Col xs={12} className='mt-4'>
                                                                            <Card.Title>Delivery</Card.Title>
                                                                        </Col>
                                                                        <Col xs={12}>

                                                                            <Row className="g-3">
                                                                                <Col md={12} className=''>
                                                                                    <AutoCompleter name="line1" data={custDeliveryAddr} onChange={onDeliveryAddressChange} placeholder="Search by customer name and mobile number" onInputChange={() => { }} extraLabel="city" />
                                                                                </Col>
                                                                                <Col xs={12} sm={12} md={12} lg={4}>
                                                                                    <Form.Floating className="">
                                                                                        <Form.Control type="text" autoComplete='off' name="personName" id="floatingInputCustom" placeholder="Customer Name" value={orderDetails.deliveryAddress ? orderDetails.deliveryAddress.personName : ""} onChange={handleDeliverAddrChange} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Customer Name <span className=" text-danger">*</span></Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                                <Col xs={12} sm={12} md={12} lg={4}>
                                                                                    <Form.Floating className="">
                                                                                        <NumberField maxLength={10} autoComplete='off' name="mobileNumber" id="floatingInputCustom" placeholder="Phone Number" value={orderDetails.deliveryAddress ? orderDetails.deliveryAddress.mobileNumber : ""} onChange={handleDeliverAddrChange} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Phone Number <span className=" text-danger">*</span></Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                                <Col xs={12} sm={12} md={12} lg={4}>
                                                                                    <Form.Floating className="">
                                                                                        <Form.Control type="text" autoComplete='off' name="line1" id="floatingInputCustom" placeholder="Address" value={orderDetails.deliveryAddress ? orderDetails.deliveryAddress.line1 : ""} onChange={handleDeliverAddrChange} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Address <span className=" text-danger">*</span></Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                                <Col xs={12} sm={12} md={12} lg={4}>
                                                                                    <Form.Floating className="">
                                                                                        <Form.Control type="text" autoComplete='off' name="city" id="floatingInputCustom" placeholder="Town / City / District" value={orderDetails.deliveryAddress ? orderDetails.deliveryAddress.city : ""} onChange={handleDeliverAddrChange} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Town / City / District <span className=" text-danger">*</span></Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                                <Col xs={12} sm={12} md={12} lg={4}>
                                                                                    <Form.Floating className="">
                                                                                        <Form.Select className='shadow-box form-control' id='floatingInputCustom' aria-label="Floating label Select State" name="stateCode" onChange={handleDeliverAddrChange}>
                                                                                            <option> Select State </option>
                                                                                            {states && states.map((state, index) => {
                                                                                                return (
                                                                                                    <option selected={state.code === (orderDetails.deliveryAddress && orderDetails?.deliveryAddress?.stateCode || "03")} key={index} value={state.code}>{state.name}</option>
                                                                                                )
                                                                                            })}
                                                                                        </Form.Select>
                                                                                        <Form.Label htmlFor="floatingInputCustom">State <span className=" text-danger">*</span></Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                                <Col xs={12} sm={12} md={12} lg={4}>
                                                                                    <Form.Floating className="">
                                                                                        <NumberField maxLength={6} autoComplete='off' name="zipCode" id="floatingInputCustom" placeholder="zipCode" value={orderDetails.deliveryAddress ? orderDetails.deliveryAddress.zipCode : ""} onChange={handleDeliverAddrChange} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">PIN Code <span className=" text-danger">*</span></Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                                <Col xs={12}>
                                                                                    <Form.Floating className="">
                                                                                        <Form.Control type="text" as="textarea" rows={10} autoComplete='off' name="comments" id="floatingInputCustom" placeholder="Note" value={orderDetails.comments} onChange={(event) => setOrderDetails({ ...orderDetails, [event.target.name]: event.target.value })} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Note</Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="DRIVE" className='h-100'>
                                                    <Row className='g-0'>
                                                        <Col xs={12}>
                                                            <Card>
                                                                <Card.Body className='p-3'>
                                                                    <Row className='g-3'>
                                                                        <Col xs={12} className='mt-4'>
                                                                            <Card.Title>Drive</Card.Title>
                                                                        </Col>
                                                                        <Col xs={12}>
                                                                            <Row className="g-3">
                                                                                <Col xs={12} sm={12} md={12} lg={4}>
                                                                                    <Form.Floating className="">
                                                                                        <Form.Control type="text" autoComplete='off' value={orderDetails.deliveryAddress ? orderDetails.deliveryAddress.personName : ""} name="personName" id="floatingInputCustom" placeholder="Customer Name" onChange={handleDeliverAddrChange} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Customer Name <span className=" text-danger">*</span></Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                                <Col xs={12} sm={12} md={12} lg={4}>
                                                                                    <Form.Floating className="">
                                                                                        <NumberField maxLength={10} autoComplete='off' name="mobileNumber" id="floatingInputCustom" placeholder="Phone Number" value={orderDetails.deliveryAddress ? orderDetails.deliveryAddress.mobileNumber : ""} onChange={handleDeliverAddrChange} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Phone Number <span className=" text-danger">*</span></Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                                <Col xs={12}>
                                                                                    <Form.Floating className="">
                                                                                        <Form.Control type="text" as="textarea" rows={10} autoComplete='off' name="comments" id="floatingInputCustom" placeholder="Note" value={orderDetails.comments} onChange={(event) => setOrderDetails({ ...orderDetails, [event.target.name]: event.target.value })} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Note</Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col>
                                                                                {/* <Col xs={12} sm={12} md={12} lg={4}>
                                                                                    <Form.Floating className="">
                                                                                        <Form.Control type="text" autoComplete='off' name="line1" id="floatingInputCustom" placeholder="Address" value={orderDetails.deliveryAddress ? orderDetails.deliveryAddress.line1 : ""} onChange={handleDeliverAddrChange} />
                                                                                        <Form.Label htmlFor="floatingInputCustom">Address <span className=" text-danger">*</span></Form.Label>
                                                                                    </Form.Floating>
                                                                                </Col> */}
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Col>
                        </Row >
                    </Modal.Body >
                    : <div className="d-flex align-items-center justify-content-center" style={{ height: '290px' }} >
                        <img width='200px' src={loader} />
                    </div>}
                <Modal.Footer className='border-0 pt-0'>
                    <Row className="g-3 justify-content-end w-100">
                        {selectedOrderType === "DINE" ?
                            <Col xs={8} sm={6} md={5} lg={3} xl={2} >
                                <Button disabled={orderDetails.tags === undefined || orderDetails.tags === ""} className="light-btn w-100 bg-light-grey" variant="primary" onClick={submitOrderType}>
                                    Confirm Booking
                                </Button>
                            </Col>
                            :
                            <Col xs={6} sm={6} md={3} lg={2} xl={2} >
                                <Button className="light-btn w-100 bg-light-grey" variant="primary" onClick={submitOrderType} disabled={disableSave} >
                                    Save
                                </Button>
                            </Col>}
                    </Row>
                </Modal.Footer>
            </Modal >
        </>
    )
};

export default OrderTypeModal;