import { createSlice } from '@reduxjs/toolkit';
import { getGroupStoreData } from '../actions/billdesk/BillDeskActions';

const initialState = {
    businessStoreData: [],
    enableCrudOperation: false,
    newOrderWithToken: null,
    addNewOrder: false
}

export const billDeskSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        changeCrudOperOnAdmin: (state, action) => {
            state.enableCrudOperation = action.payload
        },
        addNewOrderWithToken: (state, action) => {
            state.newOrderWithToken = action.payload;
        },
        refreshNewOrderProcess: (state, action) => {
            state.addNewOrder = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(getGroupStoreData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getGroupStoreData.fulfilled, (state, action) => {
                state.loading = false
                state.businessStoreData = action.payload;
            }).addCase(getGroupStoreData.rejected, (state) => {
                state.loading = false
                state.businessStoreData = [];
            })

    }

});

export const { changeCrudOperOnAdmin, addNewOrderWithToken, refreshNewOrderProcess } = billDeskSlice.actions;

export default billDeskSlice.reducer;