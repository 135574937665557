import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HEADERS } from "../../config/appHeaders";

const apiEndPoint = process.env.REACT_APP_API_BASE_ENDPOINT;

export const getStoreInventoryList = createAsyncThunk(
    'storeInventories',
    async (storeId) => {
        const response = await axios.get(`${apiEndPoint}/business/inventory/store/${storeId}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const updateInventory = createAsyncThunk(
    'updateInventory',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/business/inventory/${payload.id}`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getCategoryList = createAsyncThunk(
    'categories',
    async () => {
        const response = await axios.get(`${apiEndPoint}/business/categorys`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const createProduct = createAsyncThunk(
    'category',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/business/inventory`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const deleteProductAssembly = createAsyncThunk(
    'deleteAssembly',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/business/inventory-product-delete/${payload.storeId}/${payload.id}`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const immediateInventoryUpdate = createAsyncThunk(
    'immediateAction',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/business/immediate-action/${payload.storeId}`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getAssemlbyCategory = createAsyncThunk(
    'categories',
    async () => {
        const response = await axios.get(`${apiEndPoint}/business/assembly-categories`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getStores = createAsyncThunk(
    'getStores',
    async () => {
        const response = await axios.get(`${apiEndPoint}/business/stores`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const searchStoreList = createAsyncThunk(
    'searchStore',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/business/stores`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)