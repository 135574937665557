import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Row, Col, Container, Form, } from 'react-bootstrap';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { PiExportBold } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated } from '../../../config/appSession';
import { deepCopy, standardDateFormat } from '../../../util/utility-helper';
import { getCompleteOrders } from '../../../actions/order/CompleteOrdersAction';
import { useNavigate } from 'react-router-dom';
import OrderTable from './widgets/OrderTable';
import SingleOrderDetailModal from './modals/SingleOrderDetailModal';
import TokenOrderDetailModal from './modals/TokenOrderDetailModal';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { getAllDeliveryMethods } from '../../../actions/billdesk/BillDeskActions';
import ToastManager from '../../../components/common/App/NotificationManager';
import { changeOrderFilter } from '../../../slice/order/CompleteOrderSlice';
import Select from 'react-select';
import { fetchAllTerminals } from '../../../actions/admin/AdminActions';

let selectedOrder = null;

const initialFilterState = { startDate: "", endDate: "", };
const pageSizeSelectOptions = [{ name: "10", value: 10 }, { name: "20", value: 20 }, { name: "50", value: 50 }];
const initialPageDetails = { currentPage: 1, pageSize: 10 };
const yesterday = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() - 1));
const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const initialToastState = { visible: false, toastMessage: '', variant: "" };

const CompleteOrder = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const userRole = localStorage.getItem("USER_ROLE");

    /** redux state for filter order by status */
    const ordersFilter = useSelector((state) => state.completeOrder.orderFilter);

    /** component states */
    const [loading, setLoading] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({ ...initialFilterState });
    const [pageDetails, setPageDetails] = useState({ ...initialPageDetails });
    const [completedOrders, setCompletedOrders] = useState([]);
    const [orderToDisplay, setOrderToDisplay] = useState([]);
    const [modalManager, setModalManager] = useState({ modalName: '', visible: false });
    const [methodAndTerminalList, setMethodAndTerminalList] = useState({ terminals: [], methods: [] });
    const [toastMessage, setToastMessage] = useState({ ...initialToastState });

    useEffect(() => {
        if (ordersFilter !== '') {
            filterOrderByStatus(ordersFilter);
            dispatch(changeOrderFilter(''));
        }
    }, [ordersFilter, pageDetails, completedOrders]);

    useEffect(() => {
        checkAuthentication();
    }, [userRole]);

    /**
     * checks user is authentication and fetch order type, terminal and complete order list
     */
    const checkAuthentication = async () => {
        if (isAuthenticated()) {
            let terminalList = [];
            let orderTypeMethodList = [];
            await dispatch(fetchAllTerminals()).then((response) => {
                terminalList = response.payload;
            })
            await dispatch(getAllDeliveryMethods()).then((response) => {
                orderTypeMethodList = response.payload;
            })
            setMethodAndTerminalList({ ...methodAndTerminalList, terminals: terminalList, methods: orderTypeMethodList });
            if (terminalList.length > 0 && orderTypeMethodList.length > 0) {
                const terminalIds = terminalList.map((terminal) => String(terminal.id)); // Get all terminal IDs
                const orderTypeMethodId = orderTypeMethodList.map((orderType) => String(orderType.methodCode)); // Get all orderTypeMethod IDs
                await getCompleteOrderList({ ...initialFilterState, startDate: standardDateFormat(new Date(), dateFormat), endDate: standardDateFormat(new Date(), dateFormat), terminalIds: terminalIds, deliveryMethods: orderTypeMethodId });
            }
        } else {
            navigate('/user/login');
        }
    };

    /**
     * fetch completed order list by filters
     * @param {object} filter 
     */
    const getCompleteOrderList = async (filter) => {
        setLoading(true);
        await dispatch(getCompleteOrders(filter))
            .then((response) => {
                if (response.payload) {
                    let currentPage = pageDetails.currentPage
                    if (JSON.stringify(filter) !== JSON.stringify(currentFilter)) {
                        currentPage = 1;
                        setPageDetails({ ...pageDetails, currentPage: 1 })
                    }
                    setCompletedOrders(userRole === 'BUSINESS_ADMIN' ? deepCopy(response.payload) : deepCopy(response.payload).splice(0, 10));
                    setCurrentFilter({ ...filter });
                    handlePaginator(response.payload, pageDetails.pageSize, currentPage);
                }
            })
    };

    /**
     * handle days filters
     * @param {string} value 
     */
    const handleDaysFilter = (value) => {
        document.querySelector('input[name=startDate]').value = "";
        document.querySelector('input[name=endDate]').value = "";
        if (value === "TODAY") {
            getCompleteOrderList({ ...currentFilter, startDate: standardDateFormat(new Date(), dateFormat), endDate: standardDateFormat(new Date(), dateFormat) })
        } else {
            getCompleteOrderList({ ...currentFilter, startDate: standardDateFormat(yesterday, dateFormat), endDate: standardDateFormat(yesterday, dateFormat) })
        }
    };

    /**
     * create chunks functions
     * @param {Array} orders 
     * @param {object} pageSize 
     * @param {object} currentPage 
     */
    const handlePaginator = async (orders, pageSize, currentPage) => {
        let chunks = [];
        if (orders) {
            for (let i = 0; i < orders.length; i++) {
                if (userRole === 'BUSINESS_MANAGER' && i === 10) {
                    break;
                } else {
                    if (!chunks.length || chunks[chunks.length - 1].length == pageSize)
                        chunks.push([]);
                    chunks[chunks.length - 1].push(orders[i]);
                }
            }
            setLoading(false);
            setOrderToDisplay(chunks[currentPage - 1] === undefined ? [] : chunks[currentPage - 1]);
        }
    }

    /**
     * handle date filter
     * @param {event} event 
     */
    const handleDateFilter = (event) => {
        setTimeout(() => {
            getCompleteOrderList({ ...currentFilter, [event.target.name]: event.target.value });
        }, 500)
    };

    /**
     * Handle terminal and order method filter and search accordingly 
     * @param {array} selectedOptions - Selected options from the dropdown
     * @param {string} filterType - Type of filter ('terminalIds' or 'deliveryMethods')
     */
    const handleTerminalAndOrderTypeFilter = (selectedOptions, filterType) => {
        setTimeout(() => {
            let selectedValue = [];

            if (selectedOptions.length === 0) {
                // If no options are selected, include all corresponding IDs
                if (filterType === 'terminalIds') {
                    selectedValue = methodAndTerminalList.terminals.map(terminal => String(terminal.id));
                } else if (filterType === 'deliveryMethods') {
                    selectedValue = methodAndTerminalList.methods.map(method => String(method.methodCode));
                }
            } else {
                // Otherwise, include only the selected IDs
                selectedValue = selectedOptions.map(option => String(option.value));
            }
            // Update the filter with the appropriate values
            getCompleteOrderList({ ...currentFilter, [filterType]: selectedValue });
        }, 500);
    };

    /**
     * pagination handler for completed records
     * @param {string} pageType 
     */
    const handlePagination = (pageType) => {
        if (pageType === "NEXT") {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage + 1 });
            handlePaginator(completedOrders, pageDetails.pageSize, pageDetails.currentPage + 1);
        } else {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage - 1 });
            handlePaginator(completedOrders, pageDetails.pageSize, pageDetails.currentPage - 1);
        }
    }

    /** 
     * page size change
     *  @param {event} pageType 
    */
    const handlePageSize = (event) => {
        setPageDetails({ ...pageDetails, pageSize: parseInt(event.target.value) });
        handlePaginator(completedOrders, parseInt(event.target.value), 1);
    };

    /**
     * on row change function
     * @param {object} order 
     */
    const onRowSelect = (order) => {
        selectedOrder = order;
        if (order.deliveryMethod === 'DINE') {
            handleModalManager('tokenOrderDetailModal', true);
        } else {
            handleModalManager('singleOrderDetailModal', true);
        }
    };

    /**
     * common modal manager function
     * @param {string} modalName 
     * @param {boolean} visible 
     */
    const handleModalManager = (modalName, visible) => {
        setModalManager({ ...modalManager, modalName, visible })
    };

    const exportToExcel = () => {
        const excelData = [];
        completedOrders.forEach(orderItem => {
            excelData.push({ "Invoice No": orderItem.invoiceNo, "Order Date": standardDateFormat(orderItem.orderDate, 'YYYY-MM-DD HH:mm:ss'), "Order Total": orderItem.orderTotal, "Payment Method": orderItem.paymentMethod, "Amount Paid": orderItem.amountPaid, "Discount": orderItem.discount, "Total GST": parseFloat(orderItem.totalGst).toFixed(2) })
        })
        if (excelData.length === 0) {
            excelData.push({ "Invoice No": "", "Order Date": "", "Order Total": "", "Payment Method": "", "Amount Paid": "", "Discount": "", "Total GST": "" });
        }
        const fileExtension = ".xlsx";
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `SaleReport_${currentFilter.startDate}_${currentFilter.endDate}` + fileExtension);
    };

    /**
     * common function for close dilaog and get completedOrder by API
     * @param {boolean} isDataChangeFlag 
     */
    const onHideModals = (isDataChangeFlag) => {
        if (isDataChangeFlag) {
            getCompleteOrderList({ ...currentFilter });
        }
        handleModalManager('', false);
    };

    /**
     * filter by total sale and cancel order
     * @param {string} filterStatus 
     */
    const filterOrderByStatus = (filterStatus) => {
        const emptyArr = [];
        setLoading(true);
        completedOrders.forEach(orders => {
            if (orders.status === filterStatus) {
                emptyArr.push(orders);
            }
        })
        setTimeout(() => {
            handlePaginator(emptyArr, pageDetails.pageSize, pageDetails.currentPage);
            setLoading(false);
        }, 1000);
    }

    /** modals props */
    const singleOrderDetailModalProps = { showModal: (modalManager.modalName === 'singleOrderDetailModal' && modalManager.visible === true), hideModal: onHideModals, selectedOrder: selectedOrder, handleToastManager: setToastMessage };
    const tokenOrderDetailModalProps = { showModal: (modalManager.modalName === 'tokenOrderDetailModal' && modalManager.visible === true), hideModal: onHideModals, selectedOrder: selectedOrder, handleToastManager: setToastMessage };

    const formatedSelectOptions = (list = []) => {
        const options = [];
        list.forEach((prod) => {
            if (prod) {
                options.push({ label: prod.name, value: prod.methodCode ? prod.methodCode : prod.id });
            }
        });
        return options;
    };

    const selectStyles = {
        menuList: (styles) => ({
            ...styles,
            background: "#fff",
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? "#CAB05D"
                : isSelected
                    ? "hsla(291, 64%, 42%, 1)"
                    : undefined,
            zIndex: 1,
        }),
        control: (styles, { isFocused, isSelected }) => ({
            ...styles,
            minHeight: isFocused ? 58 : isSelected ? 58 : 58,
            zIndex: 1,
            borderRadius: 10,
        }),

        menu: (base) => ({
            ...base,
            zIndex: 100,
        }),
        valueContainer: (base) => ({
            ...base,
            zIndex: 100,
            maxHeight: 58,
            overflow: 'auto',
        }),
    };
    return (
        <>
            <ToastManager toastManager={toastMessage} setToastManager={setToastMessage} />
            <Container fluid className={userRole === 'BUSINESS_ADMIN' ? "p-0" : 'px-3'}>
                <Row className={userRole === 'BUSINESS_ADMIN' ? "g-0" : 'mt-4 g-0'}>
                    <Col md={12}>
                        <Row className="g-3">
                            {userRole === 'BUSINESS_ADMIN' ?
                                <>
                                    <Col md={12}>
                                        <Row className="g-3">
                                            <Col xs={6} sm={4} md={4} lg={2} className='ms-auto'>
                                                <Button className='light-btn w-100 text-truncate h-56 bg-light-grey d-flex align-items-center justify-content-center' variant="primary" type="button" onClick={exportToExcel}>
                                                    <PiExportBold className='fs-16 me-1' />Export
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row className="g-3">
                                            <Col xs={6} sm={4} md={4} lg={2}>
                                                <Button className={`light-btn w-100 text-truncate h-56 ${currentFilter.startDate === standardDateFormat(new Date(), dateFormat) && currentFilter.endDate === standardDateFormat(new Date(), dateFormat) ? "bg-light-yellow" : "text-secondary"}`} variant="primary" type="button" onClick={() => handleDaysFilter('TODAY')}>Today's</Button>
                                            </Col>
                                            <Col xs={6} sm={4} md={4} lg={2}>
                                                <Button className={`light-btn w-100 text-truncate h-56 ${currentFilter.startDate === standardDateFormat(yesterday, dateFormat) && currentFilter.endDate === standardDateFormat(yesterday, dateFormat) ? "bg-light-yellow" : "text-secondary"}`} variant="primary" type="button" onClick={() => handleDaysFilter('YESTERDAY')}>Yesterday's</Button>
                                            </Col>
                                            <Col xs={6} sm={4} md={4} lg={2}>
                                                <Form.Floating >
                                                    <Form.Control id="floatingInputCustom" type="date" autoComplete='off' name="startDate" placeholder="Start Date" max={standardDateFormat(currentFilter.endDate, dateFormat)} onChange={handleDateFilter} />
                                                    <span className='date-icon'><i className="far fa-calendar-alt"></i></span>
                                                    <Form.Label htmlFor="floatingInputCustom">Start Date</Form.Label>
                                                </Form.Floating>
                                            </Col>
                                            <Col xs={6} sm={4} md={4} lg={2}>
                                                <Form.Floating >
                                                    <Form.Control id="floatingInputCustom" type="date" autoComplete='off' name="endDate" placeholder="End Date" min={standardDateFormat(currentFilter.startDate, dateFormat)} onChange={handleDateFilter} />
                                                    <span className='date-icon' ><i className="far fa-calendar-alt"></i></span>
                                                    <Form.Label htmlFor="floatingInputCustom">End Date</Form.Label>
                                                </Form.Floating>
                                            </Col>
                                            <Col xs={6} sm={4} md={4} lg={2}>
                                                <Form.Floating>
                                                    <Select
                                                        onChange={(selectedOptions) => handleTerminalAndOrderTypeFilter(selectedOptions, 'deliveryMethods')}
                                                        isMulti
                                                        name="deliveryMethod"
                                                        options={formatedSelectOptions(methodAndTerminalList.methods)}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        styles={selectStyles}
                                                        placeholder="Select Delivery Methods"
                                                    />
                                                </Form.Floating>
                                            </Col>
                                            <Col xs={6} sm={4} md={4} lg={2}>
                                                <Form.Floating>
                                                    <Select
                                                        onChange={(selectedOptions) => handleTerminalAndOrderTypeFilter(selectedOptions, 'terminalIds')}
                                                        isMulti
                                                        name="terminal"
                                                        options={formatedSelectOptions(methodAndTerminalList.terminals)}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        styles={selectStyles}
                                                        placeholder="Select Terminals"
                                                    />
                                                </Form.Floating>
                                            </Col>
                                        </Row>

                                    </Col>
                                </> : null}
                            <Col md={12}>
                                <OrderTable orderList={orderToDisplay} loading={loading} getSelectedRow={onRowSelect} />
                                <Row className='g-0'>
                                    <Col md={12} className='table-pagination flex-wrap d-flex align-items-center justify-content-end'>
                                        <div className='d-flex align-items-center mb-3'>
                                            <span className='mx-3'> Items per page:</span>
                                            <select className='form-select' aria-label="Floating label select example" name="productType" onChange={(e) => { handlePageSize(e) }}>
                                                {pageSizeSelectOptions.map((page, index) => {
                                                    return <option key={index} value={page.value}>{page.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center mb-3'>
                                            <span className='mx-3'> {pageDetails.currentPage + " - " + Math.ceil(completedOrders?.length / pageDetails.pageSize) + " of " + completedOrders?.length}</span>
                                            <Button className='light-btn px-3' variant="primary" disabled={pageDetails.currentPage === 1 ? true : false} onClick={() => handlePagination('PREV')}><IoChevronBackOutline className='fs-16' /></Button>
                                            <Button className='light-btn px-3 ms-3' variant="primary" disabled={(completedOrders?.length < pageDetails.pageSize || pageDetails.currentPage === Math.ceil(completedOrders?.length / pageDetails.pageSize)) ? true : false} onClick={() => handlePagination('NEXT')}><IoChevronForwardOutline className='fs-16' /></Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <SingleOrderDetailModal {...singleOrderDetailModalProps} />
            <TokenOrderDetailModal {...tokenOrderDetailModalProps} />
        </>
    )
};

export default CompleteOrder;