import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import NumberField from "../../../../../../components/common/App/NumberField";
import { BiPencil } from "react-icons/bi";
import proImg from '../../../../../../assets/images/upload.jpg';
import { getSelCategoryByStyle, validateFields } from "../../../../../../util/utility-helper";
import { changeProductImage, createProduct } from "../../../../../../actions/billdesk/BillDeskActions";
import { uomData } from "../../../../../../util/constants";

const initalState = {
    itemNumber: "", prodAlias: "", description: "", hsn: "", uom: "", tags: "", perGst: "",
    isPriceGstInc: "Y", price: 0, icon: "", image1: "N", image2: "N", productType: "OFFLINE",
    image3: "N", image4: "N", image5: "N", status: "ACTIVE", syncStatus: "N", categories: {}, displayOrder: ""
};
const intialButtonState = { loading: false, disable: true }
const mandatoryFields = ['prodAlias', 'price', 'perGst', 'isPriceGstInc', 'uom'];

const AddProductModal = ({ ...props }) => {
    const dispatch = useDispatch()

    const [product, setProduct] = useState({ ...initalState });
    const [buttonState, setButtonState] = useState({ ...intialButtonState });

    useEffect(() => {
        if (props.showModal) {
            setProduct({ ...initalState });
            setButtonState({ ...intialButtonState });
        }
    }, [props.showModal]);

    const openFileUploader = () => {
        document.getElementById('fileUploader').click();
    }

    const onFileChange = (event) => {
        const file = event.target.files[0];
        setProduct({ ...product, icon: file, prodImg: URL.createObjectURL(file) });
    };

    const handleChange = (event) => {
        setProduct({ ...product, [event.target.name]: event.target.value });
        setButtonState({ ...buttonState, disable: validateFields(mandatoryFields, { ...product, [event.target.name]: event.target.value }) })
    };

    const handleCheckboxChange = (event) => {
        setProduct({ ...product, [event.target.name]: event.target.checked ? "Y" : "N" });
        setButtonState({ ...buttonState, disable: validateFields(mandatoryFields, { ...product, [event.target.name]: event.target.checked ? "Y" : "N" }) })
    };

    const addNewProduct = async () => {
        setButtonState({ ...buttonState, loading: true });
        let newProductState = { ...product };
        if (Object.keys(newProductState.categories).length === 0) {
            newProductState.categories = { id: getSelCategoryByStyle(props.subCategoryList, 'bg-light-yellow') ? getSelCategoryByStyle(props.subCategoryList, 'bg-light-yellow').id : "" };
        }
        if (newProductState.icon !== '') {
            newProductState.icon = await uploadProductImg(newProductState);
        }
        try {
            await dispatch(createProduct({ ...newProductState }))
                .then((response) => {
                    if ((response.payload && !response.payload.error)) {
                        props.hideModal(true);
                        setProduct({ ...initalState });
                        props.handleToastManager({ visible: true, toastMessage: "Product Created Successfully", variant: "Success" });
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: response.payload.error, variant: "Danger" });
                    }
                    setButtonState({ ...buttonState, loading: false });
                })
        } catch (err) { }
    };

    const handleCategorySelect = (event) => {
        setProduct({ ...product, categories: { id: event.target.value } })
    };

    const uploadProductImg = async (product) => {
        let resourcePath = null;
        const formData = new FormData()
        formData.append("files", product.icon);
        formData.append("fileName", product.id);
        try {
            await dispatch(changeProductImage(formData)).then(async (response) => {
                if ((response.payload && !response.payload.error)) {
                    resourcePath = response.payload.resourcePath;
                } else {
                    props.handleToastManager({ visible: true, toastMessage: "Upload Error", variant: "Danger" });
                }
            });
        } catch (err) { }
        return resourcePath;
    };


    return (
        <Modal size="lg" backdrop="static" keyboard={false} centered show={props.showModal} onHide={props.hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Add Product</Modal.Title>
            </Modal.Header>
            <>
                <Modal.Body>
                    <Row className="g-3">
                        <Col md={12} lg={3}>
                            <div className="pro-main mx-auto mx-lg-2" >
                                <div className="pro-menu" onClick={openFileUploader}>
                                    <div className="pro-overlay">
                                        <BiPencil className='pro-icon' />
                                    </div>
                                    <img className="pro-image" src={product.prodImg || proImg} />
                                    <input type="file" name="files" accept=".jpg,.jpeg,.png" id="fileUploader" hidden={true} onChange={onFileChange} />
                                </div>
                            </div>

                        </Col>
                        <Col md={12} lg={9}>
                            <Row className="g-3">
                                <Col md={6}>
                                    <Form.Floating className="">
                                        <Form.Control type="text" autoComplete='off' name="prodAlias" id="floatingInputCustom" placeholder="Product Name" value={product.prodAlias} onChange={handleChange} />
                                        <Form.Label htmlFor="floatingInputCustom">Product Name<span className="text-danger">*</span></Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={6}>
                                    <Form.Floating className="">
                                        <NumberField value={product.price} decimals={2} defaultValue={product.price} onChange={handleChange} name="price" placeholder="Price" />
                                        <Form.Label htmlFor="floatingInputCustom">Product Price<span className="text-danger">*</span></Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={6} className="d-flex align-items-end">
                                    <Form.Floating className="w-100">
                                        <Form.Control type="text" autoComplete='off' name="hsn" id="floatingInputCustom" placeholder="HSN Code" value={product.hsn} onChange={handleChange} />
                                        <Form.Label htmlFor="floatingInputCustom">HSN Code</Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={6}>
                                    <Form.Check className="" type="switch" checked={product.isPriceGstInc === "Y"} name="isPriceGstInc" id="custom-switch" label="Is GST Included?" onChange={handleCheckboxChange} />
                                    <Form.Floating className="">
                                        <NumberField autoComplete='off' placeholder="GST %" name="perGst" value={product.perGst} onChange={handleChange} />
                                        <Form.Label htmlFor="floatingInputCustom">GST %<span className="text-danger">*</span></Form.Label>
                                    </Form.Floating>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} className="d-flex align-items-end">
                            <Form.Floating className="">
                                <Form.Select className='text-capitalize' aria-label="Category Select" name="uom" value={product.uom} onChange={handleChange}>
                                    <option selected disabled>UOM</option>
                                    {uomData.map((uom, indx) => (
                                        <option selected={uom.value === product.uom} key={indx} value={uom.value} >
                                            {uom.name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Label htmlFor="floatingInputCustom">UOM<span className="text-danger">*</span></Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={6}>
                            <Form.Floating className="">
                                <Form.Control type="text" autoComplete='off' name="itemNumber" id="floatingInputCustom" placeholder="Item Number" value={product.itemNumber} onChange={handleChange} />
                                <Form.Label htmlFor="floatingInputCustom">Item Number</Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={6}>
                            <Form.Floating className="">
                                <Form.Control type="text" autoComplete='off' name="description" id="floatingInputCustom" placeholder="Description" value={product.description} onChange={handleChange} />
                                <Form.Label htmlFor="floatingInputCustom">Description</Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={6}>
                            <Form.Floating className="">
                                <Form.Select className='text-capitalize' aria-label="Floating label select example" name="categories" onChange={handleCategorySelect}>
                                    <option disabled>Select Sub-Category</option>
                                    {props.subCategoryList.map((cats) => (
                                        <option value={cats.id} selected={cats.id === getSelCategoryByStyle(props.subCategoryList, 'bg-light-yellow')?.id ? true : false} >{cats.catAlias}</option>
                                    ))}
                                </Form.Select>
                                <Form.Label htmlFor="floatingInputCustom">Sub-Category<span className="text-danger">*</span></Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={6}>
                            <Form.Floating className="">
                                <NumberField autoComplete='off' name="displayOrder" id="floatingInputCustom" placeholder="UOM" value={product.displayOrder} defaultValue={product.displayOrder} onChange={handleChange} />
                                <Form.Label htmlFor="floatingInputCustom">Display Order</Form.Label>
                            </Form.Floating>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0'>
                    <Row className='g-3 w-100 justify-content-end'>
                        <Col md={3} lg={2} >
                            {!buttonState.loading ?
                                <Button className='light-btn w-100 bg-light-grey' variant="primary" name="Save" onClick={addNewProduct} disabled={buttonState.disable}>Save</Button>
                                : <Button className='light-btn w-100 bg-light-grey' variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        size="sm"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>}
                        </Col>
                    </Row>
                </Modal.Footer>
            </>
        </Modal >
    )
}

export default AddProductModal;