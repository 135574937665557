import { createSlice } from '@reduxjs/toolkit';
import { createNewTerminal, fetchAllTerminals, fetchUserData, getCustomerVerificatonList } from '../actions/admin/AdminActions';
import { searchStoreList } from '../actions/inventory/InventoryActions';

const initialState = {
    userList: [],
    terminalList: [],
    custVerificationList: [],
    storeList: [],
}

export const adminSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        changeEditView: (state, action) => {
            state.editView = action.payload;
        },
        changeNewOrder: (state, action) => {
            state.newOrder = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder

            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.loading = false;
                state.userList = action.payload;
            })
            .addCase(fetchAllTerminals.fulfilled, (state, action) => {
                state.loading = false;
                state.terminalList = action.payload;
            })
            .addCase(createNewTerminal.fulfilled, (state, action) => {
                state.loading = false;
                state.terminalList = [...state.terminalList, action.payload];
            })
            .addCase(getCustomerVerificatonList.pending, (state) => {
                state.custVerificationList = [];
            })
            .addCase(getCustomerVerificatonList.fulfilled, (state, action) => {
                state.custVerificationList = action.payload;
            })
            .addCase(getCustomerVerificatonList.rejected, (state) => {
                state.custVerificationList = []
            })
            .addCase(searchStoreList.pending, (state) => {
                state.storeList = [];
            })
            .addCase(searchStoreList.fulfilled, (state, action) => {
                state.storeList = action.payload;
            })
            .addCase(searchStoreList.rejected, (state) => {
                state.storeList = []
            })
    }

});

export const { setLoading, changeEditView, changeNewOrder } = adminSlice.actions;


export default adminSlice.reducer;