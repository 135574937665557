import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  deleteMedia,
  getStoreMediaByStoreId,
  uploadResource,
} from "../../../actions/admin/AdminActions";
import { searchStoreList } from "../../../actions/inventory/InventoryActions";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BiPencil } from "react-icons/bi";
import ToastManager from "../../../components/common/App/NotificationManager";
import uploadHoimg from "../../../assets/images/upload-placeholder.jpg";
import ConfirmDialog from "../../../components/common/App/ConfirmDialog";
let currentStore = null;
const baseUrlPoint = process.env.REACT_APP_API_BASE_ENDPOINT;
const initialToastState = { visible: false, toastMessage: "", variant: "" };

const Resourceploader = () => {
  const [promotions, setPromotions] = useState([]);
  const [cruisers, setCruisers] = useState([]);
  const [handleToastManager, setHandleToastManager] = useState({
    ...initialToastState,
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const selectedFileRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    async function callBack() {
      const selectedStore = await getStores();
      currentStore = selectedStore;
      if (selectedStore) {
        getStoreMedia(selectedStore.id);
      }
    }
    callBack();
  }, [""]);

  const getStores = async () => {
    let apiResponse = null;
    await dispatch(searchStoreList({ searchFilter: "" })).then((response) => {
      if (response.payload) {
        apiResponse = response.payload && response.payload.find((e) => e.storeType === "ONLINE");
      }
    });
    return apiResponse;
  };
  const getStoreMedia = async (storeId) => {
    await dispatch(getStoreMediaByStoreId(storeId)).then((response) => {
      handlePromotionsList(response?.payload && response?.payload?.promotions || []);
      handleCruisers(response?.payload && response?.payload?.cruisers || [])
    });
  };

  const handlePromotionsList = (promList) => {
    const emptyList = [];
    for (let i = 0; i < 5; i++) {
      if (promList.length > i) {
        emptyList.push({ fileSrc: baseUrlPoint + promList[i], src: promList[i] });
      } else {
        emptyList.push({ fileSrc: null });
      }
    }
    setPromotions(emptyList);
  };

  const handleCruisers = (crusList) => {
    const emptyList = [];
    for (let i = 0; i < 5; i++) {
      if (crusList.length > i) {
        emptyList.push({ fileSrc: baseUrlPoint + crusList[i], src: crusList[i] });
      } else {
        emptyList.push({ fileSrc: null });
      }
    }
    setCruisers(emptyList);
  }

  const confirmDelete = (flag) => {
    if (flag === 'YES') {
      if (selectedFileRef.current !== null) {
        if (!selectedFileRef.current.isLocal) {
          const splitedPath = selectedFileRef.current?.src?.split("/");
          if (splitedPath && splitedPath[4] && currentStore) {
            dispatch(
              deleteMedia({
                mediaType:
                  splitedPath[2] === "promotions"
                    ? "mediaPromotions"
                    : "mediaCruisers",
                storeId: currentStore?.id,
                endPath: splitedPath[4],
              })
            ).then((response) => {
              if (response.payload && !response.payload.error) {
                getStoreMedia(currentStore.id);
                setHandleToastManager({
                  visible: true,
                  toastMessage: "Media deleted successfully.",
                  variant: "Success",
                });
                setShowConfirmModal(false);
              } else {
                setHandleToastManager({
                  visible: true,
                  toastMessage: response.payload.error,
                  variant: "Danger",
                });
              }
            });
          }
        }
      }
    } else {
      setShowConfirmModal(false);
    }
  };

  const openFileUploader = (fileType) => {
    document.getElementById(fileType).click();
  };

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    if (selectedFileRef.current !== null) {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("fileName", selectedFileRef?.current.mediaType + '_' + selectedFileRef?.current?.index);
      await dispatch(uploadResource({ mediaType: selectedFileRef?.current.mediaType, formData: formData })).then((response) => {
        if (response.payload && !response.payload.error) {
          getStoreMedia(currentStore.id);
          selectedFileRef.current = null;
          setHandleToastManager({ ...handleToastManager, visible: true, toastMessage: "Media Uploaded.", variant: "Success" });
        } else {
          setHandleToastManager({ ...handleToastManager, visible: true, toastMessage: response.payload.error, variant: "Danger" });
        }
      })
    }
  };

  const handleDeleteModal = (media) => {
    selectedFileRef.current = media;
    setShowConfirmModal(true);
  }

  return (
    <>
      <ToastManager
        toastManager={handleToastManager}
        setToastManager={setHandleToastManager}
      />
      <Row className="g-0">
        <Col sm={12} md={12} lg={12}>
          <Row className="g-3">
            <Col md={12}>
              <Card className=" rounded-0 border mb-4 shadow-sm">
                <Card.Body className="p-3 ">
                  <Row className="g-0 mx-0">
                    <Col md={12} className="mb-3">
                      <h4 className="fs-5 fw-bold modal-title pb-3 ">
                        Promotion Banners
                      </h4>
                    </Col>

                    <Col md={12} className="mb-3">
                      <Row className="g-3">
                        {promotions.map((prom, index) => {
                          return (<div key={index} className="pro-main mx-auto mx-lg-2">
                            <div className="pro-menu-h" onClick={() => { openFileUploader('promFile'); selectedFileRef.current = { ...selectedFileRef.current, index: index, mediaType: 'mediaPromotions' } }}>
                              <div className="pro-overlay">
                                <BiPencil className="pro-icon" />
                              </div>
                              <img className="pro-image" src={prom.fileSrc || uploadHoimg} />
                              <input
                                type="file"
                                accept=".jpg,.jpeg,.png"
                                name="files"
                                id="promFile"
                                hidden={true}
                                onChange={onFileChange}
                              />
                            </div>
                            {prom.fileSrc ? (
                              <div className="pro-trash" onClick={() => handleDeleteModal(prom)}>
                                <IoIosCloseCircleOutline />
                              </div>
                            ) : null}

                          </div>
                          )
                        })}
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card className="rounded-0 border shadow-sm">
                <Card.Body className="p-3 ">
                  <Row className="g-0 mx-0">
                    <Col md={12} className="mb-3">
                      <h4 className="fs-5 fw-bold modal-title pb-3 ">
                        Product Banners
                      </h4>
                    </Col>
                    <Col md={12} className="mb-3">
                      <Row className="g-3">
                        {cruisers.map((crus, index) => {
                          return (
                            <div
                              key={index}
                              className="pro-main mx-auto mx-lg-2"
                            >
                              <div className="pro-menu-h" onClick={() => { openFileUploader('crusFile'); selectedFileRef.current = { ...selectedFileRef.current, index: index, mediaType: 'mediaCruisers' } }}>
                                <div className="pro-overlay">
                                  <BiPencil className="pro-icon" />
                                </div>
                                <img
                                  className="pro-image"
                                  src={crus.fileSrc ? crus.fileSrc : uploadHoimg}
                                />
                                <input
                                  type="file"
                                  accept=".jpg,.jpeg,.png"
                                  name="files"
                                  id="crusFile"
                                  hidden={true}
                                  onChange={onFileChange}

                                />
                              </div>
                              {crus.fileSrc ? (
                                <div
                                  className="pro-trash"
                                  onClick={() => handleDeleteModal(crus)}
                                >
                                  <IoIosCloseCircleOutline />
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col >
      </Row >
      <ConfirmDialog handleClick={confirmDelete} showModal={showConfirmModal} hideModal={() => setShowConfirmModal(false)} message="Are you sure you want to delete media?" />
    </>
  );
};

export default Resourceploader;
