import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { TbEdit } from 'react-icons/tb';
import { FaMinus, FaPlus, FaTrash } from 'react-icons/fa6';

const ModifyDetailModal = ({ ...props }) => {

    const [inventoryDetails, setInventoryDetails] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);

    useEffect(() => {
        if (props.showModal) {
            setExpandedRow(null);
            let cloneInventoryDetails = [...props.inventoryList];
            setInventoryDetails(cloneInventoryDetails)
            // Promise.all(
            //     cloneInventoryDetails.map(invDetails => {
            //         invDetails.newQty = Number(invDetails.newQty) + Number(invDetails.oldQty)
            //         return invDetails;
            //     })).then(() => {
            //         setInventoryDetails([...cloneInventoryDetails]);
            //     })
        }
    }, [props.showModal, props.inventoryList]);

    const handleRowClick = (idx) => {
        setExpandedRow(expandedRow === idx ? null : idx);
    };

    const handleQtyChange = (adjType, index, fieldName) => {
        let invDetailsLcl = [...inventoryDetails];
        if (adjType === 'PLUS') {
            invDetailsLcl[index || 0][fieldName] = (invDetailsLcl[index || 0][fieldName] + 1)
        } else {
            if (invDetailsLcl[index][fieldName] > 1) {
                invDetailsLcl[index][fieldName] = (invDetailsLcl[index || 0][fieldName] - 1)
            } else {
                setExpandedRow(null);
                invDetailsLcl.splice(index, 1);
            }
        }
        setInventoryDetails([...invDetailsLcl]);
    };

    const saveInventoryDetails = () => {
        props.saveInventoryDetails(inventoryDetails);
    };

    return (
        <>

            <Modal backdrop="static" keyboard={false} className='modal-height' show={props.showModal} onHide={props.hideModal} centered size='lg' scrollable>
                <Modal.Header className='border-0 pb-0' closeButton>
                    <Modal.Title className='fs-5 fw-bold'>Modify Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='g-3'>
                        <Col xs={12}>
                            <Table responsive className='inso-table mb-0'>
                                <thead>
                                    <tr>
                                        <th className='text-start' width="40%">Item Name</th>
                                        {/* <th className='text-center' width="30%">Vendor</th> */}
                                        <th className='text-center' width="20%">Current Qty</th>
                                        <th className='text-center' width="20%">Updated Qty</th>
                                        <th className='text-center' width="20%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inventoryDetails.map((items, index) => {
                                        const isExpanded = expandedRow === index;
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td className='text-start'>{items.prodAlias}</td>
                                                    <td className='text-center'>{items.oldQty}</td>
                                                    <td className="text-center">
                                                        {Number(items.newQty)}
                                                    </td>
                                                    <td className='text-center'>
                                                        <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
                                                            <Button
                                                                className="border-0 bg-transparent p-0 ms-3"
                                                                onClick={() => handleRowClick(index)}
                                                            >
                                                                <TbEdit className="fs-16 text-dark " />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr >
                                                {isExpanded && (
                                                    // inventoryDetails.map((item, subIdx) => (
                                                    <tr key={`${index}`}>
                                                        <td className='text-start'>{items.prodAlias}</td>
                                                        <td className="text-center">
                                                            {items.oldQty}
                                                        </td>
                                                        <td className="text-center">
                                                            <span className="orderItem_qty">
                                                                {items.newQty > 1 ?
                                                                    <span class="action-plus" role="button" onClick={() => handleQtyChange('MINUS', index, 'newQty')}> <FaMinus /></span> :
                                                                    <span class="action-del" role="button" onClick={() => handleQtyChange('MINUS', index, 'newQty')}>
                                                                        <FaTrash />
                                                                    </span>}
                                                                <input className="qtyInput" type="text" value={items.newQty} />
                                                                <span class="action-plus" role="button" onClick={() => handleQtyChange('PLUS', index, 'newQty')}><FaPlus /></span>
                                                            </span>
                                                        </td>
                                                        <td className='text-start'></td>

                                                    </tr>
                                                    // ))
                                                )
                                                }
                                            </>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0'>
                    <Row className='g-3 w-100 justify-content-end'>
                        <Col md={3} lg={2}>
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="cancel" onClick={props.hideModal}>Cancel</Button>
                        </Col>
                        <Col md={3} lg={2}>
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="save" onClick={saveInventoryDetails} disabled={inventoryDetails.length === 0}>Save</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default ModifyDetailModal;