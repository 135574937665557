import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { LuSearch } from "react-icons/lu";
import { standardDateFormat } from "../../../../util/utility-helper";

const initialPageDetails = { currentPage: 1, pageSize: 10 };
const dateFormat  = process.env.REACT_APP_DATE_FORMAT;
const CustomerVerificatonsPane = ({ ...props }) => {

    const [verificationList, setVerificationList] = useState([]);
    const [pageDetails, setPageDetails] = useState({ ...initialPageDetails });
    const [verificationSearch, setVerificationSearch] = useState("");
    const [customerVerifications, setCustomerVerifications] = useState([]);

    useEffect(() => {
        setCustomerVerifications(props.verificationList)
        handlePaginator(props.verificationList, pageDetails.pageSize, pageDetails.currentPage);
    }, [props.verificationList])

    const handlePaginator = (terminalList, pageSize, currentPage) => {
        const chunksRecord = [];
        terminalList && terminalList.forEach((item) => {
            if (!chunksRecord.length || chunksRecord[chunksRecord.length - 1].length === pageSize)
                chunksRecord.push([]);

            chunksRecord[chunksRecord.length - 1].push(item);
        });
        setVerificationList(chunksRecord[currentPage - 1] === undefined ? [] : chunksRecord[currentPage - 1]);
    };

    const handlePagination = (pageType) => {
        if (pageType === "NEXT") {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage + 1 });
            handlePaginator(customerVerifications, pageDetails.pageSize, pageDetails.currentPage + 1);
        } else {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage - 1 });
            handlePaginator(customerVerifications, pageDetails.pageSize, pageDetails.currentPage - 1);
        }
    };

    const handlePageSize = (event) => {
        setPageDetails({ ...pageDetails, pageSize: parseInt(event.target.value) });
        handlePaginator(customerVerifications, parseInt(event.target.value), pageDetails.currentPage);
    };

    const handleSearch = (event) => {
        const verificationListClone = [];
        props.verificationList && props.verificationList.forEach(verifiction => {
            if (String(verifiction.mobileNumber).toLocaleLowerCase().includes(String(event.target.value).toLocaleLowerCase())) {
                verificationListClone.push(verifiction);
            }
        })
        setCustomerVerifications(verificationListClone)
        setVerificationSearch(event.target.value);
        handlePaginator(verificationListClone, pageDetails.pageSize, pageDetails.currentPage)
    };
    return (
        <Row className="g-3">
            <Col md={12}>
                <Row className="g-3">
                    <Col md={6}>
                        <Form.Floating className="search_bar">
                            <span class="input-group-text search_icon" ><LuSearch /></span>
                            <Form.Control className="search_input" id="productSearch" type="search" autoComplete='off' name="filterText" placeholder="Search By Terminal Name" value={verificationSearch} onChange={handleSearch} />
                            <Form.Label htmlFor="floatingInputCustom">Search By MobileNumber</Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Col>
            <Col md={12}>
                <Table responsive className="inso-table">
                    <thead>
                        <tr>
                            <th className="text-start" width="20%"> MobileNumber </th>
                            <th className="text-center" width="20%"> OTP </th>
                            <th className="text-center" width="20%"> ExpiryTime </th>
                            <th className="text-center" width="20%"> ipAddress </th>
                        </tr>
                    </thead>
                    <tbody>
                        {verificationList && verificationList.map((item) => {
                            return (
                                <tr>
                                    <td className="text-start">
                                        {item.mobileNumber || "-"}
                                    </td>

                                    <td className="text-center">
                                        {item.otp || "-"}
                                    </td>

                                    <td className="text-center">
                                        {standardDateFormat(item.expiryTime, dateFormat + "HH:mm:ss") || "-"}
                                    </td>

                                    <td className="text-center">
                                        {item.ipAddress || "-"}
                                    </td>

                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                <Row className='g-0'>
                    <Col md={12} className='table-pagination flex-wrap d-flex align-items-center justify-content-end'>
                        <div className='d-flex align-items-center mb-3'>
                            <span className='mx-3'> Items per page:</span>
                            <select className='form-select' aria-label="Floating label select example" onChange={handlePageSize}>
                                {props.pageSizeOptions.map((page, index) => (
                                    <option key={index} value={page.value}>{page.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className='d-flex align-items-center mb-3'>
                            <span className='mx-3'> {pageDetails.currentPage + " - " + Math.ceil(customerVerifications.length / pageDetails.pageSize) + " of " + customerVerifications.length}</span>
                            <Button className='light-btn px-3' variant="primary" disabled={pageDetails.currentPage === 1} onClick={() => handlePagination('PREV')}><IoChevronBackOutline className='fs-16' /></Button>
                            <Button className='light-btn px-3 ms-3' variant="primary" disabled={customerVerifications?.length < pageDetails.pageSize || pageDetails.currentPage === Math.ceil(customerVerifications.length / pageDetails.pageSize)} onClick={() => handlePagination('NEXT')}><IoChevronForwardOutline className='fs-16' /></Button>
                        </div>
                    </Col>
                </Row>

            </Col>
        </Row>
    )
}

export default CustomerVerificatonsPane;