import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { immediateInventoryUpdate } from '../../../../actions/inventory/InventoryActions';

const PreviewModal = ({ ...props }) => {
    const dispatch = useDispatch();
    const [loaderState, setLoaderState] = useState(false);
    const [inventoryPreviewDetails, setInventoryPreviewDetails] = useState([]);

    useEffect(() => {
        if (props.showModal) {
            let cloneInventoryPreviewDetails = [...props.inventoryList];
            setInventoryPreviewDetails([...cloneInventoryPreviewDetails]);
        }
    }, [props.showModal, props.inventoryList])

    const saveInventory = async () => {
        const apiData = [];
        inventoryPreviewDetails.forEach(invDetails => {
            apiData.push(
                {
                    "productId": invDetails.productId, "eventType": 'CHANGE', "comments": invDetails.comments, "action": "CHANGE",
                    "inventoryStocks": { "newQty": invDetails.newQty, "criticalLimit": invDetails?.inventoryStocks?.criticalLimit || 0, "warningLimit": invDetails?.inventoryStocks?.warningLimit || 0 }
                },
            )
        })
        setLoaderState(true);
        await dispatch(immediateInventoryUpdate({ "products": apiData, "storeId": props.storeId }))
            .then((response => {
                if ((response.payload && !response.payload.error)) {
                    props.handleToastManager({ visible: true, toastMessage: 'Inventory Updated.', variant: 'Success' });
                    props.hideModalOnSave();
                } else {
                    props.handleToastManager({ visible: true, toastMessage: response.payload.error, variant: 'Danger' });
                }
                setLoaderState(false)
            }))
    };

    const handleCommentChange = async (event) => {
        let invDetailsLcl = [...inventoryPreviewDetails];
        for (let i = 0; i < invDetailsLcl.length; i++) {
            invDetailsLcl[i] = { ...invDetailsLcl[i], [event.target.name]: event.target.value };
        }
        setInventoryPreviewDetails([...invDetailsLcl]);
    };

    const deleteItem = (item) => {
        const foundIndex = inventoryPreviewDetails.findIndex((e) => e.productId === item.productId);
        if (foundIndex > -1) {
            inventoryPreviewDetails.splice(foundIndex, 1);
            setInventoryPreviewDetails([...inventoryPreviewDetails]);
        }
    }
    return (
        <>

            <Modal backdrop="static" keyboard={false} className='modal-height' show={props.showModal} onHide={props.hideModal} centered size='lg' scrollable>
                <Modal.Header className='border-0 pb-0' closeButton>
                    <Modal.Title className='fs-5 fw-bold'>Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='g-3'>
                        <Col xs={12}>
                            <Table responsive className='inso-table mb-0'>
                                <thead>
                                    <tr>
                                        <th className='text-start' width="60%">Item Name</th>
                                        {/* <th className='text-center' width="20%">Vendor</th> */}
                                        <th className='text-center' width="20%">Current Qty</th>
                                        <th className='text-center' width="20%">Updated Qty</th>
                                        <th className='text-center' width="20%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inventoryPreviewDetails?.map((item, index) => (
                                        <tr key={index}>
                                            <td className='text-start'>{item.prodAlias}</td>
                                            {/* <td className='text-center'>{item.vendor}</td> */}
                                            <td className='text-center'>{item.oldQty}</td>
                                            <td className='text-center'>{item.newQty}</td>
                                            <td className='text-center'>
                                                <OverlayTrigger placement='top' overlay={<Tooltip>Delete Item</Tooltip>}>
                                                    <Button className="border-0 bg-transparent p-0 ms-3" onClick={() => deleteItem(item)}>
                                                        <AiOutlineDelete className="fs-16 text-dark " />
                                                    </Button>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={12}>
                            <Form.Floating className="">
                                <Form.Control as="textarea" rows={5} type="text" autoComplete='off' name="comments" id="floatingInputCustom" placeholder="Product Price" value={inventoryPreviewDetails.location} defaultValue={inventoryPreviewDetails.location} onChange={handleCommentChange} />
                                <Form.Label htmlFor="floatingInputCustom">Comments</Form.Label>
                            </Form.Floating>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0'>
                    <Row className='g-3 w-100 justify-content-end'>
                        <Col md={3} lg={2}>
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={props.hideModal}>Cancel</Button>
                        </Col>
                        <Col md={3} lg={2}>
                            {!loaderState ?
                                <Button disabled={inventoryPreviewDetails.length === 0} className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={saveInventory}>Save</Button>
                                : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        size="sm"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>}
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default PreviewModal;