import React from "react";
import { Table } from 'react-bootstrap';
import NumberField from "../../../../components/common/App/NumberField";

const Qtylist = ({ items, changeQtyInput }) => {
    return (
        <Table responsive >
            <thead className=" ">
                <tr>
                    <th className="text-start">Product Name</th>
                    <th className="text-center">Added Qty</th>
                    <th className="text-center">(Old Qty) New Qty</th>
                    {/* <th className="text-center">New Qty</th> */}
                </tr>
            </thead>
            <tbody>
                {items.length !== 0 ?
                    items.map((qtyItem, key) => {
                        return (
                            <tr key={key}>
                                <td className="orderItem_name">
                                    <span className="d-block">
                                        {qtyItem.prodAlias}
                                        {/* <span className="d-block text-secondary">{qtyItem.prodAlias}</span> */}
                                    </span>
                                </td>
                                <td className="text-center">  <NumberField allowNegativeValue={true} decimals={2} placeholder="Qty" value={qtyItem.inputQty} onChange={(event) => changeQtyInput(qtyItem, event.target.value)} /></td>
                                <td className="text-center">({qtyItem.oldQty}) {Number(qtyItem.newQty)}</td>
                                {/* <td className="text-center">{Number(qtyItem.newQty) + Number(qtyItem.oldQty)}</td> */}
                            </tr>
                        )
                    })
                    :
                    null
                }
            </tbody>
        </Table>
    )
}

export default Qtylist;