import React from "react";
import TreeView, { flattenTree } from "react-accessible-treeview";
import { Col, Form, Row } from "react-bootstrap";
import { IoMdArrowDropright } from "react-icons/io";
import cx from "classnames";

const SettingMemberDetails = ({ assignedTerminals, onTerminalSelect, storeTerminalList }) => {
    // Flatten the tree and ensure unique IDs
    const dataWithGeneratedIds = flattenTree({
        name: "",
        children: storeTerminalList,
    });

    // Extract default selected IDs
    const defaultSelectedIds = assignedTerminals.map(item => item.id);

    // Helper to find parent IDs for selected nodes
    const getParentIds = (data = [], selectedIds = []) => {
        const parentIds = new Set();
        if (selectedIds.length > 0) {
            selectedIds.forEach(selectedId => {
                let currentNode = data.find(node => node.id === selectedId);
                while (currentNode?.parent) {
                    parentIds.add(currentNode.parent);
                    currentNode = data.find(node => node.id === currentNode.parent);
                }
            });
        } else {
            if (data && data.length > 0) {
                const withOutParentNodes = data.filter(e => e.id !== 0);

                if (withOutParentNodes.length > 0) {
                    parentIds.add(withOutParentNodes[0].id)
                }
            }
        }
        return [...parentIds];
    };

    // Calculate expanded IDs (parents of selected nodes)
    const defaultExpandedIds = getParentIds(dataWithGeneratedIds, defaultSelectedIds);

    const ArrowIcon = ({ isOpen, className }) => {
        const baseClass = "arrow";
        const classes = cx(
            baseClass,
            { [`${baseClass}--closed`]: !isOpen },
            { [`${baseClass}--open`]: isOpen },
            className
        );
        return <IoMdArrowDropright className={classes} />;
    };

    return (
        <Row className="g-3">
            <Col md={12}>
                <div className="terminal-tree">
                    <TreeView
                        data={dataWithGeneratedIds}
                        aria-label="Data type Map tree"
                        multiSelect
                        propagateSelect
                        propagateSelectUpwards
                        togglableSelect
                        defaultExpandedIds={defaultExpandedIds} // Expand parents of selected nodes
                        defaultSelectedIds={defaultSelectedIds} // Pre-select checkboxes
                        nodeRenderer={({
                            element,
                            isBranch,
                            isExpanded,
                            isSelected,
                            isHalfSelected,
                            getNodeProps,
                            level,
                            handleSelect,
                            handleExpand,
                        }) => (
                            <div
                                className="d-flex"
                                {...getNodeProps({ onClick: handleExpand })}
                                style={{ marginLeft: 45 * (level - 1) }}
                            >
                                {isBranch && <ArrowIcon isOpen={isExpanded} />}
                                <Form.Check
                                    type="checkbox"
                                    checked={isSelected}
                                    indeterminate={isHalfSelected}
                                    onClick={(event) => {
                                        handleSelect(event);
                                        event.stopPropagation();
                                        onTerminalSelect(element, event.target.checked);
                                    }}
                                    className="checkbox-icon d-inline me-2"
                                />
                                <span className="name">{element.name}</span>
                            </div>
                        )}
                    />
                </div>
            </Col>
        </Row>
    );
};

export default SettingMemberDetails;
