import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner, } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import loader from '../../../../assets/images/loading-img.gif';
import moment from 'moment';
import { createOrder } from '../../../../actions/billdesk/BillDeskActions';

const EditPurchaseOrder = ({ showModal, hideModal, handleToastManager }) => {
    const dispatch = useDispatch();

    const [previewDetails, setPreviewDetails] = useState(
        { order: '', orderDate: '', supplier: '', status: '', description: '', },
    );


    // loader for button
    const [loaderState, setLoaderState] = useState({ save: false, delete: false });

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoaderState({ ...loaderState, save: true });
            const resp = await dispatch(createOrder(previewDetails));
            const response = resp?.meta?.requestStatus;
            if (response === "fulfilled") {
                handleToastManager({ visible: true, toastMessage: 'Purchase Order update successfully', variant: "Success" })

                setTimeout(() => {
                    hideModal(); // Close the modal
                    setLoaderState({ ...loaderState, save: false });
                }, 2000);
            }
            else {
                handleToastManager({ visible: true, toastMessage: 'Failed to update purchase order. Please try again.', variant: "Danger" })
            }
        } catch (error) {
            handleToastManager({ visible: true, toastMessage: 'Failed to update purchase order. Please try again.', variant: "Danger" })
        } finally {
            setLoaderState({ ...loaderState, save: false });
        }
    };

    const onDelete = async (e) => {
        e.preventDefault();
        try {
            setLoaderState({ ...loaderState, delete: true });
            const resp = await dispatch(createOrder(previewDetails));
            const response = resp?.meta?.requestStatus;
            if (response === "fulfilled") {
                handleToastManager({ visible: true, toastMessage: 'Purchase Order deleted successfully', variant: "Success" })

                setTimeout(() => {
                    hideModal(); // Close the modal
                    setLoaderState({ ...loaderState, delete: false });
                }, 2000);
            }
            else {
                handleToastManager({ visible: true, toastMessage: 'Failed to delete purchase order. Please try again.', variant: "Danger" })
            }
        } catch (error) {
            handleToastManager({ visible: true, toastMessage: 'Failed to delete purchase order. Please try again.', variant: "Danger" })
        } finally {
            setLoaderState({ ...loaderState, delete: false });
        }
    };

    const onHandleChange = (e) => {
        let { name, value } = e.target;
        if (name === "orderDate") {
            value = moment(new Date(value)).format("YYYY-MM-DD HH:mm:ss");
        }
    };

    return (
        <>

            <Modal backdrop="static" keyboard={false} className='modal-height' show={showModal} onHide={hideModal} centered size='lg' scrollable>
                <Modal.Header className='border-0 pb-0' closeButton>
                    <Modal.Title className='fs-5 fw-bold'>Edit Purchase Order</Modal.Title>
                </Modal.Header>
                {previewDetails.length !== 0 ?
                    <Modal.Body>
                        <Row className='g-3'>
                            <Col md={6}>
                                <Form.Floating className="">
                                    <Form.Control type="text" autoComplete='off' name="location" id="floatingInputCustom" placeholder="Product Price" value={previewDetails.order} defaultValue={previewDetails.order} onChange={(e) => { onHandleChange(e) }} />
                                    <Form.Label htmlFor="floatingInputCustom">Purchase Order</Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col md={6}>
                                <Form.Floating className="">
                                    <Form.Control type="date" autoComplete='off' name="orderDate" id="floatingInputCustom" placeholder="Product Name" value={moment(previewDetails.orderDate).format('YYYY-MM-DD')} defaultValue={moment(previewDetails.orderDate).format('YYYY-MM-DD')} onChange={(e) => { onHandleChange(e) }} />
                                    <Form.Label htmlFor="floatingInputCustom">Date</Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col md={6}>
                                <Form.Floating className="">
                                    <Form.Control type="text" autoComplete='off' name="location" id="floatingInputCustom" placeholder="Product Price" value={previewDetails.supplier} defaultValue={previewDetails.supplier} onChange={(e) => { onHandleChange(e) }} />
                                    <Form.Label htmlFor="floatingInputCustom">Supplier</Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col md={6}>
                                <Form.Floating className="">
                                    <Form.Control type="text" autoComplete='off' name="location" id="floatingInputCustom" placeholder="Product Price" value={previewDetails.status} defaultValue={previewDetails.status} onChange={(e) => { onHandleChange(e) }} />
                                    <Form.Label htmlFor="floatingInputCustom">Status</Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col md={12}>
                                <Form.Floating className="">
                                    <Form.Control as="textarea" rows={5} type="text" autoComplete='off' name="location" id="floatingInputCustom" placeholder="Product Price" value={previewDetails.description} defaultValue={previewDetails.description} onChange={(e) => { onHandleChange(e) }} />
                                    <Form.Label htmlFor="floatingInputCustom">Description</Form.Label>
                                </Form.Floating>
                            </Col>
                        </Row>
                    </Modal.Body>

                    : <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }} >
                        <img width='20%' src={loader} />
                    </div>}
                <Modal.Footer className='border-0 pt-0'>
                    <Row className='g-3 w-100 justify-content-end'>
                        <Col md={3} lg={2}>
                            {loaderState.save === false ?
                                <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={(e) => onSubmit(e)}>Save</Button>
                                : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        size="sm"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>}
                        </Col>
                        <Col md={3} lg={2}>
                            {loaderState.delete === false ?
                                <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={(e) => onDelete(e)}>Delete</Button>
                                : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        size="sm"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>}
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default EditPurchaseOrder;