import React from "react";
import moment from "moment";

const billDateFormat = process.env.REACT_APP_BILL_FORMAT;
const KitchenReport = ({ orderDetails, tokenNo, orderType }) => {
    return (
        orderType === 'DINE' ?
            <div id="kitchenReport" style={{ display: 'inline' }} hidden={true} >
                {orderDetails && Array.isArray(orderDetails) && orderDetails.map((order, index) => (
                    <>
                        <div class="invoice-box">
                            <table class="table-head" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td>
                                        <strong style={{ fontSize: 20 }}>Master Food</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong style={{ fontSize: 14 }}>Order Type : {order.deliveryMethod}</strong><br />
                                    </td>
                                </tr>
                                {order.deliveryMethod === "DINE" ?
                                    <tr>
                                        <td>
                                            <strong style={{ fontSize: 14 }}>Token N0 : {tokenNo}</strong><br />
                                        </td>
                                    </tr> : null}
                                <tr>
                                    <td class="td-border" style={{ fontSize: 10 }}>
                                        <strong style={{ fontSize: 10 }}>Ticket No : {order?.tempInvoiceNo}</strong><br />
                                        Date : {moment(new Date(order.orderDate)).format(billDateFormat === 'DT' ? 'DD-MM-YYYY hh:mm:ss' : 'DD-MM-YYYY')} <br />
                                        {order.customer !== undefined ?
                                            <>
                                                <label>
                                                    Name : {order.customer?.name}
                                                </label>
                                                <br />
                                                {order.customer?.mobileNumber && order.customer?.mobileNumber !== "0" ?
                                                    <label>
                                                        Mobile: {order.customer?.mobileNumber}
                                                    </label> : null}
                                            </> :
                                            null
                                        }
                                    </td>
                                </tr>
                            </table>
                            <table class="table-body" cellpadding="0" cellspacing="0" style={{ fontSize: 10 }}>

                                <tr class="heading">
                                    <td>Item</td>
                                    <td>Qty</td>
                                </tr>
                                {order !== null && order.id !== undefined && order.items ?
                                    order.items.map(item => {
                                        return (
                                            <tr class="details">
                                                <td>{item.prodAlias || item.name}</td>
                                                <td>{item.qty}</td>
                                            </tr>
                                        )
                                    })
                                    : null}
                            </table>
                        </div>
                        {index < orderDetails.length - 1 ?
                            <div class="invoice-box" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                <div style={{ borderTop: '1px dashed #000', borderSpacing: '6px' }}></div>
                            </div>
                            : null}
                    </>
                ))}
            </div >
            : orderType !== 'DINE' ?
                orderDetails ?
                    <div id="kitchenReport" style={{ display: 'inline' }} hidden={true} >
                        <div class="invoice-box">
                            <table class="table-head" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td>
                                        <strong style={{ fontSize: 20 }}>Master Food</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong style={{ fontSize: 14 }}>Order Type : {orderDetails.deliveryMethod}</strong><br />
                                    </td>
                                </tr>
                                {orderDetails.deliveryMethod === "DINE" ?
                                    <tr>
                                        <td>
                                            <strong style={{ fontSize: 14 }}>Order Type : {orderDetails.tags}</strong><br />
                                        </td>
                                    </tr> : null}
                                <tr>
                                    <td class="td-border" style={{ fontSize: 10 }}>
                                        <strong style={{ fontSize: 10 }}>Ticket No : {orderDetails?.tempInvoiceNo}</strong><br />
                                        Date : {moment(new Date(orderDetails.orderDate)).format(billDateFormat === 'DT' ? 'DD-MM-YYYY hh:mm:ss' : 'DD-MM-YYYY')} <br />
                                        {orderDetails.customer !== undefined ?
                                            <>
                                                <label>
                                                    Name : {orderDetails.customer?.name}
                                                </label>
                                                <br />
                                                {orderDetails.customer?.mobileNumber !== "0" ?
                                                    <label>
                                                        Mobile: {orderDetails.customer?.mobileNumber}
                                                    </label> : null}
                                            </> :
                                            null
                                        }
                                    </td>
                                </tr>
                            </table>
                            <table class="table-body" cellpadding="0" cellspacing="0" style={{ fontSize: 10 }}>

                                <tr class="heading">
                                    <td>Item</td>
                                    <td>Qty</td>
                                </tr>
                                {orderDetails !== null && orderDetails.id !== undefined && orderDetails.items ?
                                    orderDetails.items.map(item => {
                                        return (
                                            < tr class="details">
                                                <td>{item.prodAlias || item.name}</td>
                                                <td>{item.qty}</td>

                                            </tr>
                                        )
                                    })
                                    : null}
                            </table>

                        </div>
                    </div >
                    : null
                : null
    )
}

export default KitchenReport