import React from "react";
import { Col, Form } from "react-bootstrap";
import NumberField from "../../../../../../components/common/App/NumberField";


const BasicEditStorePane = ({ addStoreData, handleChange, onStoreNameChange }) => {
    return (
        <>
            <Col md={4}>
                <Form.Floating>
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        name="name"
                        id="nameInput"
                        placeholder="Name"
                        value={addStoreData.name}
                        onChange={handleChange}
                        onBlur={onStoreNameChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">Name<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={4}>
                <Form.Floating>
                    <Form.Select
                        name="storeType"
                        id="storeType"
                        placeholder="Store Type"
                        value={addStoreData.storeType}
                        onChange={handleChange}
                        disabled={true}
                    >
                        <option value="" disabled >Select Status</option>
                        <option value="OFFLINE" selected={addStoreData.storeType === 'OFFLINE'}>OFFLINE</option>
                        <option value="ASSEMBLY" selected={addStoreData.storeType === 'ASSEMBLY'}>ASSEMBLY</option>
                        <option value="ONLINE" selected={addStoreData.storeType === 'ONLINE'}>ONLINE</option>
                    </Form.Select>
                    <Form.Label htmlFor="statusSelect">Store Type<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={4}>
                <Form.Floating>
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        name="storeKey"
                        id="storeKey"
                        placeholder="Name"
                        value={addStoreData.storeKey}
                        maxLength={3}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">Store Key<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={12}>
                <Form.Floating className="">
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        name="address"
                        id="Address"
                        placeholder="Address"
                        value={addStoreData.address}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">Address<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={4}>
                <Form.Floating className="">
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        name="location"
                        id="Location"
                        placeholder="Location"
                        value={addStoreData.location}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">Location<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={4}>
                <Form.Floating className="">
                    <NumberField
                        type="text"
                        autoComplete="off"
                        name="locationLat"
                        id="locationLat"
                        placeholder="Latitude"
                        decimals={8}
                        value={addStoreData.locationLat}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">Latitude<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={4}>
                <Form.Floating className="">
                    <NumberField
                        type="text"
                        autoComplete="off"
                        name="locationLong"
                        id="locationLong"
                        placeholder="Longitude"
                        decimals={8}
                        value={addStoreData.locationLong}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">Longitude<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={4}>
                <Form.Floating className="">
                    <NumberField
                        type="text"
                        autoComplete="off"
                        name="zipCode"
                        id="zipCode"
                        placeholder="zipCode"
                        value={addStoreData.zipCode}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">PIN Code<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={4}>
                <Form.Floating className="">
                    <NumberField
                        type="text"
                        autoComplete="off"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="PhoneNumber"
                        value={addStoreData.phoneNumber}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">Phone Number<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
        </>
    );
};

export default BasicEditStorePane;
