import { SESSION_KEYS } from "./../util/constants";

export function createSession(userData) {
    localStorage.setItem(SESSION_KEYS.USER, JSON.stringify(userData));
    const jwt = parseJwt(userData.token);
    const exp = new Date(jwt.exp * 1000);
    localStorage.setItem(SESSION_KEYS.EXPIRY, exp.getTime());
    localStorage.setItem("resources", userData.resourceBaseUrl);
    localStorage.setItem(SESSION_KEYS.ROLE, userData.defaultRole);
    localStorage.setItem("companyInfo", JSON.stringify(userData.companyInfo));
    localStorage.setItem('assignedStoreAndTerminals',JSON.stringify(userData.userAssignedStoreNdTerminal || []))
    if (userData.assignedTerminals && userData.assignedTerminals.length !== 0) {
        localStorage.setItem("currentTerminal", JSON.stringify(userData.assignedTerminals[0]));
        localStorage.setItem("terminalList", JSON.stringify(userData.assignedTerminals));
    }
}

export function getExtractStoreId(token) {
    return parseJwt(token).storeId;
}

export function findStoreId() {
    return getExtractStoreId(localStorage.getItem(SESSION_KEYS.USER));
}

function parseJwt(token) {
    if (token) {

        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
};

export function isAuthenticated() {
    const sessionUser = localStorage.getItem(SESSION_KEYS.USER);
    if (sessionUser && isExpired()) {
        invalidateSession();
        return false;
    }
    return (sessionUser === "null" || sessionUser === null) ? false : true
}

function isExpired() {
    const exp = localStorage.getItem(SESSION_KEYS.EXPIRY);
    if (Date.now() <= Number(exp)) {
        return false;
    }
    return true;
}

export function invalidateSession() {
    localStorage.removeItem(SESSION_KEYS.USER);
    localStorage.removeItem(SESSION_KEYS.EXPIRY);
    localStorage.removeItem(SESSION_KEYS.ROLE);
    localStorage.removeItem(SESSION_KEYS.STORE_ID);
}

