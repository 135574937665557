import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner, Nav } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { getUserById, getUserStoresAnTerminals, updateUserData } from "../../../../../actions/admin/AdminActions";
import BasicMemberDetails from "./widgets/BasicMemberDetails";
import SettingMemberDetails from "./widgets/SettingMemberDetails";
import { deepCopy } from "../../../../../util/utility-helper";

const initalButtonState = { loading: false, disable: true };
const EditMember = ({ showModal, hideModal, selectedUser, handleToastManager, terminalList }) => {
    // useDispatch hook for dispatching actions
    const dispatch = useDispatch();
    const [updatedUser, setUpdatedUser] = useState(selectedUser || []);
    const [password, setPassword] = useState(selectedUser?.password || '');
    const [userAssignTerminal, setUserAssignTerminal] = useState([]);
    const [activeTab, setActiveTab] = useState('Basic');
    const [assignedTerminals, setAssignedTerminals] = useState([]);
    const [storeTerminalList, setStoreTerminalList] = useState([]);

    // loader for button
    const [buttonState, setButtonState] = useState({ ...initalButtonState });

    useEffect(() => {
        if (showModal) {
            setButtonState({ ...initalButtonState });
        }
    }, [showModal]);

    // Update state when selectedUser changes
    useEffect(() => {
        if (selectedUser) {
            fetchUserDetails(selectedUser);
            setPassword(selectedUser.password);
            // getStoreByApi();
        }
    }, [selectedUser, terminalList]);

    const fetchUserDetails = async (selectedUser) => {
        if (selectedUser) {
            await dispatch(getUserById(selectedUser.id)).then((response) => {
                if (response.payload && !response.payload.error) {
                    setUpdatedUser(response.payload);
                    fetchUserStoresAndTerminals(response.payload ? response.payload.terminals : [])
                }
            })
        }
    };

    const fetchUserStoresAndTerminals = async (assignedTerminals) => {
        await dispatch(getUserStoresAnTerminals()).then((response) => {
            if (response && response.payload) {
                let finalhierarchy = [];
                response.payload.forEach(list => {
                    finalhierarchy.push({ id: list.id + 'S', name: `${list.name}(${list.storeType})`, children: list.terminals })
                })
                const defaultIds = assignedTerminals.map(item => item.id);
                const emptyArr = [];
                defaultIds.map(ids => {
                    const selTerm = terminalList.find(e => e.id === ids);
                    emptyArr.push({ id: ids, storeId: selTerm.storeId })
                });
                setAssignedTerminals(emptyArr)
                setStoreTerminalList(finalhierarchy)
            }
        })
    };

    // Handle input changes and update the state
    const onHandleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
        setButtonState({ ...buttonState, disable: false });
    };

    // Handle the update button click
    const handleUserUpdate = async () => {
        try {
            setButtonState({ ...buttonState, loading: true });
            await dispatch(updateUserData({ ...updatedUser, password: password, terminals: assignedTerminals }))
                .then((response) => {
                    if ((response.payload && !response.payload.error)) {
                        handleToastManager({ visible: true, toastMessage: 'Member updated successfully.', variant: "Success" })
                        fetchUserDetails(selectedUser) // Fetch updated user list
                    } else {
                        handleToastManager({ visible: true, toastMessage: 'Failed to updated member. Please try again.', variant: "Danger" })
                    }
                    setButtonState({ ...buttonState, loading: false });
                })
        } catch (error) {
            handleToastManager({ visible: true, toastMessage: 'Failed to reset password. Please try again.', variant: "Danger" })
        } finally {
            setButtonState({ ...initalButtonState });
        }
    };

    const handleCheckbox = (event, selectedTerminal) => {
        const { checked } = event.target;
        let assignedTerminals = [...userAssignTerminal];
        const userTerminals = updatedUser?.terminals || [];
        const foundIndex = userTerminals.findIndex(e => e.id === selectedTerminal?.id);
        if (foundIndex > -1) {
            userTerminals.splice(foundIndex, 1);
        } else {
            userTerminals.push(selectedTerminal);
        }
        Promise.all(
            assignedTerminals && assignedTerminals.map(term => {
                if (term.id === selectedTerminal.id) {
                    term.checked = checked;
                }
                return term
            })
        ).then(() => {
            setUserAssignTerminal(assignedTerminals)
        })
        setUpdatedUser({ ...updatedUser, terminals: userTerminals });
        setButtonState({ ...buttonState, disable: false });
    };

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };


    const onTerminalSelect = (terminal, flag) => {
        if (terminal.children.length > 0) {
            if (!flag) {
                const emptyArr = [];
                assignedTerminals.forEach(term => {
                    if (![...terminal.children].find(e => e == term.id)) {
                        emptyArr.push(term)
                    }
                })
                setAssignedTerminals(emptyArr);
            } else {
                const cloneAssignTerminals = deepCopy([...assignedTerminals])
                terminal.children.forEach(term => {
                    const selTerm = terminalList.find(e => e.id === term);
                    cloneAssignTerminals.push({ storeId: selTerm.storeId, id: term })
                })
                setAssignedTerminals(cloneAssignTerminals);
            }
        } else {
            let cloneAssignTerminals = deepCopy(assignedTerminals);
            if (!flag) {
                const foundIndex = cloneAssignTerminals.findIndex(e => e.id === terminal.id);
                cloneAssignTerminals.splice(foundIndex, 1);
                setAssignedTerminals([...cloneAssignTerminals]);
            } else {
                const selTerm = terminalList.find(e => e.id === terminal.id);
                cloneAssignTerminals.push({ id: terminal.id, storeId: selTerm.storeId });
                setAssignedTerminals(cloneAssignTerminals)
            }
        }
        setButtonState({...buttonState,disable:false})
    };

    return (
        <Modal size="lg" centered backdrop="static" keyboard={false} show={showModal} onHide={hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Store</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3" >
                    <Col md={12}>
                        <Nav className="card-tabs" activeKey={activeTab} onSelect={handleTabSelect}>
                            <Nav.Item>
                                <Nav.Link eventKey="Basic">Basic</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Setting">Setting</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    {activeTab === 'Basic' && (
                        <BasicMemberDetails updatedUser={updatedUser} onHandleChange={onHandleChange} handleCheckbox={handleCheckbox} userAssignTerminal={userAssignTerminal} />
                    )}
                    {activeTab === 'Setting' && (
                        <SettingMemberDetails setAssignedTerminals={setAssignedTerminals} assignedTerminals={assignedTerminals} updateUserData={updatedUser} terminalList={terminalList} onTerminalSelect={onTerminalSelect} storeTerminalList={storeTerminalList} />
                    )}
                </Row>
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
                <Row className="g-3 w-100 justify-content-end">
                    <Col md={3} lg={3} className="m-0">
                        {!buttonState.loading ?
                            <Button className='light-btn px-4 bg-light-grey ' variant="primary" name="Save" onClick={handleUserUpdate} disabled={buttonState.disable}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    );
};

export default EditMember;
