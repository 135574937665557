import React from "react";
import { Col, Form } from "react-bootstrap";

const BasicMemberDetails = ({ updatedUser, onHandleChange, userAssignTerminal, handleCheckbox }) => {
    return (
        <>
            <Col md={6}>
                <Form.Floating>
                    <Form.Control
                        type="text"
                        autoComplete='off'
                        name="username"
                        placeholder="Name"
                        value={updatedUser.username || ''}
                        onChange={onHandleChange}
                        disabled
                    />
                    <Form.Label>Username</Form.Label>
                </Form.Floating>
            </Col>
            <Col md={6}>
                <Form.Floating>
                    <Form.Select
                        name="status"
                        id="statusSelect"
                        placeholder="Status"
                        value={updatedUser.status || ''}
                        onChange={onHandleChange}
                    >
                        <option value="" disabled>Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </Form.Select>
                    <Form.Label htmlFor="statusSelect">Status</Form.Label>
                </Form.Floating>
            </Col>

            <Col md={6}>
                <Form.Floating>
                    <Form.Select
                        name="roleType"
                        id="roleTypeSelect"
                        placeholder="Role Type"
                        value={updatedUser.roleType || ''}
                        onChange={onHandleChange}
                    >
                        <option value="" disabled>Select Role Type</option>
                        {/* <option value="Admin">Admin</option> */}
                        <option value="BUSINESS_ADMIN">Business Admin</option>
                        <option value="BUSINESS_MANAGER">Business Manager</option>
                    </Form.Select>
                    <Form.Label htmlFor="roleTypeSelect">Role Type</Form.Label>
                </Form.Floating>
            </Col>

            {userAssignTerminal && userAssignTerminal.map((terminal, index) => {
                return (
                    <Col md={4}>
                        <Form.Check key={index} type='checkbox' checked={terminal.checked} label={terminal.name} onClick={(event) => handleCheckbox(event, terminal)} />
                    </Col>
                )
            })}
        </>
    )
}

export default BasicMemberDetails;