import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HEADERS } from "../../config/appHeaders";

const apiEndPoint = process.env.REACT_APP_API_BASE_ENDPOINT;

export const getInventoryHistoryList = createAsyncThunk(
    'inventoryHistory',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/business/inventory-history`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)